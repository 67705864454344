import { MoreVert } from '@mui/icons-material';
import { MenuItem, Popover } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';

import { useOrderState } from '../../context';
import { PostSetting, Schedule, ShiftInfo } from '../context';

import ShiftModal from './ScheduleStep/ShiftModal';

import { DeleteIcon, EditIcon } from '@/assets/icons';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { Small } from '@/components/Typography';
import { TableCell, TableRow } from '@/elements/Table';
import Text from '@/elements/Text';
import useMediaQuery from '@/hooks/useMediaQuery';
import {
  calcHours,
  formatDaySimplewithYear,
  formatTime,
} from '@/util/datetime';

type SummaryTableProps = {
  schedules: Schedule[];
  handleJobsChange: (schedules: Schedule[]) => void;
  postSetting: PostSetting;
  updateLastShift?: (schedule: Schedule) => void;
  skillId?: string;
};

const GetHiredInvitedWorker = (schedule: Schedule) => {
  let workerString = ['1 worker'];
  if (schedule.quantity > 1) {
    workerString = [`${schedule.quantity} workers`];
  }

  if (schedule && schedule.selectedWorkers?.length) {
    workerString.push(`${schedule.selectedWorkers.length} Invited`);
  } else if (schedule && schedule.hiredWorkers?.length) {
    workerString.push(`${schedule.hiredWorkers.length} Hired`);
  } else {
    workerString.push('-');
  }
  return workerString;
};

const SummaryTable = ({
  schedules,
  handleJobsChange,
  updateLastShift,
}: SummaryTableProps) => {
  const HEADERS = ['DATE', 'TIME', 'WORKERS', 'ACTIONS'];
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { billing, orderType } = useOrderState();
  const [selectedSchedule, setSelectedSchedule] = useState<
    Schedule | undefined
  >();
  const [showShiftModal, setShowShiftModal] = useState(false);

  const handleEdit = (info: ShiftInfo) => {
    if (!selectedSchedule) return;
    const updatedSch: Schedule = {
      ...selectedSchedule,
      startTime: info.startTime,
      endTime: info.endTime,
      quantity: info.quantity,
      mandatoryBreakTime: info.mandatoryBreakTime,
      hiredWorkers: info.hiredWorkers,
      selectedWorkers: info.invitedWorkers,
    };
    const filterSch = schedules.filter(
      (s) => s.groupId !== selectedSchedule.groupId
    );
    filterSch.push(updatedSch);
    handleJobsChange(filterSch);
    updateLastShift?.(updatedSch);

    setSelectedSchedule(undefined);
    setShowShiftModal(false);
  };
  return (
    <>
      <Table>
        <TableHeader
          fields={phoneOnly ? HEADERS.slice(0, -1) : HEADERS}
          headerBgColor="white"
          headerColor={'#7A7A7A'}
        />
        <tbody>
          {schedules.map((schGrp, idx) => {
            const startDate = formatDaySimplewithYear(
              schGrp.dateRange.startDate
            );
            const hrs = calcHours(schGrp.startTime, schGrp.endTime);
            const workerString = GetHiredInvitedWorker(schGrp);
            return (
              <>
                <TableRow
                  key={schGrp.groupId}
                  style={{
                    backgroundColor: '#FFF',
                  }}
                >
                  <TableCell
                    style={{
                      padding: '10px 5px',
                      width: '150px',
                      minWidth: '90px',
                    }}
                  >
                    {startDate}
                  </TableCell>
                  <TableCell style={{ padding: '10px 5px', minWidth: '120px' }}>
                    {schGrp.startTime && schGrp.endTime ? (
                      <>
                        {formatTime(schGrp.startTime) + ' - '}
                        {formatTime(schGrp.endTime)}
                        <Text as="div" color={'light'} lineHeightProp={'small'}>
                          {`${schGrp.mandatoryBreakTime} min break`}
                        </Text>
                        <Text as="div" color={'light'} lineHeightProp={'small'}>
                          ({hrs}hrs)
                        </Text>
                      </>
                    ) : (
                      <>-</>
                    )}
                  </TableCell>
                  <TableCell style={{ padding: '10px 5px', minWidth: '50px' }}>
                    {workerString[0]}
                    <Small as="p">{workerString[1]}</Small>
                  </TableCell>
                  <TableCell style={{ padding: '10px 5px' }}>
                    {phoneOnly ? (
                      <>
                        <IconButton
                          id={`${schGrp.groupId}-menu-btn`}
                          sx={{ padding: '0px !important', top: '-4px' }}
                          onClick={(e) => {
                            setSelectedSchedule(schGrp);
                            setAnchorEl(e.currentTarget);
                          }}
                        >
                          <MoreVert />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          id={`edit-shift-${idx}`}
                          style={{ padding: '5px' }}
                          onClick={() => {
                            setSelectedSchedule(schGrp);
                            setShowShiftModal(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          id={`delete-shift-${idx}`}
                          style={{ padding: '5px' }}
                          onClick={() =>
                            handleJobsChange(
                              schedules.filter(
                                (s) => s.groupId !== schGrp.groupId
                              )
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              </>
            );
          })}
        </tbody>
        <Popover
          PaperProps={{
            sx: {
              border: '1px solid #D3D3D3',
              filter:
                'drop-shadow(0px 4.050473213195801px 4.050473213195801px rgba(0, 0, 0, 0.05))',
              boxShadow: 'none',
            },
          }}
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          id={`popover`}
          open={open}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem
            dense={true}
            onClick={() => {
              setShowShiftModal(true);
              setAnchorEl(null);
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            dense={true}
            onClick={() =>
              handleJobsChange(
                schedules.filter((s) => s.groupId !== selectedSchedule?.groupId)
              )
            }
          >
            Delete
          </MenuItem>
        </Popover>
      </Table>

      {showShiftModal && selectedSchedule && (
        <ShiftModal
          customerId={billing?.customer?.id!}
          date={selectedSchedule.dateRange.startDate!}
          hideModal={(e, r) => {
            if (r === 'backdropClick') return;
            setShowShiftModal(false);
          }}
          orderType={orderType}
          schedule={selectedSchedule}
          onSave={handleEdit}
        />
      )}
    </>
  );
};

export default SummaryTable;
