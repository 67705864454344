import { useFeatureValue } from '@growthbook/growthbook-react';
import { useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import { LtaGigMenu } from '@/components/LtaGigMenu';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Text from '@/elements/Text';
import styled from '@/styles';
import colors from '@/styles/colors';
import { LinkAction } from '@/types';
import { JobTypeEnum } from '@/types/graphql';

const Wrapper = styled('nav', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
});

const Group = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

const Item = styled(NavLink, {
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  gap: 2,
  padding: '2px 8px',
  flexDirection: 'column',
  minWidth: '74px',

  backgroundColor: 'transparent',
  borderRadius: '$lg',
  color: '$textNeutral',

  fontWeight: '$normal',
  textDecoration: 'none',

  '&:hover, &:focus': {
    color: '$textNeutral',
    backgroundColor: 'transparent',
  },

  '&.active': {
    color: `${colors.themeDefault}`,
  },

  variants: {
    round: {
      true: {
        width: '50px',
        minWidth: '50px',
        height: '50px',
        border: '1px solid #262626',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#262626',
        color: 'white',
        top: '-30px',
        position: 'relative',
        filter: 'drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.12))',
        '&:hover': {
          backgroundColor: '$themeDefault',
          borderColor: '$themeDefault',
          color: 'white',
        },
        '&.active': {
          backgroundColor: '$themeDefault',
          borderColor: '$themeDefault',
          color: 'white',
        },
      },
      false: {},
    },
    status: {
      active: {
        backgroundColor: '$themeA12',
        color: '$themeDarkest',

        fontWeight: '$medium',

        '&:hover': {
          backgroundColor: '$themeA16',
        },
      },
    },
    disabled: {
      true: {
        borderColor: ' $neutralLightest',
        backgroundColor: ' $neutralLightest',
        pointerEvents: 'none',
        color: '$textNeutral',
      },
      false: {},
    },
    badge: {
      true: {
        position: 'relative',
        '&:after': {
          content: '',
          width: '6px',
          height: '6px',
          background: 'tomato',
          borderRadius: '100%',
          position: 'absolute',
          top: 0,
          right: '20%',
          transform: 'translateX(-20%)',
        },
      },
      false: {},
    },
  },
});

interface CustomLinkAction extends LinkAction {
  gigEnabled?: boolean;
  isCustomerAllowedLTA?: boolean;
}

type NavigationGroup = { items: CustomLinkAction[]; title?: string };

type Props = {
  groups: NavigationGroup[];
};

const BottomNavbar = ({ groups }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ref = useRef(null);
  const navigate = useNavigate();

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const staffingOrderCreation = useFeatureValue(
    FEATURE_TOGGLE.StaffingOrderCreation,
    false
  );
  const orderFolder = staffingOrderCreation ? 'creategigorder' : 'creategig';

  const navBarClick = (id?: string, isCustomerAllowedLTA?: boolean) => {
    if (id !== 'orders-create') {
      return true;
    }

    if (!isCustomerAllowedLTA) {
      setTimeout(() => {
        navigate(`/orders/${orderFolder}?type=${JobTypeEnum.GIG}`);
      }, 100);
    } else {
      setAnchorEl(ref?.current);
    }
  };

  return (
    <Wrapper>
      {groups.map((group, groupIndex) => (
        <Group key={`group-${groupIndex}`}>
          {group.title && (
            <div style={{ margin: '0 10px 10px' }}>
              <Text size="sm" weight="semibold">
                {group.title}
              </Text>
            </div>
          )}
          {group.items.map((item, itemIndex) => {
            const { CustomIcon, hide } = item;
            if (hide) return <></>;
            return (
              <>
                <Item
                  key={`item-${groupIndex}-${itemIndex}`}
                  ref={item.id === 'orders-create' ? ref : null}
                  badge={item.badge}
                  disabled={item.disabled}
                  end={item.end}
                  id={`nav-${item.id}`}
                  round={!!(item.id && item.id === 'orders-create')}
                  to={item.id === 'orders-create' ? '#' : item.to}
                  onClick={() =>
                    navBarClick(item.id, item.isCustomerAllowedLTA)
                  }
                >
                  {CustomIcon && <CustomIcon />}
                  {item.label ||
                    (item.a11yLabel && (
                      <Text color="inherit" size="sm" weight="inherit">
                        {item.label || item.a11yLabel}
                      </Text>
                    ))}
                </Item>
                {item.id === 'orders-create' && (
                  <LtaGigMenu
                    anchorEl={anchorEl}
                    handleCloseMenu={handleCloseMenu}
                    marginTop={-75}
                    open={open}
                  />
                )}
              </>
            );
          })}
        </Group>
      ))}
    </Wrapper>
  );
};

BottomNavbar.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(BottomNavbar.Wrapper)';

BottomNavbar.Group = Group;
Group.displayName = 'stitches(BottomNavbar.Group)';

BottomNavbar.Item = Item;
Item.displayName = 'stitches(BottomNavbar.Item)';

export default BottomNavbar;
