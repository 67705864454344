import * as React from 'react';

import { MIN_WORDS_TO_CAPITALIZE } from '@/constants/general';
import { TableHead, TableHeading, TableRow } from '@/elements/Table';
import { capitalizeText } from '@/util/text';

type Props = {
  fields: (string | { name: string; style: Record<string, any> })[];
  headerBgColor?: string;
  headerColor?: string;
  checkBoxHeader?: React.ReactNode;
};

const TableHeader = ({
  fields,
  headerBgColor,
  headerColor,
  checkBoxHeader,
}: Props) => {
  return (
    <TableHead style={{ backgroundColor: headerBgColor }}>
      <TableRow>
        {checkBoxHeader}
        {fields.map((field, index) => {
          if (typeof field === 'string') {
            return (
              <TableHeading
                key={index}
                style={headerColor ? { color: headerColor } : undefined}
              >
                {field.length < MIN_WORDS_TO_CAPITALIZE
                  ? capitalizeText(field)
                  : field}
              </TableHeading>
            );
          } else {
            return (
              <TableHeading key={index} style={field?.style}>
                {field?.name?.length < MIN_WORDS_TO_CAPITALIZE
                  ? capitalizeText(field?.name)
                  : field?.name}
              </TableHeading>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
