import useModal from '@area2k/use-modal';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Print } from '@mui/icons-material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { differenceInHours, parseISO } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import ConfirmFinalizeDraft from '../ConfirmFinishDraft';
import ConfirmVoidInvoice from '../ConfirmVoidInvoice';
import InvoiceDetailsPdf from '../InvoiceDetailsPdf';
import PageSkeleton from '../Skeletons/DetailsSkeleton';
import ErrorSkeleton from '../Skeletons/ErrorSkeleton';

import DetailsCard from './DetailsCard';
import ItemsCard from './ItemsCard';
import ConfirmPaymentModal from './Modals/ConfirmPaymentModal';
import PaymentFailModal from './Modals/PaymentFailModal';
import SuccessPaymentModal from './Modals/SuccessPaymentModal';
import ToggleAutoApprove from './ToggleAutoApprove';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import Page from '@/components/Page';
import Stack from '@/components/Stack';
import { UNKNOWN_ERROR_TEXT } from '@/constants/text';
import {
  useGetAccountPaymentMethodsLazyQuery,
  useGetInvoiceDetailsRevampQuery,
  useToggleAutoApproveMutation,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import routes from '@/routes/routes';
import { theme } from '@/styles/colors';
import { GenericAction, GenericActionOrList } from '@/types';
import { StripeInvoiceStatusEnum } from '@/types/graphql';
import { formatCurrentPaymentMethod } from '@/util/payments';

const InvoiceDetailRevamp = () => {
  const { currentAdminIsCustomerAdmin } = useAuth();
  const { invoiceId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [autoApprove, setAutoApprove] = useState<boolean>(true);
  const [autoApproveDisabled, setAutoApproveDisabled] = useState<boolean>(true);
  const [autoApproveError, setAutoApproveError] = useState(null);
  const { loading, error, data, refetch } = useGetInvoiceDetailsRevampQuery({
    variables: {
      invoiceId,
    },
    onCompleted(invoice) {
      if (invoice.listStripeInvoiceDetail?.invoice?.deleted) {
        navigate(`../../${routes.invoices}`, {
          state: { invalidInvoice: true },
        });
      }
    },
  });

  const handleGoBack = () => {
    navigate({
      pathname: '/invoices',
      search: location.search,
    });
  };

  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [
    getAccountPaymentMethods,
    { data: pmData, loading: loadingPM, refetch: refetchPM },
  ] = useGetAccountPaymentMethodsLazyQuery({
    variables: {
      accountId: invoiceId,
    },
  });

  useEffect(() => {
    if (data?.listStripeInvoiceDetail?.invoice) {
      getAccountPaymentMethods({
        variables: {
          accountId: data?.listStripeInvoiceDetail?.invoice.account.id,
        },
      });
    }
  }, [data]);

  const [toggleAutoApprove, { loading: toggleLoading }] =
    useToggleAutoApproveMutation();

  const [showConfirmFinishDraftModal, hideConfirmFinishDraftModal] = useModal(
    () => (
      <ConfirmFinalizeDraft
        hideModal={hideConfirmFinishDraftModal}
        invoiceId={invoiceId}
      />
    ),
    [invoiceId]
  );

  const [showConfirmVoidInvoiceModal, hideConfirmVoidInvoiceModal] = useModal(
    () => (
      <ConfirmVoidInvoice
        hideModal={hideConfirmVoidInvoiceModal}
        invoiceId={invoiceId}
      />
    ),
    [invoiceId]
  );

  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
  const [showSuccessModal, setshowSuccessModal] = useState(false);
  const [showFailureModal, setshowFailureModal] = useState(false);

  useEffect(() => {
    if (
      data &&
      data.listStripeInvoiceDetail?.invoice?.status ===
        StripeInvoiceStatusEnum.DRAFT
    ) {
      const { invoice } = data.listStripeInvoiceDetail;
      setAutoApprove(invoice.autoApprove);
      const hours = differenceInHours(
        new Date(),
        parseISO(invoice.createdDatetime)
      );
      setAutoApproveDisabled(hours > 48);
    }
  }, [data]);

  const handleSuccessPayment = useCallback(
    (isBankPayment = false) => {
      setTimeout(() => {
        refetch({ invoiceId });
      }, 1500);
      if (!isBankPayment) {
        setshowSuccessModal(true);
      }
      setShowConfirmPaymentModal(false);
    },
    [refetch]
  );
  const handleFailPayment = () => {
    setshowFailureModal(true);
    setShowConfirmPaymentModal(false);
  };

  if (loading) {
    return <PageSkeleton />;
  }

  if (error || !data) {
    return <ErrorSkeleton />;
  }

  const { invoice, invoiceItems } = data.listStripeInvoiceDetail;
  const invoiceIsOpen = invoice.status === StripeInvoiceStatusEnum.OPEN;
  const invoiceIsDraft = invoice.status === StripeInvoiceStatusEnum.DRAFT;
  const pagePrimaryAction: GenericAction | undefined = {
    a11yLabel: 'Confirm Payment',
    label: 'Confirm Payment',
    onAction: () => setShowConfirmPaymentModal(true),
    id: 'btn-pay-invoice',
  };
  const showconfirmPayment =
    showConfirmPaymentModal && !!invoice && !!pmData?.accountPaymentMethods;

  const invoiceActions = (
    status: StripeInvoiceStatusEnum
  ): GenericActionOrList[] | undefined => {
    const invoiceAction = {
      [StripeInvoiceStatusEnum.DRAFT]: {
        a11yLabel: 'Finish Draft',
        label: 'Finish Draft',
        onAction: showConfirmFinishDraftModal,
        id: 'btn-finish-draft',
      },
      [StripeInvoiceStatusEnum.OPEN]: {
        a11yLabel: 'Revert to draft',
        label: 'Revert to draft',
        dangerous: true,
        onAction: showConfirmVoidInvoiceModal,
        id: 'btn-void',
      },
    }[status];

    return invoiceAction ? [invoiceAction] : undefined;
  };

  const onToggleChanged = async (value) => {
    try {
      setAutoApprove(value);
      setAutoApproveError(null);
      await toggleAutoApprove({
        variables: {
          invoiceId,
        },
      });
    } catch (error: any) {
      setAutoApprove(!value);
      setAutoApproveError(error.message ?? UNKNOWN_ERROR_TEXT);
    }
  };
  const DownloadJSX = () => (
    <div
      style={{
        cursor: 'pointer',
        marginRight: '8px',
      }}
    >
      <Print
        htmlColor={theme.themeDefault}
        sx={{ top: '3px', width: '17', height: '16px' }}
      />
      <span
        style={{
          color: theme.themeDefault,
          marginLeft: '6px',
          fontWeight: 500,
          fontSize: '14px',
        }}
      >
        Download Invoice
      </span>
    </div>
  );
  const invId = `${invoice.id}-${invoice.number || ''}`;
  const paymentMethod = pmData?.accountPaymentMethods
    ? formatCurrentPaymentMethod(pmData?.accountPaymentMethods)
    : '';
  const isClientandCheck =
    paymentMethod === 'Check' && currentAdminIsCustomerAdmin;

  const DownLoadInvoice = () => (
    <PDFDownloadLink
      document={
        <InvoiceDetailsPdf invoice={invoice} invoiceItems={invoiceItems} />
      }
      fileName={`${invId}.pdf`}
      style={{ textDecoration: 'none' }}
    >
      {() => (!paymentMethod ? <LoadingState size={'1x'} /> : <DownloadJSX />)}
    </PDFDownloadLink>
  );

  return (
    <>
      {isClientandCheck && (
        <Alert
          css={{
            backgroundColor: '#FCF6D6 !important',
            color: '#ECA300 !important',
            margin: '12px 16px 0px 16px',
          }}
          description={`Coordinate with your account manager to pay for invoice ${invId} by check.`}
          icon={faExclamationTriangle}
          status={'warning'}
          title="Your payment method is set to pay by check"
        />
      )}

      <Page
        customActions={
          invoiceIsDraft && !phoneOnly ? (
            <ToggleAutoApprove
              checked={autoApprove}
              disabled={toggleLoading || autoApproveDisabled}
              error={autoApproveError}
              setAutoApproveError={setAutoApproveError}
              onToggleChange={onToggleChanged}
            />
          ) : invoiceIsOpen && !phoneOnly ? (
            <DownLoadInvoice />
          ) : (
            <></>
          )
        }
        primaryAction={
          invoiceIsOpen && !isClientandCheck ? pagePrimaryAction : undefined
        }
        secondaryActions={
          !currentAdminIsCustomerAdmin
            ? invoiceActions(invoice.status)
            : undefined
        }
        size="full"
        title={`Invoice Details`}
      >
        <Stack vertical gap={30}>
          {invoiceIsDraft && phoneOnly && (
            <Stack gap={0} justify={'end'}>
              <ToggleAutoApprove
                checked={autoApprove}
                disabled={toggleLoading || autoApproveDisabled}
                error={autoApproveError}
                setAutoApproveError={setAutoApproveError}
                onToggleChange={onToggleChanged}
              />
            </Stack>
          )}
          {invoiceIsOpen && phoneOnly && (
            <Stack gap={0} justify={'end'}>
              <DownLoadInvoice />
            </Stack>
          )}
          <Card changeStyle={{ padding: '22px' }}>
            {showconfirmPayment && (
              <ConfirmPaymentModal
                accountPaymentMethods={pmData?.accountPaymentMethods}
                handleFailPayment={handleFailPayment}
                handleSuccessPayment={handleSuccessPayment}
                hideModal={() => setShowConfirmPaymentModal(false)}
                invoice={invoice}
                refetchInv={refetch}
              />
            )}
            {showSuccessModal && (
              <SuccessPaymentModal
                hideModal={() => setshowSuccessModal(false)}
                invId={invId}
              />
            )}
            {showFailureModal && (
              <PaymentFailModal
                hideModal={() => setshowFailureModal(false)}
                invId={invId}
              />
            )}
            <DetailsCard
              accountPaymentMethods={pmData?.accountPaymentMethods}
              editable={invoiceIsDraft && !currentAdminIsCustomerAdmin}
              invoice={invoice}
              loadingPM={loadingPM}
              refetchInv={refetch}
              refetchPM={refetchPM}
            />
            <ItemsCard
              editable={invoiceIsDraft && !currentAdminIsCustomerAdmin}
              invoice={invoice}
              isClientAdmin={currentAdminIsCustomerAdmin}
              items={invoiceItems}
            />
          </Card>
        </Stack>
        {currentAdminIsCustomerAdmin && (
          <>
            <br />
            <Alert
              icon={faExclamationTriangle}
              status="neutral"
              title="Please note that late payments are subject to a 50% fee."
            />
          </>
        )}
        <Stack css={{ marginTop: 32 }} justify="end">
          <Button
            a11yLabel="Go back"
            appearance="primary"
            id="btn-go-back"
            label="Go Back"
            type="button"
            onClick={handleGoBack}
          />
        </Stack>
      </Page>
    </>
  );
};

export default InvoiceDetailRevamp;
