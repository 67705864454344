import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogTitle, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { getSortedWorkerSkillsList } from '../../../util';

import WorkerCertificatesSection from './WorkerCertificatesSection';
import WorkerDetailsSection from './WorkerDetailsSection';
import WorkerSkillsSection from './WorkerSkillsSection';
import { Container } from './styles';

import { GravyTrained } from '@/assets/icons';
import CrownIcon from '@/assets/icons/CrownIcon';
import InactiveCrownIcon from '@/assets/icons/InactiveCrownIcon';
import Avatar from '@/components/Avatar';
import Button from '@/components/Button';
import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import { Subheading, Small } from '@/components/Typography';
import { V3Endpoints } from '@/constants/urls';
import { useGetWorkerProfileLazyQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { JobWorkerItemFragment, TaxTypeEnum } from '@/types/graphql';
import axiosClient from '@/util/axios/axiosClient';
import { ChatGroupGWUserType } from '@/util/axios/types';
import { containsGravyCertification } from '@/util/worker';

import './index.css';
import ls, { ACTIVE_CHAT_ID } from '@/util/localstorage';

type Props = {
  hideModal: () => void;
  ProfileInfo: JobWorkerItemFragment['worker'];
  isClientAdmin: boolean;
  workerId?: string;
};

const ProfileModalRevamp = ({
  hideModal,
  ProfileInfo,
  isClientAdmin,
  workerId,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const phoneStyles = phoneOnly
    ? { height: '90vh', width: '100%', borderRadius: '20px' }
    : {};
  const [buttonLoading, setButtonLoading] = useState(false);
  const [fetchProfile, { data, loading }] = useGetWorkerProfileLazyQuery();

  useEffect(() => {
    if (workerId) {
      fetchProfile({ variables: { workerId } });
    }
  }, [workerId]);

  ProfileInfo = data?.worker || ProfileInfo;

  const handleWorkerProfileNavigation = () => {
    window.open(`/workers/${ProfileInfo.id}`, '_blank');
  };

  const handleMessageWorkerClick = async () => {
    let name = `${ProfileInfo?.user?.firstName || ''} ${
      ProfileInfo?.user?.middleName || ''
    } ${ProfileInfo?.user?.lastName || ''}`;
    name = name.replace(/  +/g, ' ');
    try {
      setButtonLoading(true);
      const resp = await axiosClient.post(V3Endpoints.CREATE_CHAT_GROUP, {
        name,
        user: {
          id: parseInt(ProfileInfo.user.id!),
          type: ChatGroupGWUserType.WORKER,
        },
      });
      if (resp?.data?.id) {
        ls.set(ACTIVE_CHAT_ID, resp.data.id);
        window.open(`/inappchat`, '_blank');
      }
    } catch (e) {
      console.error(e);
    }
    setButtonLoading(false);
  };

  const isGravyCertified = containsGravyCertification(
    ProfileInfo?.activeCertificates
  );

  const mergedWorkerSkillsList = getSortedWorkerSkillsList(
    ProfileInfo?.workerSkills || []
  );
  const {
    averageRating,
    jobsDropped,
    jobsRatingCount,
    noShow,
    taxType,
    jobsWorked,
  } = ProfileInfo;
  const isPlus = taxType === TaxTypeEnum.TAX_1099;

  return (
    <Dialog
      PaperProps={{
        style: {
          height: 'auto',
          width: '600px',
          overflowX: 'hidden',
          borderRadius: '20px',
          maxHeight: 'calc(100% - 40px)',
          ...phoneStyles,
        },
      }}
      aria-labelledby="worker-profile-dialog"
      className="worker-profile-dialog-view"
      fullScreen={phoneOnly}
      open={true}
      sx={{
        '& .MuiDialog-container': phoneOnly
          ? { alignItems: 'end', marginBottom: '2px' }
          : {},
      }}
      onClose={hideModal}
    >
      <DialogTitle
        id="options-dialog-title"
        sx={{ m: 0, p: 2, fontWeight: '600' }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 6,
          }}
          onClick={hideModal}
        >
          <CloseOutlined />
        </IconButton>
      </DialogTitle>
      <Card.Section css={{ padding: '0px' }}>
        <Stack
          alignItems="center"
          direction={'column'}
          justifyContent="center"
          padding="20px"
          py={'8px'}
          spacing={{ md: 2.5, xs: 1.5 }}
        >
          <Stack
            alignItems="center"
            direction={'column'}
            justifyContent="center"
            spacing={{ xs: 1, sm: 0.7 }}
          >
            <Container>
              <Avatar
                rounded
                borderLg={isGravyCertified}
                css={{ boxSize: '180px' }}
                src={ProfileInfo.avatarUrl}
              />
              {isGravyCertified && (
                <GravyTrained
                  data-testid="gravy-certified-badge"
                  id="gravy-certified-badge"
                  sx={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    top: 128,
                    left: 120,
                    width: '52px',
                    height: '52px',
                  }}
                />
              )}
            </Container>

            <Subheading
              css={{
                fontSize: '24px',
                textAlign: 'center',
                wordBreak: 'break-word',
              }}
              weight="bold"
            >
              {`${ProfileInfo?.user?.firstName || ''} ${
                ProfileInfo?.user?.middleName || ''
              } ${ProfileInfo?.user?.lastName || ''}`}
            </Subheading>

            <Stack alignItems="center" flexDirection="row" gap="12px">
              {isPlus ? (
                <InactiveCrownIcon fontSize="small" />
              ) : (
                <CrownIcon fontSize="small" />
              )}
              <Small css={{ fontStyle: 'italic', color: '#262626' }}>
                {isPlus ? 'Plus GravyWorker' : 'Flex Plus GravyWorker'}
              </Small>
            </Stack>
          </Stack>

          {!isClientAdmin && (
            <Stack
              flexDirection="row"
              gap="20px"
              style={{ marginBottom: '10px', marginTop: '16px' }}
              width="100%"
            >
              <Button
                a11yLabel="View Full Profile"
                data-testid="view-full-profile-button"
                label="View full profile"
                style={{
                  width: '100%',
                  height: '40px',
                  fontSize: '16px',
                }}
                type="button"
                onClick={handleWorkerProfileNavigation}
              />
              <Button
                a11yLabel="Message Worker"
                appearance="outline"
                data-testid="message-worker-button"
                isLoading={buttonLoading}
                label="Message Worker"
                style={{
                  borderColor: '#45A735',
                  fontSize: '16px',
                  color: '#45A735',
                  width: '100%',
                  height: '40px',
                }}
                type="button"
                onClick={handleMessageWorkerClick}
              />
            </Stack>
          )}

          <WorkerDetailsSection
            ProfileInfo={{
              averageRating,
              jobsDropped,
              jobsRatingCount,
              noShow,
              jobsWorked,
            }}
            isClientAdmin={isClientAdmin}
          />
        </Stack>
        {loading ? (
          <LoadingState />
        ) : (
          <Stack marginBottom="24px">
            <WorkerSkillsSection workerSkills={mergedWorkerSkillsList} />
            <WorkerCertificatesSection
              activeCertificates={ProfileInfo?.activeCertificates || []}
            />
          </Stack>
        )}
      </Card.Section>
    </Dialog>
  );
};

export default ProfileModalRevamp;
