import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { StitchesVariants } from '@stitches/react';
import React from 'react';

import { Container } from './styles';

import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Heading, Subheading } from '@/components/Typography';

type accordionVariants = StitchesVariants<typeof Container>;

export type Props = accordionVariants & {
  a11yLabel?: string;
  label: string;
  children: React.ReactNode;
  startOpen?: boolean;
  customHeadingLabel?: string;
};
const Accordion = ({
  label,
  children,
  startOpen = false,
  customHeadingLabel,
  ...rest
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(startOpen);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Container {...rest}>
      <Stack css={{ marginBottom: isOpen ? '1.5em' : 0 }}>
        <TextStack>
          <Heading>{label}</Heading>
        </TextStack>

        <Stack justify={'end'}>
          {customHeadingLabel && (
            <Subheading css={{ fontSize: '14px', color: '#45a735' }}>
              {customHeadingLabel}
            </Subheading>
          )}
          <IconicButton
            a11yLabel="action"
            appearance="clear"
            borderless={!!customHeadingLabel}
            css={{
              fontSize: '1em',
            }}
            data-testid="actionButton"
            icon={
              isOpen
                ? faChevronUp
                : customHeadingLabel
                ? faChevronDown
                : faChevronRight
            }
            iconTheme="theme"
            rounded={!customHeadingLabel}
            size="sm"
            onClick={toggle}
          />
        </Stack>
      </Stack>
      {isOpen && <>{children}</>}
    </Container>
  );
};

export default Accordion;
