import { StitchesVariants } from '@stitches/react';
import { HTMLProps } from 'react';

import Text from '@/elements/Text';

export type TextVariants = StitchesVariants<typeof Text>;

export type HeadingProps = Omit<HTMLProps<HTMLHeadingElement>, 'as' | 'ref'> &
  TextVariants;
export type ParagraphProps = Omit<
  HTMLProps<HTMLParagraphElement>,
  'as' | 'ref'
> &
  TextVariants;
export type SpanProps = Omit<HTMLProps<HTMLSpanElement>, 'as' | 'ref'> &
  TextVariants;

export const Display = (props: HeadingProps) => (
  <Text as="h1" color="default" size="xxxl" weight="semibold" {...props} />
);

export const Title = (props: HeadingProps) => (
  <Text as="h2" color="default" size="xxl" weight="semibold" {...props} />
);

export const Heading = (props: HeadingProps) => (
  <Text as="h3" color="default" size="xl" weight="semibold" {...props} />
);

export const Subheading = (props: HeadingProps) => (
  <Text as="h4" color="default" size="lg" weight="medium" {...props} />
);

export const Body = (props: ParagraphProps) => (
  <Text as="p" color="default" size="md" weight="normal" {...props} />
);

export const Small = (props: SpanProps) => (
  <Text as="span" color="lighter" size="sm" weight="normal" {...props} />
);

export const Error = (props: ParagraphProps) => (
  <Text as="h1" color="danger" size="md" weight="normal" {...props} />
);

export const ProfileHeading = (props: HeadingProps) => (
  <Text as="span" color="black" size="lg" weight="bold" {...props} />
);

export const Caption = (props: SpanProps) => (
  <Text
    as="span"
    color="light"
    css={{ textTransform: 'uppercase' }}
    size="sm"
    weight="semibold"
    {...props}
  />
);
