import {
  RenderWorkerInfo,
  getHiredButton,
  getInvitedButton,
} from './StaffingUtils';
import WorkerProfileRowMobileView from './WorkerProfileRowMobileView';

import Stack from '@/components/Stack';
import { TableRow, TableCell } from '@/elements/Table';
import useMediaQuery from '@/hooks/useMediaQuery';

type Mode = 'add' | 'remove';
type StaffingWorkerProfileRowProps = {
  worker: Record<string, any>;
  isClientAdmin: boolean;
  hireButtonClick: (worker: Record<string, any>, mode?: Mode) => void;
  inviteButtonClick: (worker: Record<string, any>, mode?: Mode) => void;
  isHiredButton?: boolean;
  isInvitedButton?: boolean;
  buttonsDisableCheck?: boolean;
  isLTA: boolean;
  skillName: string;
  modalView?: boolean;
  inviteButtonDisable?: boolean;
};

const StaffingWorkerProfileRow = ({
  worker,
  isClientAdmin,
  hireButtonClick,
  inviteButtonClick,
  isHiredButton,
  isInvitedButton,
  buttonsDisableCheck,
  isLTA,
  skillName,
  modalView = false,
  inviteButtonDisable,
}: StaffingWorkerProfileRowProps) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  return (
    <>
      {phoneOnly ? (
        <WorkerProfileRowMobileView
          key={worker.id}
          buttonsDisableCheck={buttonsDisableCheck}
          hireButtonClick={hireButtonClick}
          inviteButtonClick={inviteButtonClick}
          inviteButtonDisable={inviteButtonDisable}
          isClientAdmin={isClientAdmin}
          isHiredButton={isHiredButton}
          isInvitedButton={isInvitedButton}
          isLTA={isLTA}
          skillName={skillName}
          worker={worker}
        />
      ) : (
        <TableRow key={worker.id} aria-haspopup="true">
          <TableCell>
            <RenderWorkerInfo
              isClientAdmin={isClientAdmin}
              modalView={modalView}
              skillName={skillName}
              workerInfo={worker}
            />
          </TableCell>
          {!modalView && (
            <>
              <TableCell>{worker?.selectedSkillShift}</TableCell>
              <TableCell>{worker?.shiftsWith}</TableCell>
            </>
          )}
          <TableCell css={modalView ? { width: '240px' } : undefined}>
            <Stack css={{ flexDirection: 'row' }} gap={10}>
              {!isClientAdmin || isLTA
                ? getHiredButton({
                    isHired: isHiredButton,
                    disable: buttonsDisableCheck,
                    handleClick: (mode) => hireButtonClick(worker, mode),
                  })
                : null}
              {!isLTA &&
                getInvitedButton({
                  isInvited: isInvitedButton,
                  disable: isHiredButton || inviteButtonDisable,
                  handleClick: (mode) => inviteButtonClick(worker, mode),
                })}
            </Stack>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default StaffingWorkerProfileRow;
