import { SubmitHelpers } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { isEqual } from 'lodash';
import { useCallback, useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import TextInput from '@/components/TextInput';
import { Small } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Form from '@/form';
import OptionField from '@/form/OptionField';
import TextField from '@/form/TextField';
import TextSelectField from '@/form/TextSelectField';
import {
  useCheckDuplicateCustomerNameQuery,
  useUpdateCustomerMutation,
} from '@/graphql';
import styled from '@/styles';
import {
  DefaultDueDateEnum,
  GetCustomerQuery,
  TaxTypeEnum,
} from '@/types/graphql';
import {
  JOB_TAX_TYPES,
  TAX_TYPE_LABELS,
  taxOptions,
  LOW_MARKUP_WARNING_DESCRIPTION,
  DEFAULT_MARKUP_PRICE_VALUE,
  MARKUP_PERCENTAGE_FIELD_ERROR,
} from '@/util/constants';
import { stringToEnumInvoiceDueDate } from '@/util/date';
import { handleMutationFormError } from '@/util/error';

const LabelText = styled('p', {
  fontSize: '13px',
  fontWeight: 600,
  color: '#332F2D',
  marginBottom: '5px',
});

export type Props = {
  customer: GetCustomerQuery['customer'];
  hideModal: () => void;
};

type FormValues = {
  invoiceDueDate: DefaultDueDateEnum;
  name: string;
  enableLTA: boolean;
};

const invoiceDueDateOptions = [
  { label: '10 days', value: DefaultDueDateEnum.INVOICE_DUE_10_DAYS },
  { label: '15 days', value: DefaultDueDateEnum.INVOICE_DUE_15_DAYS },
  { label: '30 days', value: DefaultDueDateEnum.INVOICE_DUE_30_DAYS },
  { label: '45 days', value: DefaultDueDateEnum.INVOICE_DUE_45_DAYS },
  { label: '60 days', value: DefaultDueDateEnum.INVOICE_DUE_60_DAYS },
];

const UpdateClientModal = ({ customer, hideModal }: Props) => {
  const invoiceDueDate: DefaultDueDateEnum = stringToEnumInvoiceDueDate(
    customer.invoiceDueDate
  );

  const formatCurrentTaxType = (taxType: string) => {
    switch (taxType) {
      case JOB_TAX_TYPES.all:
        return TaxTypeEnum.ALL;
      case JOB_TAX_TYPES.w2:
        return TaxTypeEnum.TAX_W2;
      default:
        return taxType;
    }
  };

  const initialValues: FormValues = {
    invoiceDueDate,
    name: customer.name,
    enableLTA: customer.ltaAllowed || false,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [markupPercentage, setMarkupPercentage] = useState<string>(
    `${customer.markupPercent}%`
  );
  const [showMarkupLowValueError, setShowMarkupLowValueError] =
    useState<boolean>(false);

  const [customerTaxType, setCustomerTaxType] = useState(
    formatCurrentTaxType(customer.jobTaxType ?? JOB_TAX_TYPES.all)
  );
  const [updateCustomer, { loading: isLoading }] = useUpdateCustomerMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(customer),
        fields: {
          jobTaxType() {},
          ltaAllowed() {},
        },
      });
    },
  });

  const { data: checkCustomerNameData } = useCheckDuplicateCustomerNameQuery({
    fetchPolicy: 'no-cache',
    variables: { clientName: formValues.name },
  });

  const customerNameDuplicate =
    checkCustomerNameData && checkCustomerNameData.customerNameDuplicate;

  const showMarkupBasedPricing = useFeatureValue(
    FEATURE_TOGGLE.MarkupBasedPricingFeature,
    false
  );

  const handleFormValuesChange = (fieldContext, fieldId: keyof FormValues) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleMarkUpValueChange = (inputMarkupValue: string) => {
    if (!/^\d*%?$/.test(inputMarkupValue)) {
      // check only digits and only one %
      return;
    }
    const markUpValueFormatted = inputMarkupValue.replace('%', '');
    Number(markUpValueFormatted) < DEFAULT_MARKUP_PRICE_VALUE
      ? setShowMarkupLowValueError(true)
      : setShowMarkupLowValueError(false);
    setMarkupPercentage(`${markUpValueFormatted}%`);
  };

  const showMarkUpPriceFieldError =
    Number(markupPercentage?.replace('%', '')) < 1;

  const formSubmitDisabledCheck =
    showMarkupBasedPricing && customer?.markupPercent
      ? showMarkUpPriceFieldError
      : false;

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        const markupPercentageValue = Number(markupPercentage.replace('%', ''));
        if (
          isEqual(values, initialValues) &&
          customerTaxType ===
            (customer.jobTaxType
              ? (customer.jobTaxType as TaxTypeEnum)
              : TaxTypeEnum.ALL) &&
          markupPercentageValue === customer.markupPercent
        ) {
          return hideModal();
        }
        if (customerNameDuplicate) {
          if (formValues.name !== initialValues.name) {
            return setError({ message: 'This client name is already in use.' });
          } else {
            setError(null);
          }
        }
        if (error === null) {
          await updateCustomer({
            variables: {
              customerId: customer.id,
              taxType: customerTaxType as TaxTypeEnum,
              ltaAllowed: values.enableLTA,
              invoiceDueDate: values.invoiceDueDate,
              name: values.name,
              markupPercent: markupPercentageValue,
            },
          });

          hideModal();
        }
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            all: (gqlError) => ({
              title: gqlError.name,
              message: gqlError.message,
              status: 'danger',
            }),
          },
        });
      }
    },
    [
      customer.id,
      customerNameDuplicate,
      formValues,
      customerTaxType,
      markupPercentage,
    ]
  );

  return (
    <Modal
      disableClickout
      size="xs"
      title="Account Settings"
      wrapperBackground={true}
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            description={error.message}
            icon={faExclamationTriangle}
            status="warning"
            title="Something went wrong"
          />
        )}
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <LabelText>Client Name</LabelText>
          <TextField
            autoFocus
            required
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'name');
              if (error) {
                setError(null);
              }
            }}
            fieldId="name"
            placeholder="Client name"
          />
          <LabelText>Net Terms</LabelText>
          <TextSelectField
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'invoiceDueDate');
            }}
            fieldId="invoiceDueDate"
            options={invoiceDueDateOptions}
          />
          <LabelText>Tax Type</LabelText>
          <Stack>
            {taxOptions.map((option) => (
              <Option
                key={option}
                appearance="bullseye"
                checked={customerTaxType === option}
                id={`${taxOptions.indexOf(option)}`}
                label={TAX_TYPE_LABELS[option]}
                style={{ marginRight: '20px' }}
                type="radio"
                value={option}
                onChange={(ev) => setCustomerTaxType(ev.currentTarget.value)}
              />
            ))}
          </Stack>
          <LabelText style={{ marginTop: '15px', marginBottom: '5px' }}>
            LTA
          </LabelText>
          <OptionField
            appearance="switch"
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'enableLTA');
            }}
            fieldId="enableLTA"
            label="Enable LTA"
          />
          {showMarkupBasedPricing && (
            <>
              <LabelText style={{ marginTop: '15px', marginBottom: '10px' }}>
                Set the Markup
              </LabelText>
              <Stack vertical gap="16px" style={{ marginBottom: '20px' }}>
                <TextInput
                  data-testid="markup-value-input"
                  value={markupPercentage}
                  width="100%"
                  onChange={(e) => {
                    handleMarkUpValueChange(e.target.value);
                  }}
                />
                {showMarkUpPriceFieldError && (
                  <Small color="danger" role="alert">
                    {MARKUP_PERCENTAGE_FIELD_ERROR}
                  </Small>
                )}
                {showMarkupLowValueError && (
                  <Alert
                    css={{ marginBottom: '0px' }}
                    description={LOW_MARKUP_WARNING_DESCRIPTION}
                    icon={faExclamationTriangle}
                    status="warning"
                    title="Low Markup"
                  />
                )}
              </Stack>
            </>
          )}
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              disabled={formSubmitDisabledCheck}
              id="btn-save"
              isLoading={isLoading}
              label="Save"
              style={{ width: '100%' }}
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default UpdateClientModal;
