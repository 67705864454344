import { useFeatureValue } from '@growthbook/growthbook-react';
import { useState, useEffect } from 'react';

import CheckBoxFilterGroup from './CheckboxGroup';
import SelectableClientFilter from './ClientSelectable';
import SelectableSkillFilter from './SkillsSelectable';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { REGIONS } from '@/constants/filters';
import { AllFiltersProps } from '@/filters';
import useAuth from '@/hooks/useAuth';
import {
  FillStatusEnum,
  JobFilterSetInput,
  JobDashboardStatusEnum,
  VisibilityStatusEnum,
} from '@/types/graphql';
import { allowedStates } from '@/util/constants';

const filledFilter = [
  {
    label: 'All',
    value: FillStatusEnum.ALL,
  },
  {
    label: 'Filled',
    value: FillStatusEnum.FILLED,
  },
  {
    label: 'Unfilled',
    value: FillStatusEnum.UNFILLED,
  },
];

const orderStatusFilter = [
  {
    label: 'Active',
    value: JobDashboardStatusEnum.ACTIVE,
  },
  {
    label: 'Cancelled',
    value: JobDashboardStatusEnum.CANCELLED,
  },
  {
    label: 'No Show',
    value: JobDashboardStatusEnum.NO_SHOW,
  },
];

enum TimesheetDashboardStatusEnum {
  ALL = 'ALL',
  NEEDS_APPROVAL = 'NEEDS_APPROVAL',
}

const timesheetStatusFilter = [
  {
    label: 'All',
    value: TimesheetDashboardStatusEnum.ALL,
  },
  {
    label: 'Needs Approval',
    value: TimesheetDashboardStatusEnum.NEEDS_APPROVAL,
  },
];

const filterJobStatus = [
  {
    label: 'All',
    value: VisibilityStatusEnum.ALL,
  },
  {
    label: 'Public',
    value: VisibilityStatusEnum.PUBLIC,
  },
  {
    label: 'Private',
    value: VisibilityStatusEnum.PRIVATE,
  },
];

type FilterItem = {
  label: string;
  value: string;
};

export const stateFilters = () => {
  const states: FilterItem[] = [];
  allowedStates.forEach((state) => {
    states.push({
      label: state,
      value: state,
    });
  });
  return states;
};

type Props = AllFiltersProps<JobFilterSetInput> & {
  hideModal: () => void;
};

const DashboardFiltersModal = ({
  filters,
  hideModal,
  onChangeFilter,
  onClearAll,
}: Props) => {
  const { currentAdminIsCustomerAdmin } = useAuth();
  const [able, setAble] = useState(false);
  const [filterParams, setFilterParams] = useState(filters);

  const showDashboardApprovalStatus = useFeatureValue(
    FEATURE_TOGGLE.DashboardApprovalStatusFeature,
    false
  );

  const handleOnChangeFilter = <K extends keyof JobFilterSetInput>(
    key: K,
    value: NonNullable<JobFilterSetInput[K]>
  ) => {
    setFilterParams((prevValue) => ({ ...prevValue, [key]: value }));
  };

  const handleOnClearFilter = (key: keyof JobFilterSetInput) => {
    const updatedFilterParams = { ...filterParams };
    delete updatedFilterParams[key];
    setFilterParams(updatedFilterParams);
  };

  useEffect(() => {
    if (able && Object.keys(filterParams).length !== 0) {
      onClearAll();
      Object.keys(filterParams).map((key: any) => {
        onChangeFilter(key, filterParams[key]);
        return null;
      });
    }
  }, [able]);

  return (
    <Modal
      disableClickout
      size="sm"
      title="Change Filters"
      onRequestClose={hideModal}
    >
      <Stack vertical style={{ padding: 20 }}>
        <Body weight="medium">Fill Status</Body>
        <Stack gap={20}>
          {filledFilter.map((item, index) => (
            <Option
              key={'filledFilter' + index}
              appearance="bullseye"
              checked={filterParams.fillStatus === item.value}
              id={`${filledFilter.indexOf(item)}-filledFilter`}
              label={item.label}
              type="radio"
              value={item.value}
              onChange={(ev) =>
                handleOnChangeFilter('fillStatus', ev.target.value)
              }
            />
          ))}
        </Stack>
      </Stack>
      <Stack vertical style={{ padding: 20 }}>
        <Body weight="medium">Job Status</Body>
        <Stack gap={20}>
          {filterJobStatus.map((item, index) => (
            <Option
              key={`filterJobStatus-${index}`}
              appearance="bullseye"
              checked={filterParams.visibility === item.value}
              data-testid={`${index}-filterJobStatus`}
              id={`${index}-filterJobStatus`}
              label={item.label}
              type="radio"
              value={item.value}
              onChange={(ev) =>
                handleOnChangeFilter('visibility', ev.target.value)
              }
            />
          ))}
        </Stack>
      </Stack>
      <Stack vertical style={{ padding: 20 }}>
        <Body weight="medium">Order Status</Body>
        <CheckBoxFilterGroup
          filterKey={'jobDashboardStatus'}
          filters={filterParams}
          items={orderStatusFilter}
          onChangeFilter={handleOnChangeFilter}
          onClearFilter={handleOnClearFilter}
        />
      </Stack>
      {showDashboardApprovalStatus && (
        <Stack vertical style={{ padding: 20 }}>
          <Body weight="medium">Timesheet Status</Body>
          <Stack gap={20}>
            {timesheetStatusFilter.map((item, index) => (
              <Option
                key={`filterTimesheetStatus-${index}`}
                appearance="bullseye"
                // @ts-ignore
                checked={filterParams.timesheetStatus === item.value}
                data-testid={`${timesheetStatusFilter.indexOf(
                  item
                )}-filterTimesheetStatus`}
                id={`${timesheetStatusFilter.indexOf(
                  item
                )}-filterTimesheetStatus`}
                label={item.label}
                type="radio"
                value={item.value}
                onChange={(ev) =>
                  // @ts-ignore
                  handleOnChangeFilter('timesheetStatus', ev.target.value)
                }
              />
            ))}
          </Stack>
        </Stack>
      )}
      <Stack vertical style={{ padding: 20 }}>
        <Body weight="medium">Skills</Body>
        <div style={{ width: '100%' }}>
          <SelectableSkillFilter
            filterKey={'skillIds'}
            filters={filterParams}
            onChangeFilter={handleOnChangeFilter}
            onClearFilter={handleOnClearFilter}
          />
        </div>
      </Stack>
      {!currentAdminIsCustomerAdmin && (
        <>
          <Stack vertical style={{ padding: 20 }}>
            <Body weight="medium">Region</Body>
            <CheckBoxFilterGroup
              filterKey={'regionIds'}
              filters={filterParams}
              items={REGIONS}
              onChangeFilter={handleOnChangeFilter}
              onClearFilter={handleOnClearFilter}
            />
          </Stack>
          <Stack vertical style={{ padding: 20 }}>
            <Body weight="medium">Client</Body>
            <div style={{ width: '100%' }}>
              <SelectableClientFilter
                filterKey={'customerIds'}
                filters={filterParams}
                onChangeFilter={handleOnChangeFilter}
                onClearFilter={handleOnClearFilter}
              />
            </div>
          </Stack>
        </>
      )}

      <Card.Section>
        <Stack justify="end">
          <Button
            a11yLabel="Clear all order filters"
            appearance="outline"
            id="clear_all_dashboard_filters"
            label="Clear all"
            onClick={() => {
              onClearAll();
              hideModal();
            }}
          />
          <Button
            a11yLabel="Close modal"
            id="btn_dashboard_filters_done"
            label="Done"
            onClick={() => {
              setAble(true);
              hideModal();
            }}
          />
        </Stack>
      </Card.Section>
    </Modal>
  );
};

export default DashboardFiltersModal;
