import { HOUR_IN_MINUTES } from '@/constants/time';

const minutesToHours = (minutes: number) => {
  return minutes / HOUR_IN_MINUTES;
};

const minutesToHoursString = (minutes: number) => {
  const hours = Math.floor(minutes / HOUR_IN_MINUTES);
  const mins = minutes - hours * HOUR_IN_MINUTES;
  return `${hours}h ${mins}m`;
};

export { minutesToHours, minutesToHoursString };
