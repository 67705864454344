import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { StitchesVariants } from '@stitches/react';
import { ComponentProps } from 'react';

import Icon from '@/elements/Icon';
import styled from '@/styles';

const ButonBadge = styled('button', {
  $$bgColor: '$colors$neutralA12',
  $$color: '$colors$textDefault',

  padding: '4px 8px',
  border: 0,
  display: 'flex',
  alignItems: 'center',

  backgroundColor: '$$bgColor',
  borderRadius: '$round',
  color: '$$color',

  fontSize: '$sm',

  variants: {
    size: {
      sm: {},
      md: {
        fontSize: '$md',
      },
    },
    status: {
      neutral: {},
      theme: {
        $$bgColor: '$colors$themeA16',
        $$color: '$colors$themeDarker',
      },
      warning: {
        $$bgColor: '$colors$warningA32',
        $$color: '$colors$textDefault',
      },
      danger: {
        $$bgColor: '$colors$dangerA12',
        $$color: '$colors$dangerDarker',
      },
      success: {
        $$bgColor: '$colors$neuralLessDark',
        $$color: '$colors$textLighter',
      },
    },
  },

  defaultVariants: {
    size: 'sm',
    status: 'neutral',
  },
});

type ButonBadgeVariants = StitchesVariants<typeof ButonBadge>;

export type Props = ComponentProps<typeof ButonBadge> &
  ButonBadgeVariants & {
    a11yLabel: string;
    icon: IconDefinition;
    label: string;
  };

const dropDownBadge = ({ icon, label, ...rest }: Props) => {
  return (
    <ButonBadge {...rest}>
      {label}
      <div style={{ display: 'flex' }}>
        <Icon fixedWidth icon={icon} />
      </div>
    </ButonBadge>
  );
};

export default dropDownBadge;
