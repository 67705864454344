import { useReactiveVar } from '@apollo/client';
import { SubmitHelpers } from '@area2k/use-form';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { CloseOutlined } from '@mui/icons-material';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import { useCallback, useState } from 'react';

import { TimesheetItem } from '../..';

import { HeartFillIcon, HeartIcon } from '@/assets/icons';
import Alert from '@/components/Alert';
import Button from '@/components/Button';
import IconicButton from '@/components/IconicButton';
import Modal from '@/components/Modal';
import RatingField from '@/components/RatingField';
import Stack from '@/components/Stack';
import { Body, ProfileHeading } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { GAEvent } from '@/constants/gaevents';
import { PAST_DAYS } from '@/constants/general';
import Form from '@/form';
import TextAreaField from '@/form/TextAreaField';
import {
  useAddTimesheetCommentMutation,
  useWorkerUpdateRatingMutation,
} from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Role } from '@/routes/PrivateRoute';
import styled from '@/styles';
import { Scalars, RelationshipKindEnum } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { currentAdminVar } from '@/util/apollo/cache';
import { parseDateComment } from '@/util/date';
import { handleMutationFormError } from '@/util/error';

export type Props = {
  timesheet: TimesheetItem;
  variant?: 'DETAIL' | 'DEFAULT';
  hideModal: () => Scalars['Void'];
  phoneOnly?: boolean;
  workerRelationships: [];
  markAsFavorite: () => void;
  markAsUnfavorite: () => void;
};

type FormValues = {
  body: string;
};

const initialValues: FormValues = {
  body: '',
};

type CommentItemProps = {
  comment: string;
  actor: string;
  date: any;
};
const CommentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '21px',
  variants: {
    hasComments: {
      twoOrMore: {
        paddingBottom: '40px',
        minHeight: '50px',
        margin: '21px 0 0',
      },
      one: {
        paddingBottom: '20px',
        minHeight: '30px',
        margin: '10px 0 0',
      },
      noComments: {
        paddingBottom: '10px',
      },
    },
  },
  maxHeight: '150px',
  overflowY: 'auto',
  '@phoneOnly': {
    maxHeight: '125px',
  },
});

const ShadowPoint = styled('div', {
  position: 'relative',
  width: '100%',
  '&::after': {
    position: 'absolute',
    content: '',
    width: '100%',
    bottom: '0',
    left: '0',
    background:
      'linear-gradient(180deg, rgba(255, 253, 253, 0.00) 0%, #FFFEFE 77.97%)',
  },
  variants: {
    hasComments: {
      twoOrMore: {
        '&::after': {
          height: '40px',
        },
      },
      one: {
        '&::after': {
          height: '20px',
        },
      },
      noComments: {
        '&::after': {
          height: '0px',
        },
      },
    },
  },
});

const CommentName = styled('span', {
  fontSize: '13px',
  color: '#128400',
  fontWeight: '700',
});

const CommentDetail = styled('span', {
  fontSize: '11px',
  color: '#6E8476',
  fontWeight: '400',
});

const CommentItem = ({ comment, actor, date }: CommentItemProps) => (
  <Stack vertical css={{ padding: '0' }} verticalGap={6}>
    <Stack justify="apart">
      <CommentName>{actor}</CommentName>
      <CommentDetail>{parseDateComment(date)}</CommentDetail>
    </Stack>
    <Body css={{ wordWrap: 'break-word', maxWidth: '100%' }}>{comment}</Body>
  </Stack>
);

const CommentsTimesheetModal = ({
  timesheet,
  hideModal,
  variant = 'DEFAULT',
  workerRelationships,
  markAsFavorite,
  markAsUnfavorite,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const HeaderDialog = styled('span', {
    padding: '10px 20px 15px 25px',
    background: 'linear-gradient(180deg, #EEFFEC 38.16%, #FFF 107.94%)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  });

  const TitleDialog = styled('span', {
    color: '#45A735',
    fontSize: '16px',
    fontWeight: '600',
  });

  return (
    <>
      {phoneOnly ? (
        <>
          <Dialog
            PaperProps={{
              style: {
                height: 'fit-content',
                borderRadius: '15px',
              },
            }}
            aria-labelledby="second-options-dialog"
            fullScreen={true}
            open={true}
            sx={{
              '& .MuiDialog-container': {
                alignItems: 'end',
                marginBottom: '2px',
              },
            }}
            onClose={hideModal}
          >
            <HeaderDialog>
              <TitleDialog>
                {`${timesheet.jobWorker.worker.user.firstName}'s Review`}
              </TitleDialog>

              <IconButton aria-label="close" onClick={hideModal}>
                <CloseOutlined />
              </IconButton>
            </HeaderDialog>
            <DialogContent>
              <CommentsContent
                hideModal={hideModal}
                markAsFavorite={markAsFavorite}
                markAsUnfavorite={markAsUnfavorite}
                phoneOnly={phoneOnly}
                timesheet={timesheet}
                variant={variant}
                workerRelationships={workerRelationships}
              />
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <>
          <Modal
            disableClickout
            displayFlex={true}
            size="xs"
            title="Add Comment"
            wrapperBackground={true}
            onRequestClose={hideModal}
          >
            <Stack vertical gap={15} style={{ padding: 20 }}>
              <CommentsContent
                hideModal={hideModal}
                markAsFavorite={markAsFavorite}
                markAsUnfavorite={markAsUnfavorite}
                phoneOnly={phoneOnly}
                timesheet={timesheet}
                variant={variant}
                workerRelationships={workerRelationships}
              />
            </Stack>
          </Modal>
        </>
      )}
    </>
  );
};

const CommentsContent = ({
  timesheet,
  hideModal,
  variant = 'DEFAULT',
  phoneOnly,
  workerRelationships,
  markAsFavorite,
  markAsUnfavorite,
}: Props) => {
  const currentAdmin = useReactiveVar(currentAdminVar);
  const currentAdminIsTenantAdmin = currentAdmin!.role === Role.TENANT_ADMIN;
  const currentAdminIsClientAdmin = currentAdmin!.role === Role.CUSTOMER_ADMIN;
  const { logEvent } = useAnalytics();
  const [workerRating, setWorkerRating] = useState<number | null>(
    timesheet.rating || 0,
  );
  const isFavorite =
    workerRelationships.find(
      (r) =>
        r.kind === RelationshipKindEnum.FAVORITED &&
        timesheet.jobWorker.worker.id === r.worker.id,
    ) !== undefined;

  const [comments, setComments] = useState<any>(
    timesheet.timesheetComments
      .filter((c) => {
        if (currentAdminIsClientAdmin === true) {
          return c.actorType === 'CustomerAdmin';
        } else {
          return true;
        }
      })
      .reverse(),
  );
  const showRating = useFeatureValue(FEATURE_TOGGLE.WorkerRating, false);
  const isPast2Weeks =
    differenceInCalendarDays(new Date(), parseISO(timesheet.createdAt)) >
    PAST_DAYS;

  const [addComment, { loading }] = useAddTimesheetCommentMutation({
    onCompleted: (data) => {
      const { timesheet } = data.timesheetCommentsAdd;
      const { timesheetComments } = timesheet;
      setComments(timesheetComments?.reverse());
      logEvent(GAEvent.TimesheetComment, timesheet.id);
    },
  });
  const [updateWorkerRating, { loading: loadingRating }] =
    useWorkerUpdateRatingMutation({
      onCompleted: (data) => {
        const { timesheet } = data.workerUpdateRating;
        const { timesheetComments } = timesheet;
        setComments(timesheetComments?.reverse());
        logEvent(GAEvent.TimesheetRating, timesheet.id);
      },
    });

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError, clearForm }: SubmitHelpers) => {
      try {
        if (currentAdminIsClientAdmin && showRating) {
          if (!values.body && !workerRating) return;
          await updateWorkerRating({
            variables: {
              timesheetId: timesheet.id,
              body: values.body,
              rating: workerRating,
            },
          });
          hideModal();
        } else {
          await addComment({
            variables: {
              timesheetId: timesheet.id,
              body: values.body,
            },
          });
          hideModal();
        }
        clearForm();
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
        });
      }
    },
    [workerRating],
  );

  const hasCommentsValue = () => {
    if (comments?.length > 1) {
      return 'twoOrMore';
    } else if (comments?.length === 1) {
      return 'one';
    }
    return 'noComments';
  };

  return (
    <>
      {showRating && (
        <>
          <Stack
            align={'start'}
            style={{ gap: '10px', flexWrap: 'wrap' }}
            justify={'apart'}
          >
            <ProfileHeading>
              {currentAdminIsTenantAdmin ? 'Client Rating' : 'Give Rating'}
            </ProfileHeading>
            <Stack gap={'10px'} style={{ width: 'auto' }}>
              <RatingField
                initialRating={workerRating}
                readOnly={currentAdminIsTenantAdmin || isPast2Weeks}
                showNumberLabel={true}
                starSize={phoneOnly ? '25' : '28'}
                onChange={setWorkerRating}
              />
            </Stack>
            <IconicButton
              a11yLabel="Toggle Favorite"
              appearance="clear"
              customIcon={
                isFavorite ? (
                  <HeartFillIcon
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                ) : (
                  <HeartIcon
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                )
              }
              data-testid="toggle-favorite"
              icon={faExclamationTriangle}
              id="toggle-favorite-btn"
              size="sm"
              type="button"
              onClick={() => {
                if (isFavorite) {
                  markAsUnfavorite();
                } else {
                  markAsFavorite();
                }
              }}
            />
          </Stack>
        </>
      )}
      <ShadowPoint hasComments={hasCommentsValue()}>
        <CommentWrapper hasComments={hasCommentsValue()}>
          {comments &&
            comments.map((item) => (
              <CommentItem
                key={item.id}
                actor={`${item.actor.firstName} ${item.actor.lastName}`}
                comment={item.body}
                date={item.createdAt}
              />
            ))}
        </CommentWrapper>
      </ShadowPoint>
      {variant !== 'DETAIL' && (
        <Stack vertical>
          <Form
            initialValues={initialValues}
            style={{ width: '100%', margin: '10px 0 10px 0' }}
            onSubmit={handleSubmit}
          >
            <Stack justify="apart" style={{ marginBottom: '10px' }}>
              <ProfileHeading>Add Comment</ProfileHeading>
            </Stack>
            <TextAreaField
              fieldId="body"
              maxLength={250}
              maxLengthPosition="top"
              placeholder="Add comments here..."
              required={currentAdminIsTenantAdmin}
              trim={true}
            />
            <Stack css={{ marginTop: '1.5em' }} justify={'end'}>
              <Button
                a11yLabel="Close"
                appearance="outline"
                label="Close"
                style={{
                  width: phoneOnly ? '100%' : 'auto',
                }}
                type="button"
                onClick={hideModal}
              />
              <Button
                a11yLabel="Submit form"
                isLoading={loading || loadingRating}
                label="Submit"
                style={{
                  width: phoneOnly ? '100%' : 'auto',
                }}
                type="submit"
              />
            </Stack>
          </Form>
        </Stack>
      )}
      {!comments && variant === 'DETAIL' && (
        <Alert
          description="No admin comments provided"
          icon={faExclamationTriangle}
          status="warning"
          style={{ margin: '1.5em 1.5em 0' }}
          title={`Admin comments`}
        />
      )}
    </>
  );
};
export default CommentsTimesheetModal;
