import { useReactiveVar } from '@apollo/client';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ShiftWeekList from './ShiftWeekList';

import { LtaGigMenu } from '@/components/LtaGigMenu';
import Page from '@/components/Page';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import { CurrentCustomerAdminFragment, JobTypeEnum } from '@/types/graphql';
import { hasValidCustomerAccountStatus } from '@/util/accountstatus';
import { currentAdminVar } from '@/util/apollo/cache';
import ls, { ACCOUNT_DISABLED } from '@/util/localstorage';

const Dashboard = () => {
  const accountDisabled = ls.get(ACCOUNT_DISABLED) === 'true';
  const { currentAdmin, isCurrentCustomerAdmin } = useAuth();
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const currentAdminUser = useReactiveVar(
    currentAdminVar
  ) as CurrentCustomerAdminFragment;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ref = useRef(null);
  const navigate = useNavigate();

  const isFeatFlagDisabledCustomerLoginAllowedEnabled = useFeatureValue(
    'disabled-customer-login',
    false
  );

  const staffingOrderCreation = useFeatureValue(
    FEATURE_TOGGLE.StaffingOrderCreation,
    false
  );
  const orderFolder = staffingOrderCreation ? 'creategigorder' : 'creategig';

  function hasValidAccountStatus() {
    return hasValidCustomerAccountStatus(
      isFeatFlagDisabledCustomerLoginAllowedEnabled,
      currentAdminUser,
      accountDisabled
    );
  }

  const dashboardOnNewOrder = () => {
    if (
      isCurrentCustomerAdmin(currentAdmin) &&
      !currentAdmin.customer?.ltaAllowed
    ) {
      navigate(`/orders/${orderFolder}?type=${JobTypeEnum.GIG}`);
    } else {
      setAnchorEl(ref?.current);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Page
      headerPadding={phoneOnly}
      noPadding={phoneOnly}
      primaryAction={{
        a11yLabel: 'Go to create order',
        label: 'Place new order',
        onAction: dashboardOnNewOrder,
        id: 'create-new-order',
        ref,
        disabled: !hasValidAccountStatus(),
      }}
      size={phoneOnly ? 'full' : 'xl'}
      title="Dashboard"
    >
      <LtaGigMenu
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        open={open}
      />
      <ShiftWeekList />
    </Page>
  );
};

export default Dashboard;
