import useModal from '@area2k/use-modal';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { useEffect, useState } from 'react';

import { FirstStepFormValues, OnChangeFirstStepForm } from '../';
import { useOrderActions, useOrderState } from '../../../../context';
import { Address } from '../../../context';

import RedirectionClientProfileAddressModal from './RedirectionClientProfileAddressModal';

import Button from '@/components/Button';
import LoadingState from '@/components/LoadingState';
import Stack from '@/components/Stack';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { MAX_LENGTH_ADDRESS_INSTRUCTIONS } from '@/constants/text';
import Text from '@/elements/Text';
import ItemSelectField from '@/form/ItemSelectField';
import TextAreaField from '@/form/TextAreaField';
import { useGetCustomerLazyQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import ActiveAddressModal from '@/routes/Agency/Customer/AddressList/ActiveAddressModal';
import CreateAddressModal from '@/routes/Agency/Customer/AddressList/CreateAddressModal';
import styled from '@/styles';
import { Maybe } from '@/types';
import { Scalars } from '@/types/graphql';

type Props = {
  skill: FirstStepFormValues['skill'];
  hasInstructions?: Scalars['Boolean'];
  onChange: OnChangeFirstStepForm;
  address: FirstStepFormValues['address'];
  contact: FirstStepFormValues['contact'];
  continueButtonIsDisabled: boolean;
};

const FormFieldWrapper = styled('div', {
  width: '100%',
});

const AddressSection = ({
  skill,
  address,
  contact,
  hasInstructions = false,
  onChange,
  continueButtonIsDisabled,
}: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { billing } = useOrderState();
  const { setBilling } = useOrderActions();
  const [isOpen, setIsOpen] = useState(hasInstructions);
  const [addresses, setAddresses] = useState<Maybe<Address[]>>(null);
  const [addressValue, setAddressValue] = useState<Maybe<string>>(null);
  const [fetchGetCustomer] = useGetCustomerLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ customer }) => {
      setBilling({
        account: billing!.account,
        customer: {
          ...billing!.customer,
          addresses: customer.addresses,
        },
      });
    },
  });

  const showRemovedAddressCreationOnOrder = useFeatureValue(
    FEATURE_TOGGLE.RemoveAddressCreationOnOrder,
    false
  );
  const onChangeAddress = (address: Address) => {
    if (address.active) {
      onChange({ value: address }, 'address');
    }
  };

  const [showCreateAddressModal, hideCreateAddressModal] = useModal(
    () => (
      <CreateAddressModal
        customer={billing?.customer}
        hideModal={(addressCreated) => {
          hideCreateAddressModal();
          fetchGetCustomer({
            variables: {
              customerId: billing?.customer.id!,
              selectNearbyRate: true,
            },
          });
          if (addressCreated) {
            if (!addressCreated.active) {
              showInactiveAddressModal();
            }
          }
        }}
        onChangeAddress={onChangeAddress}
      />
    ),
    [billing?.customer]
  );

  const [
    showClientProfileRedirectionAddressModal,
    hideProfileRedirectionAddressModal,
  ] = useModal(
    () => (
      <RedirectionClientProfileAddressModal
        customerId={billing?.customer?.id}
        hideModal={() => {
          hideProfileRedirectionAddressModal();
        }}
      />
    ),
    [billing?.customer]
  );

  const [showInactiveAddressModal, hideInactiveAddressModal] = useModal(
    () => (
      <ActiveAddressModal
        hideModal={hideInactiveAddressModal}
        text={
          'This address is in rejected state. Please select another address.'
        }
      />
    ),
    [billing?.customer]
  );

  useEffect(() => {
    const filteredAddressesBySkill = billing!.customer.addresses.filter(
      (address) => {
        const addressHasTheSkill = address.rateQuotes!.find(
          (rateQuote) =>
            rateQuote.skill!.id === skill?.id &&
            billing?.account.id === rateQuote.account?.id
        );
        return !!(addressHasTheSkill && address.active);
      }
    );
    const filteredAddressesDefaultRate = billing!.customer.addresses.filter(
      (address) => address.active
    );

    const chooseAddress =
      filteredAddressesBySkill.length >= 1
        ? filteredAddressesBySkill
        : filteredAddressesDefaultRate;

    setAddresses(chooseAddress);
    const selectedAddress = chooseAddress.find(
      (addressItem: Address) => addressItem.id === address?.id
    );
    onChange({ value: selectedAddress }, 'address');
  }, [skill, billing?.customer.addresses]);

  if (!addresses) {
    return <LoadingState overlayColor="white" text="Loading addresses" />;
  }

  return (
    <FormFieldWrapper css={{ display: !skill && phoneOnly ? 'none' : 'block' }}>
      <ItemSelectField<Address>
        required
        callback={(fieldContext) => {
          setAddressValue('' + fieldContext.value?.addressLine1);
          onChange(fieldContext, 'address');
        }}
        disabled={!skill}
        fieldId="address"
        itemToKey={(item) => item.id}
        itemToString={(item) => (item ? `${item.addressLine1}` : '')}
        items={addresses}
        label="Where is the job?*"
        placeholder="Select an address..."
        selectedItem={address}
      />
      {continueButtonIsDisabled && addressValue && contact && (
        <Text as="p" color={'danger'} css={{ top: '-15px' }}>
          {`It looks like you're placing an order in a new region where we haven't
          set up rates for you yet. Please contact `}
          <a
            href={`mailto:am@gravywork.com`}
            id="from-email"
            style={{ color: 'blue', textDecoration: 'none' }}
          >
            am@gravywork.com
          </a>
          {' to get this resolved.'}
        </Text>
      )}
      <Stack vertical>
        <Button
          a11yLabel="Click to add a new address"
          appearance={phoneOnly ? 'outline' : 'plain'}
          disabled={!skill}
          label="Add new address"
          type="button"
          onClick={() => {
            showRemovedAddressCreationOnOrder
              ? showClientProfileRedirectionAddressModal()
              : showCreateAddressModal();
          }}
        />
        {isOpen ? (
          <FormFieldWrapper>
            <TextAreaField
              callback={(fieldContext) =>
                onChange(fieldContext, 'addressInstructions')
              }
              fieldId="addressInstructions"
              label="Address instructions"
              maxLength={MAX_LENGTH_ADDRESS_INSTRUCTIONS}
              placeholder="Add any parking or supervisor check-in instructions..."
            />
          </FormFieldWrapper>
        ) : (
          <Button
            a11yLabel="Click to add optional address instructions"
            appearance={phoneOnly ? 'outline' : 'plain'}
            disabled={!skill}
            label="Add location instructions"
            type="button"
            onClick={() => setIsOpen(true)}
          />
        )}
      </Stack>
    </FormFieldWrapper>
  );
};

export default AddressSection;
