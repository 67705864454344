import { NavLink } from 'react-router-dom';

import PendingDotIcon from '@/assets/icons/PendingDot';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import { useAppSelector } from '@/hooks/store';
import styled from '@/styles';
import { LinkAction } from '@/types';

const Wrapper = styled('nav', {
  display: 'flex',
  flexDirection: 'column',
  padding: 16,
  width: '100%',
});

const Group = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '24px',

  '&:first-child': {
    marginTop: 0,
  },
});

const Item = styled(NavLink, {
  alignItems: 'center',
  display: 'flex',
  gap: '20px',
  padding: '8px 20px',

  backgroundColor: 'transparent',
  borderRadius: '$lg',
  color: '$textLight',

  fontWeight: '$medium',
  textDecoration: 'none',

  '& + &': {
    marginTop: '2px',
  },

  '&:hover': {
    backgroundColor: '$neutralA4',
  },

  '&.active': {
    backgroundColor: '$themeA12',
    color: '$themeDarkest',

    fontWeight: '$medium',

    '&:hover': {
      backgroundColor: '$themeA16',
    },
  },

  focusPseudoElement: {
    element: 'after',
  },

  variants: {
    status: {
      active: {
        backgroundColor: '$themeA12',
        color: '$themeDarkest',

        fontWeight: '$medium',

        '&:hover': {
          backgroundColor: '$themeA16',
        },
      },
    },
  },
});

type NavigationGroup = { items: LinkAction[]; title?: string };

type Props = {
  groups: NavigationGroup[];
};

const NavigationMenu = ({ groups }: Props) => {
  const unreadChats = useAppSelector((state) => state.pubnub.unreadChats);
  const pendingCustomers = useAppSelector(
    (state) => state.layout.pendingCustomers
  );

  return (
    <Wrapper>
      {groups.map((group, groupIndex) => (
        <Group key={`group-${groupIndex}`}>
          {group.title && (
            <div style={{ margin: '0 10px 10px' }}>
              <Text size="sm" weight="semibold">
                {group.title}
              </Text>
            </div>
          )}
          {group.items.map((item, itemIndex) => {
            const { CustomIcon, hide } = item;
            if (hide) return <></>;
            return (
              <Item
                key={`item-${groupIndex}-${itemIndex}`}
                end={item.end}
                id={`nav-${item.id}`}
                to={item.to}
                onClick={item?.onClick}
              >
                {item.icon && (
                  <Text color="inherit" size="lg">
                    <Icon fixedWidth icon={item.icon} />
                  </Text>
                )}
                {CustomIcon && <CustomIcon />}

                <Text color="inherit" size="md" weight="inherit">
                  {item.label || item.a11yLabel}
                </Text>
                {item.a11yLabel === 'Clients' && pendingCustomers > 0 && (
                  <PendingDotIcon />
                )}
                {item.a11yLabel === 'Chat' && unreadChats && <PendingDotIcon />}
              </Item>
            );
          })}
        </Group>
      ))}
    </Wrapper>
  );
};

NavigationMenu.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(NavigationMenu.Wrapper)';

NavigationMenu.Group = Group;
Group.displayName = 'stitches(NavigationMenu.Group)';

NavigationMenu.Item = Item;
Item.displayName = 'stitches(NavigationMenu.Item)';

export default NavigationMenu;
