import useModal from '@area2k/use-modal';
import { EventClickArg, MoreLinkArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { Divider } from '@mui/material';
import { useCallback, useState, useRef, useEffect } from 'react';

import { ShiftColors } from '..';
import {
  Schedule,
  ShiftInfo,
  useJobDraftActions,
  useJobDraftState,
} from '../../../context';
import ShiftModal from '../ShiftModal';
import { isHoliday, calculateRates, getStartDate } from '../util';

import { DateRange } from './DateRangeInput';
import EventsModal from './EventsModal';

import Button from '@/components/Button';
import CustomErrorsForm, { ErrorSchema } from '@/components/CustomErrorsForm';
import FormElement from '@/components/FormElement';
import MaskedInput from '@/components/MaskedInput';
import Option from '@/components/Option';
import Stack from '@/components/Stack';
import { Body, Heading, Small, Subheading } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import FormColumns from '@/form/FormColumns';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Role } from '@/routes/PrivateRoute';
import styled from '@/styles';
import { Address, JobTypeEnum, Maybe } from '@/types/graphql';
import { formatEventDate, formatEventTime } from '@/util/datetime';
import { centsToCurrency } from '@/util/number';
import './index.css';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});

type Props = {
  minCost: number;
  minPay: number;
  schedules: Schedule[];
  customerId?: string;
  onChange: (schedule: Schedule[]) => void;
  setDisabledButton: (isDisabled: boolean) => void;
  holidays: any[];
  orderType: JobTypeEnum | null;
  setCost: any;
  setPay: any;
  address: Maybe<Address>;
  showPricing: boolean;
};

const ScheduleSection = ({
  minPay,
  minCost,
  schedules,
  customerId,
  onChange,
  setDisabledButton,
  holidays,
  orderType,
  setCost,
  setPay,
  showPricing,
}: Props) => {
  const { currentAdmin } = useAuth();
  const sameWorkerFeature = useFeatureValue(
    FEATURE_TOGGLE.SameWorkerAllShifts,
    false
  );

  const [payRateInput, setPayRateInput] = useState(centsToCurrency(minPay));
  const [billRateInput, setBillRateInput] = useState(centsToCurrency(minCost));
  const [events, setEvents] = useState<MoreLinkArg>();
  const [allShiftsSameWorker, setAllShiftsSameWorker] = useState(
    schedules && schedules.length > 0 ? schedules[0].allShiftsSameWorker : false
  );
  const minDate = getStartDate(new Date(), currentAdmin);
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const calendarRef = useRef<FullCalendar>(null);
  const { updateLastShift } = useJobDraftActions();
  const { lastShiftInfo } = useJobDraftState();

  const [showShiftModal, setShowShiftModal] = useState(false);
  const [shiftModalData, setShiftModalData] = useState<{
    date?: Date;
    schedule?: Schedule;
  }>({
    date: undefined,
    schedule: undefined,
  });

  const errorSchema: ErrorSchema[] = [
    {
      type: 'payRate',
      title: 'Pay rate is below minimum',
      message: `The minimum pay rate is ${centsToCurrency(minPay)}`,
      condition: Math.round(Number(payRateInput) * 100) < minPay,
    },
    {
      type: 'costRate',
      title: 'Bill rate is below minimum',
      message: `The minimum bill rate is ${centsToCurrency(minCost)}`,
      condition: Math.round(Number(billRateInput) * 100) < minCost,
    },
  ];

  const handleHolidayRates = useCallback(
    (schedules: Schedule[], payrate?: number, costrate?: number) => {
      const calcPayRate = payrate || calculateRates(payRateInput, minPay);
      const calcBillRate = costrate || calculateRates(billRateInput, minCost);

      return schedules.map((sch) => {
        const holiday = isHoliday(holidays, sch.dateRange?.startDate);
        if (holiday) {
          return {
            ...sch,
            payRate: Math.floor(calcPayRate * holiday.rate),
            costRate: Math.floor(calcBillRate * holiday.rate),
            originalPayRate: calcPayRate,
            originalCostRate: calcBillRate,
            isHoliday: true,
            rate: holiday.rate,
            minCost,
            minPay,
          };
        } else {
          return {
            ...sch,
            payRate: calcPayRate,
            costRate: calcBillRate,
            originalPayRate: calcPayRate,
            originalCostRate: calcBillRate,
            isHoliday: false,
            rate: undefined,
            minCost,
            minPay,
          };
        }
      });
    },
    [billRateInput, payRateInput, holidays]
  );

  const onScheduleChange = (schedules: Schedule[]) => {
    const newSchedules = handleHolidayRates(schedules);
    onChange(newSchedules);
  };

  const handleAllShiftsSameWorker = (value: boolean) => {
    setAllShiftsSameWorker(value);
    const newSchedules = schedules.map((schedule) => ({
      ...schedule,
      allShiftsSameWorker: value,
    }));
    onChange(newSchedules);
  };

  const handlePayRateChange = (payRateInput: string) => {
    setPayRateInput(payRateInput);
    const payRate = Number(payRateInput) * 100;

    if (payRate > minPay) {
      let newSchedules = schedules.map((schedule) => ({
        ...schedule,
        payRate,
      }));
      newSchedules = handleHolidayRates(newSchedules, payRate);
      onChange(newSchedules);
      setPay(payRate);
    } else {
      let newSchedulesMinPay = schedules.map((schedule) => ({
        ...schedule,
        payRate: minPay,
      }));
      newSchedulesMinPay = handleHolidayRates(newSchedulesMinPay, minPay);
      onChange(newSchedulesMinPay);
      setPay(minPay);
    }
  };

  const handleBillRateChange = (billRateInput: string) => {
    setBillRateInput(billRateInput);
    const billingRate = billRateInput
      ? parseFloat(billRateInput.replace(/,/g, '')) * 100
      : 0;

    if (billingRate > minCost) {
      let newSchedules = schedules.map((schedule) => ({
        ...schedule,
        costRate: billingRate,
      }));
      newSchedules = handleHolidayRates(newSchedules, undefined, billingRate);
      onChange(newSchedules);
      setCost(billingRate);
    } else {
      let newSchedulesMinPay = schedules.map((schedule) => ({
        ...schedule,
        costRate: minCost,
      }));
      newSchedulesMinPay = handleHolidayRates(
        newSchedulesMinPay,
        undefined,
        minCost
      );
      onChange(newSchedulesMinPay);
      setCost(minCost);
    }
  };

  const customCalandarButtons = {
    customPrev: {
      icon: 'chevron-left',
      click: () => handleCustomNavigation('prev'),
    },
    customNext: {
      icon: 'chevron-right',
      click: () => handleCustomNavigation('next'),
    },
  };

  const handleCustomNavigation = (direction: 'prev' | 'next') => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      if (direction === 'prev') {
        calendarApi.prev();
      } else if (direction === 'next') {
        calendarApi.next();
      }
    }
  };

  const eventContent = (arg) => {
    const { quantity, workers, bgColor } = arg.event.extendedProps;
    return (
      <Stack
        justify="apart"
        style={{
          backgroundColor: bgColor,
          padding: '2px 4px',
          color: 'white',
          cursor: 'pointer',
        }}
      >
        <Small color={'inherit'} size={phoneOnly ? 'sm' : 'md'}>
          {arg.event.title}
        </Small>
        {!phoneOnly && (
          <Stack css={{ width: 'auto' }} gap={2}>
            <Body color={'inherit'}>{`${workers}/${quantity}`}</Body>
          </Stack>
        )}
      </Stack>
    );
  };

  const handleShiftClick = (info: EventClickArg) => {
    const editSch = schedules.find((s) => s.groupId === Number(info.event.id));
    if (editSch) {
      setShiftModalData({
        date: editSch.dateRange.startDate!,
        schedule: editSch,
      });
      setShowShiftModal(true);
    }
  };

  const eventContentOnModal = (arg, index) => {
    const { quantity, workers, workerText, bgColor } = arg.event.extendedProps;
    return (
      <Stack
        id={`event-stack-${index}`}
        justify="apart"
        style={{
          backgroundColor: bgColor,
          padding: '4px 10px',
          color: 'white',
          cursor: 'pointer',
          borderRadius: '5px',
        }}
        onClick={() => handleShiftClick(arg)}
      >
        <Heading color={'inherit'}>{arg.event.title}</Heading>
        <Stack vertical css={{ width: 'auto', alignItems: 'flex-end' }} gap={0}>
          <Subheading color={'inherit'}>
            {`${quantity} worker` + (quantity > 1 ? 's' : '')}
          </Subheading>
          {workers > 0 && (
            <Subheading
              color={'inherit'}
            >{`${workers} ${workerText}`}</Subheading>
          )}
        </Stack>
      </Stack>
    );
  };

  const freshSchedule = (date: Date): Schedule => {
    const defaultRange: DateRange = {
      startDate: date,
      endDate: date,
    };
    const payRate = Number(payRateInput) * 100;
    const costRate = Number(billRateInput) * 100;

    return {
      costRate,
      dateRange: defaultRange,
      payRate,
      originalCostRate: costRate,
      originalPayRate: payRate,
      displayDates: defaultRange,
      selectedWorkers: [],
      hiredWorkers: [],
      mandatoryBreakTime: 0,
      payRateInput: centsToCurrency(payRate),
      quantity: 1,
      startTime: '08:00',
      endTime: '12:00',
      groupId: 1,
      allShiftsSameWorker,
    };
  };

  const [showEventsModal, hideEventsModal] = useModal(
    () => (
      <EventsModal
        count={events?.allSegs.length ?? 0}
        hideModal={hideEventsModal}
        title={`Job List ` + formatEventDate(events?.allSegs[0].start)}
      >
        <Stack vertical gap={10}>
          {events?.allSegs?.map((e, index) => {
            return eventContentOnModal(e, index);
          })}
        </Stack>
        <Stack css={{ paddingTop: 20 }}>
          <Button
            a11yLabel="Add shift to job"
            appearance="outline"
            css={{
              width: '100%',
            }}
            id="create-shift-btn"
            label="Create Shift"
            onClick={() => handleDateSelect(events)}
          />
        </Stack>
      </EventsModal>
    ),
    [events]
  );

  const handleMoreLink = (events) => {
    if (phoneOnly) {
      setEvents(events);
      setTimeout(showEventsModal, 100);
      return 'events';
    } else {
      return 'popover';
    }
  };

  const handleDateSelect = (selectInfo) => {
    if (selectInfo.date < minDate) {
      return;
    }
    setShiftModalData({ date: selectInfo.date, schedule: undefined });
    setShowShiftModal(true);
  };

  const handleSaveShift = (info: ShiftInfo) => {
    const updatedSch: Schedule = {
      ...freshSchedule(info.date),
      startTime: info.startTime,
      endTime: info.endTime,
      quantity: info.quantity,
      mandatoryBreakTime: info.mandatoryBreakTime,
      hiredWorkers: info.hiredWorkers,
      selectedWorkers: info.invitedWorkers,
    };
    updateLastShift({ lastShiftInfo: updatedSch });
    if (info.groupId) {
      // edit sch
      const filterSch = schedules.filter((s) => s.groupId !== info.groupId);
      filterSch.push(updatedSch);
      onScheduleChange(filterSch);
    } else {
      // Add Sch
      onScheduleChange([updatedSch, ...schedules]);
    }
    setShowShiftModal(false);
  };

  useEffect(() => {
    if (calendarRef && calendarRef.current) {
      const calendarApi = calendarRef.current?.getApi();
      calendarApi.removeAllEvents();
      if (schedules && schedules.length) {
        schedules.map((s) => {
          const bgColor = ShiftColors.GREEN;

          return calendarApi.addEvent({
            id: s.groupId.toString(),
            title: formatEventTime(s.startTime),
            start: s.dateRange.startDate,
            end: s.dateRange.startDate,
            allDay: true,
            bgColor,
            quantity: s.quantity,
            workers: s.selectedWorkers?.length || s.hiredWorkers?.length,
            workerText: s.selectedWorkers?.length
              ? 'invited'
              : s.hiredWorkers?.length
              ? 'hired'
              : '',
          });
        });
      }
    }
  }, [schedules, calendarRef]);

  return (
    <Wrapper style={{ width: '100%' }}>
      {showPricing && (
        <Stack
          vertical
          justify="apart"
          style={{
            border: phoneOnly ? '' : '1px solid #D3D3D3',
            padding: phoneOnly ? '5px 0px' : '20px',
          }}
        >
          <Subheading>Job Rates</Subheading>
          <FormColumns layout="doubleBoth" phoneFull={phoneOnly}>
            {currentAdmin?.role !== Role.CUSTOMER_ADMIN && (
              <FormElement fontSize="md" label="Pay rate">
                <MaskedInput
                  normalizeZeros
                  padFractionalZeros
                  mask={Number}
                  min={minPay / 100}
                  radix="."
                  scale={2}
                  signed={false}
                  thousandsSeparator=","
                  value={payRateInput}
                  onAccept={(value) => {
                    handlePayRateChange(value);
                  }}
                />
              </FormElement>
            )}
            <FormElement fontSize="md" label="Bill rate">
              <MaskedInput
                normalizeZeros
                padFractionalZeros
                disabled={currentAdmin?.role === Role.CUSTOMER_ADMIN}
                mask={Number}
                min={minCost / 100}
                radix="."
                scale={2}
                signed={false}
                thousandsSeparator=","
                value={billRateInput}
                onAccept={(value) => handleBillRateChange(value)}
              />
            </FormElement>
          </FormColumns>
          <div style={{ width: '100%' }}>
            <CustomErrorsForm
              schema={errorSchema}
              setDisabledButton={setDisabledButton}
            />
          </div>
        </Stack>
      )}
      {phoneOnly && <Divider />}
      <Stack
        vertical
        style={{
          marginBottom: phoneOnly ? '10px' : '50px',
          border: phoneOnly ? '' : '1px solid #D3D3D3',
          padding: phoneOnly ? '5px 0px' : '20px',
        }}
      >
        <Heading>{`Choose ${
          orderType === JobTypeEnum.GIG ? 'Gig' : orderType
        } Schedule`}</Heading>
        <Small>Select a date or multiple dates from calendar</Small>
        {orderType === JobTypeEnum.GIG && sameWorkerFeature && (
          <div style={{ margin: '10px 0px' }}>
            <Option
              appearance="checkbox"
              checked={allShiftsSameWorker}
              id={'allShiftsSameWorker'}
              label={'I want the same worker for the shifts that I have hired'}
              name={'allShiftsSameWorker'}
              type="checkbox"
              onChange={(e) =>
                handleAllShiftsSameWorker(e.currentTarget.checked)
              }
            />
          </div>
        )}

        <div className="full-calendar-wrapper">
          <FullCalendar
            ref={calendarRef}
            customButtons={customCalandarButtons}
            dateClick={handleDateSelect}
            dayCellClassNames={({ date }) => {
              if (date < minDate) {
                return 'disableDate';
              }
              return '';
            }}
            dayMaxEvents={true}
            eventClick={handleShiftClick}
            eventContent={eventContent}
            headerToolbar={{
              start: 'customPrev',
              center: 'title',
              end: 'customNext',
            }}
            height={750}
            // If we give range it will restrict user to change months accordingly
            moreLinkClick={handleMoreLink}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            selectable={true}
          />
        </div>

        {showShiftModal && (
          <ShiftModal
            customerId={customerId!}
            date={shiftModalData.date!}
            hideModal={(e, r) => {
              if (r === 'backdropClick') return;
              setShowShiftModal(false);
            }}
            holidays={holidays}
            lastShiftInfo={lastShiftInfo}
            orderType={orderType}
            schedule={shiftModalData.schedule}
            onSave={handleSaveShift}
          />
        )}
      </Stack>
    </Wrapper>
  );
};

export default ScheduleSection;
