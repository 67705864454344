import { format, parseISO } from 'date-fns';

export const getInvoiceDescription = (
  item: Record<string, any>,
  showOvertime: boolean
) => {
  const {
    shortDescription = '',
    skill,
    isEdited,
    cancelled,
    isOvertime,
    isHoliday,
  } = item;
  const skillName = skill?.name || '';
  const isEditedandSkill = isEdited && skillName;
  let description = skillName || shortDescription;

  if (showOvertime && isOvertime) {
    description = isHoliday
      ? `HOLIDAY OVERTIME: ${skillName}`
      : `OVERTIME: ${skillName}`;
  } else if (isEditedandSkill || cancelled) {
    description = `${skillName} - ${shortDescription}`;
  }
  if (isHoliday && !isOvertime) {
    description = 'HOLIDAY: ' + description;
  }
  return description;
};

export const getRateDesc = (
  item: Record<string, any>,
  showOvertime: boolean
) => {
  const { isOvertime, isHoliday } = item;
  let rateDesc = '';
  if (showOvertime && isOvertime) {
    rateDesc = isHoliday ? `Holiday Overtime` : `Overtime`;
  } else if (!isOvertime && isHoliday) {
    rateDesc = 'Holiday Rate';
  }
  return rateDesc;
};

const isNumberDecimal = (num: number) => num % 1 !== 0;

export const formatNetTime = (netTime: number | string | null) => {
  const netTimeInt = Number(netTime);
  if (netTimeInt) {
    const netTimeHours = netTimeInt / 60;
    return isNumberDecimal(netTimeHours)
      ? netTimeHours.toFixed(2)
      : netTimeHours;
  } else return netTime;
};

export const formatApproveTime = (approveTime: string | null) => {
  if (!approveTime) return '';
  return format(parseISO(approveTime), 'p').toLowerCase();
};

export const formatNetTimeInHrAndMin = (approvedMinutes: number) => {
  const hours: any = Math.floor(approvedMinutes / 60);
  const minutes: any = Math.floor(approvedMinutes % 60);
  return (hours > 0 ? `${hours}h` : '') + ` ${minutes}m`;
};
