import { useReactiveVar } from '@apollo/client';
import { SubmitHelpers } from '@area2k/use-form';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { Snackbar } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { StyledPage } from '../SignUp/styles';
import routes from '../routes';

import { logoWork } from '@/assets/img';
import Button from '@/components/Button';
import GravyWorkLogo from '@/components/GravyWorkLogo';
import Stack from '@/components/Stack';
import { Title } from '@/components/Typography';
import { privacyPolicyUrl } from '@/constants/urls';
import Link from '@/elements/Link';
import Form from '@/form';
import FormFooter from '@/form/FormFooter';
import TextField from '@/form/TextField';
import { useAdminLoginMutation } from '@/graphql';
import useLocationSearch from '@/hooks/useLocationSearch';
import { currentAdminVar, currentTenantVar } from '@/util/apollo/cache';
import { handleMutationFormError } from '@/util/error';
import ls, {
  ACCESS_TOKEN_KEY,
  DEVICE_TOKEN_KEY,
  RESET_PASSWORD,
} from '@/util/localstorage';

type FormValues = { email: string; password: string };

const initialValues: FormValues = {
  email: '',
  password: '',
};

const AppLogin = () => {
  const navigate = useNavigate();
  const { to } = useLocationSearch();
  const deviceToken = ls.get(DEVICE_TOKEN_KEY);
  const destination = useMemo(() => (typeof to === 'string' ? to : '/'), [to]);

  const [successMsg, setSuccessMessage] = useState<string | null>(null);
  const currentTenant = useReactiveVar(currentTenantVar);
  const [loginAdmin, { loading: isLoginAdminLoading }] =
    useAdminLoginMutation();

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        const result = await loginAdmin({
          variables: { ...values, tenantId: currentTenant!.id },
        });

        const {
          accessToken,
          refreshToken,
          tenantAdmin,
          customerAdmin,
          resetPassword,
        } = result.data!.adminLogin;

        ls.multiSet({
          [ACCESS_TOKEN_KEY]: accessToken,
          [DEVICE_TOKEN_KEY]: refreshToken,
          [RESET_PASSWORD]: resetPassword,
        });

        currentAdminVar(tenantAdmin ?? customerAdmin);

        const newDestination = !resetPassword ? destination : '/reset-password';

        navigate(newDestination);
      } catch (err) {
        if (err.graphQLErrors) {
          err.graphQLErrors.forEach((error) => {
            if (error.extensions?.code === 'APP_ACCESS_UNAVAILABLE') {
              navigate('/request-app-access', {
                state: { email: values.email },
              });
            }
          });
        }
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            INVALID_LOGIN: () => ({
              icon: faLock,
              title: 'Invalid login',
              message: 'There is no account matching the email you provided.',
              status: 'warning',
            }),
            APP_ACCESS_REQUESTED: () => ({
              icon: faLock,
              title: 'Invalid login',
              message: 'You will be notified once you are invited',
              status: 'warning',
            }),
            all: (gqlError) => ({
              title: 'Invalid login',
              // TODO: Proper error handling to be done through JIRA ticket
              message:
                'You have a worker account, please sign in with the app (' +
                gqlError.message +
                ')',
              status: 'danger',
            }),
          },
        });
        // }
      }
    },
    [currentTenant]
  );

  const handleClose = () => {
    setSuccessMessage(null);
  };

  if (deviceToken) {
    return <Navigate replace to={destination} />;
  }
  return (
    <StyledPage css={{ alignItems: 'flex-start' }} size="xs">
      <Snackbar
        autoHideDuration={4000}
        id="success-msg"
        message={successMsg}
        open={!!successMsg}
        onClose={handleClose}
      />
      <Stack vertical gap={24}>
        <Stack vertical css={{ paddingTop: '66px' }} gap={24} justify="center">
          <GravyWorkLogo src={logoWork} />
          <Title weight={'normal'}>Sign in to your business account</Title>
        </Stack>
        <Stack vertical gap={24}>
          <Form
            initialValues={initialValues}
            style={{ width: '100%' }}
            onSubmit={handleSubmit}
          >
            <TextField
              autoFocus
              required
              autoComplete="email"
              fieldId="email"
              label="Email Address"
              placeholder="Email address"
              type="email"
            />

            <TextField
              required
              autoComplete="current-password"
              css={{ letterSpacing: '2px' }}
              fieldId="password"
              label="Password"
              placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
              type="password"
            />
            <FormFooter>
              <Stack align="center" gap={16} justify="center" vertical={true}>
                <Button
                  a11yLabel="Sign in"
                  id="sign-in-btn"
                  isLoading={isLoginAdminLoading}
                  loadingLabel="Log In..."
                  style={{ width: '100%' }}
                />
                <Link id="forget-password" to={`/${routes.forgotPassword}`}>
                  Forgot password?
                </Link>
                <Link
                  target="_self"
                  to={{ pathname: `/` }}
                  onClick={() => {
                    window.open(privacyPolicyUrl, '_self');
                  }}
                >
                  Privacy Policy
                </Link>
              </Stack>
            </FormFooter>
          </Form>
        </Stack>
      </Stack>
    </StyledPage>
  );
};

export default AppLogin;
