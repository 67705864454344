import qs from 'query-string';
import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import LoadingState from '@/components/LoadingState';
import { useGetCurrentActorQuery } from '@/graphql';
import { currentAdminVar } from '@/util/apollo/cache';
import ls, { DEVICE_TOKEN_KEY, RESET_PASSWORD } from '@/util/localstorage';
import { isWeb } from '@/util/platform';

const LoginRedirect = ({ didFailAuth }: { didFailAuth: boolean }) => {
  const location = useLocation();

  ls.clearAuth();

  return (
    <Navigate
      replace
      state={{ didFailAuth, didReroute: true }}
      to={{
        pathname: isWeb() ? '/login' : '/app-login',
        search: `?${qs.stringify({ to: location.pathname })}`,
      }}
    />
  );
};

const AppIntroRedirect = ({ didFailAuth }: { didFailAuth: boolean }) => {
  const location = useLocation();
  ls.clearAuth();

  return (
    <Navigate
      replace
      state={{ didFailAuth, didReroute: true }}
      to={{
        pathname: '/mobile-intro',
        search: `?${qs.stringify({ to: location.pathname })}`,
      }}
    />
  );
};
const ResetPasswordRedirect = () => {
  return <Navigate replace to={'/reset-password'} />;
};

const Authenticated = () => {
  const deviceToken = ls.get(DEVICE_TOKEN_KEY);
  const resetPassword = ls.get(RESET_PASSWORD);

  const query = useGetCurrentActorQuery({ skip: !deviceToken });

  useEffect(() => {
    if (query.data) {
      switch (query.data.currentActor.__typename) {
        case 'TenantAdmin':
          const { currentActor: currentTenantAdminActor } = query.data;
          currentAdminVar(currentTenantAdminActor);
          break;

        case 'CustomerAdmin':
          const { currentActor: currentCustomerAdminActor } = query.data;
          currentAdminVar(currentCustomerAdminActor);
          break;

        default:
          currentAdminVar(null);
      }
    }

    return () => {
      currentAdminVar(null);
    };
  }, [query.data]);

  if (!deviceToken || !resetPassword || query.error) {
    if (!isWeb()) {
      return <AppIntroRedirect didFailAuth={!!query.error} />;
    } else {
      return <LoginRedirect didFailAuth={!!query.error} />;
    }
  }

  if (deviceToken && resetPassword !== 'false') {
    return <ResetPasswordRedirect />;
  }

  if (query.loading) {
    return <LoadingState text="Loading your account information..." />;
  }

  return <Outlet />;
};

export default Authenticated;
