import useModal from '@area2k/use-modal';
import qs from 'query-string';

import UpdateAddressModal from './UpdateAddressModal';

import Card from '@/components/Card';
import Stack from '@/components/Stack';
import TextStack from '@/components/TextStack';
import { Small } from '@/components/Typography';
import styled from '@/styles';
import { GetJobQuery, JobStatusEnum } from '@/types/graphql';

type Props = {
  job: GetJobQuery['job'];
  style?: any;
};
const AddressText = styled('div', {
  cursor: 'pointer',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '15.23px',
});
const AddressSection = ({ job, style }: Props) => {
  const [showUpdateModal, hideUpdateModal] = useModal(
    () => <UpdateAddressModal hideModal={hideUpdateModal} job={job} />,
    [job],
  );

  const { address, addressInstructions, status } = job;
  const mapsParams = {
    query: `${address.addressLine1},${address.city},${address.state}`,
  };
  const mapsUrl = `https://www.google.com/maps/search/?api=1&${qs.stringify(
    mapsParams,
  )}`;

  const addressFontColor = '#52C241';

  const addressLine = `${address.addressLine1 ? address.addressLine1 : ''} ${
    address.addressLine2 ? address.addressLine2 : ''
  }`;

  const onClickMap = () => {
    window.open(mapsUrl, '_blank', 'noreferrer');
  };
  return (
    <Card.Section
      actions={
        status === JobStatusEnum.UPCOMING
          ? [
              {
                a11yLabel: 'Change job address instructions',
                label: 'Change',
                onAction: showUpdateModal,
              },
            ]
          : undefined
      }
      borderNone={true}
      style={style || { padding: '10px 20px' }}
      title="Address"
      titleStyle={{ paddingBottom: '5px' }}
    >
      <Stack vertical>
        <AddressText onClick={onClickMap}>
          <TextStack>
            <Small css={{ color: addressFontColor }}>{addressLine}</Small>
            <Small css={{ color: addressFontColor }}>
              {address.city},{address.state} {address.zip}
            </Small>
          </TextStack>
        </AddressText>

        {addressInstructions && (
          <Small>&quot;{addressInstructions}&quot;</Small>
        )}
      </Stack>
    </Card.Section>
  );
};

export default AddressSection;
