import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { PropsWithChildren, ReactNode } from 'react';

import DropdownMenu from './DropdownMenu';

import Action from '@/components/Action';
import Breadcrumbs, {
  Props as BreadcrumbsProps,
} from '@/components/Breadcrumbs';
import IconicButton from '@/components/IconicButton';
import SingleColumnLayout, {
  Props as SingleColumnLayoutProps,
} from '@/components/SingleColumnLayout';
import Stack from '@/components/Stack';
import { Subheading, Title } from '@/components/Typography';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import BREAKPOINTS from '@/styles/breakpoints';
import { GenericAction, GenericActionOrList } from '@/types';
import { isActionList } from '@/util/actions';

const Header = styled('div', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',

  padding: '32px 0 0',
  '@phoneOnly': {
    padding: '24px 0 0',
  },
  variants: {
    headerPadding: {
      true: {
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
  },
});

const Content = styled('div', {
  margin: '32px 0',
  '@phoneOnly': {
    margin: '24px 0',
  },
  variants: {
    noTopMargin: {
      true: {
        marginTop: '0px',
      },
    },
  },
});

const Footer = styled('div', {
  margin: '0 0 32px',
  '@phoneOnly': {
    paddingLeft: '16px',
    paddingRight: '16px',
    margin: '0 0 24px',
  },
});

type CustomAction = GenericAction & {
  ref?: any;
};

export type Props = SingleColumnLayoutProps & {
  breadcrumbs?: BreadcrumbsProps['crumbs'];
  media?: ReactNode;
  primaryAction?: CustomAction;
  secondaryActions?: GenericActionOrList[];
  subtitle?: ReactNode;
  title?: ReactNode;
  headerPadding?: boolean;
  footer?: ReactNode;
  headerMenu?: ReactNode;
  customActions?: ReactNode;
  contentVarient?: String;
};

const pushElements = (primaryArray, secondaryArray) => {
  let primaryArrayClone = [...primaryArray];
  for (let index = 0; index < secondaryArray.length; index++) {
    const action = secondaryArray[index];
    primaryArrayClone = [...primaryArrayClone, [{ ...action }]];
  }
  return primaryArrayClone;
};

const getDropdownMenuActions = (action: GenericActionOrList) => {
  let menuActions: GenericAction[][] = [];

  if (action) {
    if (isActionList(action) && action.actions[0].length > 0) {
      menuActions = [...pushElements(menuActions, action.actions[0])];
    } else {
      menuActions = [...menuActions, [{ ...(action as GenericAction) }]];
    }
  }

  return menuActions;
};

const Page = ({
  breadcrumbs,
  secondaryActions,
  children,
  media = null,
  primaryAction,
  size = 'lg',
  subtitle,
  title,
  headerPadding,
  footer,
  headerMenu,
  customActions,
  contentVarient = 'false',
  ...variants
}: PropsWithChildren<Props>) => {
  const hasHeader =
    subtitle ||
    title ||
    primaryAction ||
    media ||
    (secondaryActions && secondaryActions.length > 0) ||
    (breadcrumbs && breadcrumbs.length > 0);
  const phoneBreakpoint = useMediaQuery(BREAKPOINTS.phoneOnly);

  return (
    <SingleColumnLayout size={size} {...variants}>
      {hasHeader && (
        <Header headerPadding={headerPadding}>
          <Stack gap={phoneBreakpoint ? 8 : 24}>
            {media && <div>{media}</div>}
            <Stack vertical gap={2}>
              {breadcrumbs && breadcrumbs.length > 0 && (
                <Breadcrumbs crumbs={breadcrumbs} />
              )}
              {typeof title === 'string' ? (
                <Title>{title}</Title>
              ) : title === undefined ? null : (
                title
              )}
              {typeof subtitle === 'string' ? (
                <Subheading color="light">{subtitle}</Subheading>
              ) : subtitle === undefined ? null : (
                subtitle
              )}
            </Stack>
          </Stack>
          <PageActions
            customActions={customActions}
            primaryAction={primaryAction}
            secondaryActions={secondaryActions}
          />
          {headerMenu}
        </Header>
      )}
      <Content noTopMargin={contentVarient}>{children}</Content>
      <Footer>{footer}</Footer>
    </SingleColumnLayout>
  );
};

const PageActions = ({
  primaryAction,
  secondaryActions,
  customActions,
}: Pick<Props, 'primaryAction' | 'secondaryActions' | 'customActions'>) => {
  const phoneBreakpoint = useMediaQuery(BREAKPOINTS.phoneOnly);

  if (phoneBreakpoint) {
    return (
      <Stack css={{ width: phoneBreakpoint ? 'auto' : null }} justify="end">
        {primaryAction &&
          (primaryAction.ref ? (
            <div ref={primaryAction.ref}>
              <Action.Button action={primaryAction} appearance="primary" />
            </div>
          ) : (
            <Action.Button action={primaryAction} appearance="primary" />
          ))}
        {secondaryActions && (
          <DropdownMenu
            actions={getDropdownMenuActions(secondaryActions[0])}
            justify="right"
            size="md"
          >
            <IconicButton a11yLabel="actions" icon={faChevronDown} size="lg" />
          </DropdownMenu>
        )}
      </Stack>
    );
  }

  return (
    <Stack gap={8} justify="end">
      {customActions}
      {secondaryActions?.map((action, index) => (
        <Action.Button
          key={index}
          action={action}
          appearance={action.onAction ? 'secondary' : 'clear'}
        />
      ))}
      {primaryAction && primaryAction.ref && (
        <div ref={primaryAction.ref}>
          <Action.Button action={primaryAction} appearance="primary" />
        </div>
      )}
      {primaryAction && !primaryAction.ref && (
        <Action.Button action={primaryAction} appearance="primary" />
      )}
    </Stack>
  );
};

Page.Header = Header;
Header.displayName = 'stitches(Page.Header)';

export default Page;
