import { useCallback } from 'react';

import { TabDefinition } from './types';

import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';

const Wrapper = styled('li', {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',

  color: '$textLighter',
  variants: {
    showTabOnMobile: {
      true: {
        '@phoneOnly': {
          width: '100%',
        },
      },
    },
  },
});

const Button = styled('button', {
  all: 'unset',
  display: 'inline-flex',
  gap: 12,
  justifyContent: 'center',
  padding: 12,
  width: '100%',

  color: 'inherit',
  cursor: 'pointer',

  lineHeight: 1.5,

  focusPseudoElement: {
    element: 'after',
    borderWidth: 0,
    inset: '12px 12px',
  },

  '& > span': {
    padding: '4px 8px',
  },

  '&::before': {
    content: '',
    display: 'none',
    height: 2,
    margin: '0 4px',

    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,

    backgroundColor: '$neutralLighter',
    borderRadius: '$lg $lg 0 0',
    '@phoneOnly': {
      height: 4,
      borderRadius: 0,
    },
  },

  '&:hover, &:focus-visible': {
    '&::before': {
      display: 'block',
    },
  },
  '@phoneOnly': {
    paddingLeft: 0,
    paddingRight: 0,
  },

  variants: {
    isActive: {
      true: {
        color: '$textDefault',

        '&::before': {
          display: 'block',

          backgroundColor: '$themeDefault',
        },
      },
      false: {},
    },
  },
});

const TabId = styled('span', {
  padding: '2px 12px 3px 4px',
  marginLeft: '12px',
  borderRadius: '100px',
  width: '45px',
  height: '22px',
  border: '1px solid #D3D3D3',
  color: '#808B9D',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '18px',
});

export type Props = {
  index: number;
  isActive: boolean;
  showTabOnMobile?: boolean;
  tab: TabDefinition;
  onSelect: (index: number) => void;
  minWidth?: string;
};

const Tab = ({
  index,
  showTabOnMobile,
  isActive,
  tab,
  onSelect,
  minWidth,
}: Props) => {
  const onClick = useCallback(() => onSelect(index), [index, onSelect]);
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  return (
    <>
      {phoneOnly ? (
        <Wrapper
          css={{ minWidth }}
          role="presentation"
          showTabOnMobile={showTabOnMobile}
        >
          <Button id={`tab-btn-${index}`} isActive={isActive} onClick={onClick}>
            <span>
              {tab.label || tab.a11yLabel}
              {/* {tab.id && <TabId>&ensp; {tab.id} </TabId>} */}
            </span>
          </Button>
        </Wrapper>
      ) : (
        <Wrapper
          css={{ minWidth }}
          role="presentation"
          showTabOnMobile={showTabOnMobile}
        >
          <Button id={`tab-btn-${index}`} isActive={isActive} onClick={onClick}>
            <span>
              {tab.label || tab.a11yLabel}
              {tab.id && <TabId>&ensp; {tab.id} </TabId>}
            </span>
          </Button>
        </Wrapper>
      )}
    </>
  );
};

Tab.Wrapper = Wrapper;
Wrapper.displayName = 'stitches(Tabs.Tab.Wrapper)';

Tab.TabList = Button;
Button.displayName = 'stithces(Tabs.Tab.Button)';

export default Tab;
