import { useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import Billing from './Billing';
import JobEditor from './JobEditor';
import {
  OrderActions,
  OrderActionsContext,
  OrderActionType,
  OrderStateContext,
  stateReducer,
} from './context';

import Page from '@/components/Page';
import useMediaQuery from '@/hooks/useMediaQuery';
import ROUTES from '@/routes/routes';

const CreateGig = () => {
  const [state, dispatch] = useReducer(stateReducer, {
    orderType: null,
    billing: null,
    jobs: [],
  });

  const navigate = useNavigate();

  const onCloseEditor = () => {
    navigate(ROUTES.basePath, { replace: true });
  };

  const actions: OrderActions = {
    addJob: (job) => {
      dispatch({ type: OrderActionType.ADD_JOB, job });
      // closeEditor();
    },
    clearJobs: () => dispatch({ type: OrderActionType.CLEAR_JOBS }),
    removeJob: (index) => dispatch({ type: OrderActionType.REMOVE_JOB, index }),
    replaceJob: (index, job) =>
      dispatch({ type: OrderActionType.REPLACE_JOB, index, job }),
    setBilling: (billing) =>
      dispatch({ type: OrderActionType.SET_BILLING, billing }),
    setOrderType: (orderType) =>
      dispatch({ type: OrderActionType.SET_ORDER_TYPE, orderType }),
    setHolidays: (holidays) =>
      dispatch({ type: OrderActionType.SET_HOLIDAYS, holidays }),
  };
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <OrderActionsContext.Provider value={actions}>
      <OrderStateContext.Provider value={state}>
        {state.billing ? (
          <>
            <JobEditor onClose={onCloseEditor} />
          </>
        ) : (
          <Page
            headerPadding={phoneOnly}
            noPadding={phoneOnly}
            size={phoneOnly ? 'full' : 'md'}
            title="New order"
          >
            <Billing />
          </Page>
        )}
      </OrderStateContext.Provider>
    </OrderActionsContext.Provider>
  );
};

export default CreateGig;
