import { StitchesVariants } from '@stitches/react';
import {
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithChildren,
  RefAttributes,
  useState,
} from 'react';

import Footer, { Props as FooterProps } from './Footer';
import Header, { Props as HeaderProps } from './Header';
import Section from './Section';

import styled from '@/styles';

const Wrapper = styled('div', {
  width: '100%',

  backgroundColor: 'white',
  borderRadius: '$xxl',
  boxShadow: '$1',

  '&:first-child': {
    borderTopLeftRadius: '$xxl',
    borderTopRightRadius: '$xxl',
  },

  '&:last-child': {
    borderBottomLeftRadius: '$xxl',
    borderBottomRightRadius: '$xxl',
  },

  variants: {
    size: {
      default: { width: '100%' },
      sm: { width: '80%' },
    },
    noRadius: {
      true: {
        borderRadius: 0,
        boxShadow: 'none',
        borderBottom: '1px solid $neutralLightest',
        '&:first-child': {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        '&:last-child': {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        },
      },
      false: {},
    },
    showBorder: {
      true: {
        border: '1px solid #D3D3D3',
        borderBottom: '1px solid #D3D3D3 !important',
      },
      false: {},
    },
    footerSpacing: {
      true: {
        paddingBottom: 15,
      },
      false: {},
    },
  },
});

Wrapper.displayName = 'stitches(Card.Wrapper)';

export type Variants = StitchesVariants<typeof Wrapper>;

export type Props = Variants &
  PropsWithChildren<{
    actions?: HeaderProps['actions'];
    id?: string;
    primaryFooterAction?: FooterProps['primaryAction'];
    secondaryFooterActions?: FooterProps['secondaryActions'];
    sectioned?: boolean;
    accordion?: boolean;
    title?: HeaderProps['title'];
    tooltipText?: HeaderProps['tooltipText'];
    changeStyle?: Object;
    headerBg?: boolean;
    headerNobg?: boolean;
    headerSpacing?: boolean;
    open?: boolean;
    actionsDropdown?: HeaderProps['actionsDropdown'];
  }>;

type CardType = ForwardRefExoticComponent<
  Props & RefAttributes<HTMLDivElement>
> & {
  Footer: typeof Footer;
  Header: typeof Header;
  Section: typeof Section;
};

const Card = forwardRef<HTMLDivElement, Props>(
  (
    {
      actions,
      accordion,
      children,
      id,
      changeStyle,
      primaryFooterAction,
      secondaryFooterActions,
      sectioned,
      title,
      tooltipText,
      headerBg,
      headerSpacing,
      headerNobg,
      open = false,
      actionsDropdown,
      ...props
    },
    ref
  ) => {
    const [showSection, setShowSection] = useState(open);
    const hasHeader = title || (actions && actions.length > 0);
    const hasFooter =
      primaryFooterAction ||
      (secondaryFooterActions && secondaryFooterActions.length > 0);

    const handleHeaderClick = () => {
      setShowSection(!showSection);
    };

    return (
      <Wrapper
        ref={ref}
        id={id}
        noRadius={accordion}
        style={changeStyle}
        {...props}
      >
        {hasHeader && (
          <Header
            accordion={accordion}
            actions={actions}
            actionsDropdown={actionsDropdown}
            headerNobg={headerNobg}
            headerSpacing={headerSpacing || accordion}
            open={showSection}
            title={title}
            tooltipText={tooltipText}
            wrapperBackground={headerBg}
            onClick={handleHeaderClick}
          />
        )}
        {accordion ? (
          showSection && (
            <Section flush={true} open={showSection}>
              {children}
            </Section>
          )
        ) : sectioned ? (
          <Section>{children}</Section>
        ) : (
          children
        )}
        {hasFooter && (
          <Footer
            primaryAction={primaryFooterAction}
            secondaryActions={secondaryFooterActions}
          />
        )}
      </Wrapper>
    );
  }
) as CardType;

Card.displayName = 'Card';

Card.Footer = Footer;
Card.Header = Header;
Card.Section = Section;

export default Card;
