const routes = {
  basePath: '/',

  orders: 'orders',
  gigsCreate: 'creategig',
  gigOrderCreate: 'creategigorder',
  orderDetail: ':orderId',
  orderJobDetail: ':orderId/jobs/:jobId',
  orderJobDetailOld: ':orderId/jobsold/:jobId',

  clients: 'clients',
  customerDetail: ':customerId',
  customerAccountDetail: ':customerId/accounts/:accountId',
  customerAdminDetail: ':customerId/admin/:adminId',

  contacts: 'contacts',

  invoices: 'invoices',
  invoicesDetail: ':invoiceId',
  invoicesPay: ':invoiceId/pay',
  invoiceGenerator: 'invoice-generator',
  invoiceGeneratorDetail: 'invoice-generator?clientId',

  workers: 'workers',
  workerDetail: ':workerId',

  chat: 'chat',
  inappchat: 'inappchat',

  // Customer Admin
  myTeam: 'my-team',
  myTeamAdminDetail: ':adminId',
  myCompany: 'my-company',
  myCompanyAccountDetail: 'accounts/:accountId',
  myProfile: 'my-profile',

  // Payroll Runs
  payrolls: 'payrolls',
  payrollDetail: ':payrollId&status=:payrollStatus',

  // Reports
  reports: 'reports',

  jobmonitor: 'job-monitor',

  login: 'login',
  signUp: 'sign-up',
  signOut: 'sign-out',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  validationAccount: 'validation-account/:caseId',
  linkExpire: 'link-expire',
  payInvoice: 'pay-invoice/:invoiceId',

  mobileIntro: '/mobile-intro',
  appLogin: '/app-login',
  requestAppAccess: '/request-app-access',
  waitlist: '/waitlist',
};

export default routes;
