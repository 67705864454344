import { ApolloProvider } from '@apollo/client';
import { ModalProvider } from '@area2k/use-modal';
import { App as CapacitorApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import { IonApp, IonContent, IonRefresher } from '@ionic/react';
import InfoRounded from '@mui/icons-material/InfoRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { useEffect, useState } from 'react';
import { ModalProvider as ReactModalHookProvider } from 'react-modal-hook';
import { Provider as ReduxProvider } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import LoadingState from './components/LoadingState';
import { GAEvent } from './constants/gaevents';
import { V3Endpoints } from './constants/urls';
import { StripeProvider } from './context';
import axiosClient from './util/axios/axiosClient';
import { PullToRefreshScreens } from './util/constants';
import { isWeb } from './util/platform';

import RouterDebug from '@/components/RouterDebug';
import Config from '@/config';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import useAuth from '@/hooks/useAuth';
import PaymentMethodProvider from '@/hooks/usePaymentMethod';
import AppRouter from '@/routes';
import { store } from '@/store';
import useAnalytics from '@/util/analytics';
import client from '@/util/apollo/client';

import './index.css';

Sentry.init({
  dsn: Config.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: Config.ENVIRONMENT,
  release: Config.BUILD_COMMIT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
});

const gb = new GrowthBook({
  apiHost: Config.GROWTHBOOK_API_HOST,
  clientKey: Config.GROWTHBOOK_CLIENT_KEY,
});

const App = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [featureFlagLoaded, setFeatureFlagLoaded] = useState(false);

  const analytics = useAnalytics(process.env.GA_TRACKING_ID);

  useEffect(() => {
    gb.loadFeatures()
      .then(() => {
        setFeatureFlagLoaded(true);
      })
      .catch((e) => console.error(e));
  }, []);
  const { currentAdmin } = useAuth();

  useEffect(() => {
    analytics.logPageView(window.location.pathname + window.location.search);
    if (!isWeb()) {
      checkAppVersion();
    }
  }, []);

  const [refreshing, setRefreshing] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const doRefresh = (event: CustomEvent) => {
    setRefreshing(true);
    window.location.reload();
    event.detail.complete();
  };

  useEffect(() => {
    if (!isWeb()) {
      CapacitorApp.addListener('appStateChange', ({ isActive }) => {
        if (isActive) {
          if (location.pathname === '/inappchat') {
            navigate(0);
          }
          checkAppVersion();
        }
      });
      return () => {
        CapacitorApp.removeAllListeners();
      };
    }
  }, [location, currentAdmin, featureFlagLoaded]);

  const checkAppVersion = () => {
    if (!isWeb()) {
      axiosClient
        .get(`${V3Endpoints.CHECK_APP_VERSION}`, {
          params: {
            app_type: `client_${Capacitor.getPlatform()}`.toUpperCase(),
          },
        })
        .then((res) => {
          if (
            Number(
              `${res?.data[0]?.min_supported_version}`.replaceAll('.', '')
            ) > Number(Config.BUILD_VERSION.substring(1).replaceAll('.', ''))
          ) {
            setUpdateAvailable(true);
            analytics.logEvent(
              GAEvent.ClientAppUpdate,
              Config.BUILD_VERSION.substring(1)
            );
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const GetSubComponents = () => {
    if (!isWeb()) {
      return SubComponentsWithoutStripe();
    } else {
      return <StripeProvider>{SubComponentsWithoutStripe()}</StripeProvider>;
    }
  };

  const disablePullToRefresh = () => {
    return (
      isWeb() ||
      (!PullToRefreshScreens.includesList.includes(location.pathname) &&
        PullToRefreshScreens.startsWithList.filter((l) =>
          location.pathname.startsWith(l)
        ).length === 0)
    );
  };

  const updateAppModal = () => {
    const forceUpdateClientApp = gb.getFeatureValue(
      FEATURE_TOGGLE.ForceUpdateClientApp,
      false
    );

    if (!forceUpdateClientApp) {
      return null;
    }

    const url: string =
      Capacitor.getPlatform() === 'ios'
        ? Config.APP_STORE_URL
        : Config.PLAY_STORE_URL;
    return (
      <div className="force-update">
        <div className="force-update-content">
          <div className="force-update-text">
            <span className="force-update-icon">
              <InfoRounded fontSize="inherit" />
            </span>
            <br />
            <span className="force-update-header-text">
              Time to update your app!
            </span>
            <br />
            <div style={{ lineHeight: 'normal', padding: '10px' }}>
              <span className="force-update-body-text">
                Download the latest app update to get the best experience.
              </span>
            </div>
          </div>
          <div
            className="force-update-footer"
            onClick={() => {
              analytics.logEvent(
                GAEvent.ClientAppForceUpdated,
                Config.BUILD_VERSION.substring(1)
              );
              Browser.open({ url });
            }}
          >
            Update
          </div>
        </div>
      </div>
    );
  };

  const SubComponentsWithoutStripe = () => {
    return (
      <PaymentMethodProvider>
        <ModalProvider>
          <ReactModalHookProvider>
            <IonContent className="ion-content ion-padding" scrollEvents={true}>
              <IonRefresher
                disabled={disablePullToRefresh()}
                slot="fixed"
                onIonRefresh={doRefresh}
              >
                {refreshing ? <LoadingState text="Reloading..." /> : null}
              </IonRefresher>
              {updateAvailable ? updateAppModal() : null}
              <RouterDebug />
              <AppRouter />
            </IonContent>
          </ReactModalHookProvider>
        </ModalProvider>
      </PaymentMethodProvider>
    );
  };

  return (
    <IonApp>
      <ApolloProvider client={client}>
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <GrowthBookProvider growthbook={gb}>
              {GetSubComponents()}
            </GrowthBookProvider>
          </LocalizationProvider>
        </ReduxProvider>
      </ApolloProvider>
    </IonApp>
  );
};

export default App;
