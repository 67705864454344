import {
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';

import Stack from '@/components/Stack';
import Button from '@/elements/Button';
import Icon from '@/elements/Icon';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import {
  MessageBarType,
  ReloadInvoiceStatusEnum,
  setReloadInvoices,
  updateMessages,
} from '@/store/slices/pubnubSlice';
import styled from '@/styles';

import './index.css';

const MessageBar = styled('div', {
  padding: '5px 20px',
  margin: '15px 30px',
  borderRadius: '5px',
  variants: {
    type: {
      SUCCESS: {
        backgroundColor: '#EEFFEC',
        border: 'solid 1px #ADDB9B',
      },
      ERROR: {
        border: 'solid 1px #FEB1B1',
        backgroundColor: '#F5E7E9',
      },
      INFORMATION: {
        border: 'solid 1px #FFDD92',
        backgroundColor: '#FCF6D6',
      },
    },
  },
});

const MessageTitle = styled('p', {
  fontWeight: '600',
  fontSize: '14px',
  color: '#262626',
});

const MessageContent = styled('div', {
  fontWeight: '400',
  fontSize: '14px',
  color: '#262626',
});

const MessageContainer = () => {
  const messages = useAppSelector((state) => state.pubnub.messages);
  const dispatch = useAppDispatch();
  const removeMessageAtIndex = (index) => {
    const msgs = [...messages];
    msgs.splice(index, 1);
    dispatch(updateMessages(msgs));
  };

  const reloadInvoiceMessages = () => {
    dispatch(setReloadInvoices(ReloadInvoiceStatusEnum.MANUAL));
  };

  return messages.map((message, index) => (
    <Messagebar
      key={`key-${index}`}
      hideMessage={() => removeMessageAtIndex(index)}
      index={index}
      message={message}
      reloadInvoices={reloadInvoiceMessages}
    />
  ));
};

type MessagebarProp = {
  message: MessageBarType;
  index: number;
  hideMessage: () => void;
  reloadInvoices: () => void;
};

const Messagebar = ({
  message,
  index,
  hideMessage,
  reloadInvoices,
}: MessagebarProp) => {
  const { type, title, content, reloadLink } = message;
  const onReload = () => {
    reloadInvoices();
    hideMessage();
  };

  return (
    <MessageBar key={index} type={type}>
      <Stack>
        <Stack vertical gap={5}>
          <Stack>
            <Icon
              icon={type === 'SUCCESS' ? faCheckCircle : faExclamationTriangle}
              size={'1x'}
              theme={
                type === 'ERROR'
                  ? 'danger'
                  : type === 'INFORMATION'
                  ? 'information'
                  : 'success'
              }
            />
            <MessageTitle>{title}</MessageTitle>
          </Stack>
          <MessageContent className="msgContent">
            {ReactHtmlParser(content)}
            {reloadLink && (
              <>
                {' Click to '}
                <Button appearance={'plain'} onClick={onReload}>
                  Refresh
                </Button>
              </>
            )}
          </MessageContent>
        </Stack>
        <IconButton onClick={hideMessage}>
          <Close fontSize="small" id={`close-btn-${index}`} />
        </IconButton>
      </Stack>
    </MessageBar>
  );
};

export default MessageContainer;
