import { FieldContext } from '@area2k/use-form';
import { faUserCheck, faUsers } from '@fortawesome/free-solid-svg-icons';
import { ChangeEvent, useEffect, useState } from 'react';

import { useOrderState } from '../../../context';
import {
  PostSetting,
  PublishInEnum,
  Step,
  selectionOptions,
  useJobDraftActions,
  useJobDraftState,
} from '../../context';
import BottomBar from '../BottomBar';
import Layout from '../Layout';

import Button from '@/components/Button';
import SelectableBox from '@/components/SelectableBox';
import SingleColumnLayout from '@/components/SingleColumnLayout';
import Stack from '@/components/Stack';
import { Body, Heading, Subheading } from '@/components/Typography';
import { GAEvent } from '@/constants/gaevents';
import Icon from '@/elements/Icon';
import Text from '@/elements/Text';
import Form from '@/form';
import TextSelectField from '@/form/TextSelectField';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import useAnalytics from '@/util/analytics';

const PostSetttings = styled('div', {
  display: 'grid',
  gap: 24,
  gridAutoRows: '1fr',
  gridTemplateColumns: '1fr 1fr',
  width: '100%',

  '& > *': {
    height: '100%',
  },
  '@phoneOnly': {
    gridTemplateColumns: 'auto',
  },
});

const TextSelectFieldWrapper = styled('div', {
  width: '100%',
});

const SelectableBody = styled('div', {
  padding: '24px',
});

const publishInOptions = [
  { value: PublishInEnum.ONE_HOUR, label: 'in 1 hour' },
  { value: PublishInEnum.SIX_HOURS, label: 'in 6 hours' },
  { value: PublishInEnum.TWELVE_HOURS, label: 'in 12 hours' },
  {
    value: PublishInEnum.TWENTY_FOUR_HOURS,
    label: 'in 1 day',
  },
  {
    value: PublishInEnum.FORTY_EIGHT_HOURS,
    label: 'in 2 days',
  },
  {
    value: PublishInEnum.SEVENTY_TWO_HOURS,
    label: 'in 3 days',
  },
  { value: PublishInEnum.NEVER, label: 'Never' },
];

type FormValues = {
  publishIn: PublishInEnum;
  postSetting: PostSetting;
};

type Props = {
  setStep: (step: Step) => void;
};

const PublishingStep = ({ setStep }: Props) => {
  const jobState = useJobDraftState();
  const { updatePublishing, updateSchedules, updateLastShift } =
    useJobDraftActions();
  const { billing } = useOrderState();

  const { logEvent } = useAnalytics();
  const [startTime, setStartTime] = useState<number>(new Date().getTime());

  const [formValues, setFormValues] = useState<FormValues>({
    publishIn: jobState.publishIn,
    postSetting: jobState.postSetting,
  });

  const handleSettingChange = (ev: ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      publishIn:
        ev.currentTarget.value === selectionOptions.MY_SELECTIONS
          ? PublishInEnum.ONE_HOUR
          : PublishInEnum.IMMEDIATELY,
      postSetting: ev.currentTarget.value as PostSetting,
    });
  };

  const handleFormValuesChange = <T extends any>(
    fieldContext: FieldContext<T>
  ) => {
    setFormValues((prev) => ({
      ...prev,
      publishIn: fieldContext.value as PublishInEnum,
    }));
  };

  useEffect(() => {
    setStartTime(new Date().getTime());
  }, []);

  const handleSubmit = async () => {
    const { postSetting, publishIn } = formValues;
    if (postSetting === selectionOptions.MY_SELECTIONS) {
      updatePublishing({ postSetting, publishIn });
    } else {
      updatePublishing({ postSetting, publishIn: PublishInEnum.IMMEDIATELY });
    }
    if (jobState.postSetting && postSetting !== jobState.postSetting) {
      // if job post setting change empty schedules
      updateSchedules({ schedules: [] });
      updateLastShift({ lastShiftInfo: undefined });
    }
    logEvent(GAEvent.StepTwoGig, billing?.account?.id, {
      time_spent: (new Date().getTime() - startTime) / 1000,
    });
    return setStep(Step.SCHEDULE);
  };
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <Layout>
      <Form initialValues={formValues} onSubmit={handleSubmit}>
        <SingleColumnLayout
          noPadding={phoneOnly}
          size={phoneOnly ? 'full' : 'sm'}
        >
          <Stack vertical align="center" gap={24}>
            <Heading>Who should see your job post first?</Heading>
            <PostSetttings>
              <SelectableBox
                id="postSetting.everyone"
                isSelected={
                  formValues.postSetting === selectionOptions.EVERYONE
                }
                name="postSetting"
                type="radio"
                value="everyone"
                onChange={handleSettingChange}
              >
                <SelectableBody>
                  <Stack vertical gap={16}>
                    <Text color="light" size="xxxl">
                      <Icon icon={faUsers} />
                    </Text>
                    <Stack vertical gap={8}>
                      <Subheading>Everyone</Subheading>
                      <Body>
                        The job will be visible to all qualified GravyWorkers
                      </Body>
                    </Stack>
                  </Stack>
                </SelectableBody>
              </SelectableBox>
              <SelectableBox
                id="postSetting.my_selections"
                isSelected={
                  formValues.postSetting === selectionOptions.MY_SELECTIONS
                }
                name="postSetting"
                type="radio"
                value="my_selections"
                onChange={handleSettingChange}
              >
                <SelectableBody>
                  <Stack vertical gap={16}>
                    <Text color="light" size="xxxl">
                      <Icon icon={faUserCheck} />
                    </Text>
                    <Stack vertical gap={8}>
                      <Subheading>Private</Subheading>
                      <Body>
                        Pick the GravyWorkers you would like to see the job
                        before everyone else
                      </Body>
                    </Stack>
                  </Stack>
                </SelectableBody>
              </SelectableBox>
            </PostSetttings>

            {formValues.postSetting === selectionOptions.MY_SELECTIONS && (
              <TextSelectFieldWrapper>
                <TextSelectField
                  callback={handleFormValuesChange}
                  fieldId="publishIn"
                  label="Automatically publish to all qualified GravyWorkers..."
                  options={publishInOptions}
                />
              </TextSelectFieldWrapper>
            )}

            <BottomBar>
              <Button
                a11yLabel="Go back to previous step"
                appearance="outline"
                id="back-btn"
                label="Back"
                type="button"
                onClick={() => setStep(Step.SKILL)}
              />

              <Button
                a11yLabel="Submit form"
                disabled={!formValues.postSetting}
                id="continue-btn"
                label="Continue"
                type="submit"
                onClick={handleSubmit}
              />
            </BottomBar>
          </Stack>
        </SingleColumnLayout>
      </Form>
    </Layout>
  );
};

export default PublishingStep;
