import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import '@/styles';

const root = document.querySelector('#root');
if (!root) throw new Error('no root element!');

render(
  <Router>
    <App />
  </Router>,
  root
);
