import useModal from '@area2k/use-modal';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { differenceInHours, parseISO } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { getTaxTypeLabel } from '../Order/util';

import DetailsCard from './DetailsCard';
import JobBadge from './JobBadge';
import JobHeader from './JobHeader';
import PageSkeleton from './PageSkeleton';
import WorkerTable from './WorkerTable';
import CancellationDissmissModal from './modals/CancellationAndDismissModal';
import ConfirmModalCancellation from './modals/ConfirmModalCancellation';
import { getJobStatusByJob, JobStatus } from './util';

import Alert from '@/components/Alert';
import Badge from '@/components/Badge';
import Page from '@/components/Page';
import Stack from '@/components/Stack';
import Tabs, { TabDefinition } from '@/components/Tabs';
import { TIME_TO_REFRESH } from '@/constants/general';
import {
  ContextCancelRemoveModal,
  ContextConfirmModalCancellation,
} from '@/constants/job';
import {
  useGetJobQuery,
  useUnPublishJobMutation,
  usePublishJobMutation,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import styled from '@/styles';
import { GetJobQuery, JobTypeEnum } from '@/types/graphql';
import { DAY_HOURS } from '@/util/constants';
import { formatISO, isWithin24Hours } from '@/util/date';
import { getHiredWorkersByJobWorkers } from '@/util/job';

const JobDetailsWrapper = styled('div', {
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'flex-start',
  gap: '50px',
  padding: '20px',
  '@phoneOnly': {
    justifyContent: 'center',
    gap: 0,
    padding: 0,
  },
});
const Job = () => {
  const { jobId } = useParams();
  const { currentAdminIsCustomerAdmin } = useAuth();
  const query = useGetJobQuery({
    variables: { jobId },
    pollInterval: TIME_TO_REFRESH,
  });
  const [tab, setTab] = useState(0);
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const [showCancelJobModal, hideCancelJobModal] = useModal(
    ({ job }: { job: GetJobQuery['job'] }) => (
      <CancellationDissmissModal
        context={ContextCancelRemoveModal.CancelJob}
        hideModal={() => {
          hideCancelJobModal();
          hideConfirmCancelJob();
        }}
        job={job}
      />
    ),
    []
  );

  const [confirmCancelJob, hideConfirmCancelJob] = useModal(
    ({ job }: { job: GetJobQuery['job'] }) => (
      <ConfirmModalCancellation
        context={ContextConfirmModalCancellation.CancelJob}
        hideModal={hideConfirmCancelJob}
        onAction={() => {
          showCancelJobModal({ job });
        }}
      />
    ),
    []
  );

  const onCancelPress = (job: GetJobQuery['job']) => {
    const firstShiftStartAt = parseISO(job.firstShiftStartAt);

    const differenceHours = differenceInHours(firstShiftStartAt, new Date());

    if (differenceHours < DAY_HOURS && currentAdminIsCustomerAdmin) {
      return confirmCancelJob({ job });
    }

    return showCancelJobModal({ job });
  };

  const [jobPublish] = usePublishJobMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(job),
        fields: { job() {} },
      });
    },
  });
  const [jobUnpublish] = useUnPublishJobMutation({
    update: (cache) => {
      cache.modify({
        id: cache.identify(job),
        fields: { job() {} },
      });
    },
  });

  const togglePublish = async () => {
    const value = jobPublished;
    try {
      if (value) {
        return await jobUnpublish({ variables: { jobId } });
      }

      return await jobPublish({ variables: { jobId } });
    } catch (err) {
      return err;
    }
  };

  if (!query.data) {
    return <PageSkeleton />;
  }

  const job = query.data.job;
  const jobStatus = getJobStatusByJob(job);
  const jobPublished = job.published;
  const jobWorkersCount = getHiredWorkersByJobWorkers(job.jobWorkers);
  const taxTypeLabel = getTaxTypeLabel(job.taxType);
  const TABS: TabDefinition[] = [
    {
      a11yLabel: 'Workers details',
      label: 'Worker Details',
      id: `${jobWorkersCount.length}/${job.quantity}`,
    },
    { a11yLabel: 'Job Details', label: 'Job Details' },
  ];

  const getJobStatusLabel = () => {
    const startAtIso = parseISO(job.shifts[0].startAt);
    if (isWithin24Hours(startAtIso)) {
      if (!jobPublished && jobStatus === JobStatus.CANCELLED) {
        return [JobStatus.UNPUBLISHED, jobStatus];
      } else if (!jobPublished) {
        return JobStatus.UNPUBLISHED;
      } else if (jobStatus === JobStatus.CANCELLED) {
        return jobStatus;
      } else {
        return [];
      }
    } else if (!jobPublished) {
      return [JobStatus.UNPUBLISHED, jobStatus];
    } else {
      return jobStatus;
    }
  };

  const displayLabels = () => {
    const LABELS = [
      <JobBadge key={'status'} jobStatus={getJobStatusLabel()} />,
    ];
    if (job.jobType === JobTypeEnum.LTA) {
      LABELS.push(
        <Badge
          key={'lta'}
          css={{ marginRight: '10px' }}
          label="LTA"
          status={'themeDefault'}
        />
      );
    }
    if (taxTypeLabel && !currentAdminIsCustomerAdmin) {
      LABELS.push(<Badge key={'taxtype'} label={taxTypeLabel} />);
    }
    return LABELS;
  };

  const workerDetails = () => {
    return (
      <Stack
        vertical
        gap={24}
        style={phoneOnly ? { padding: '0px' } : { padding: '20px' }}
      >
        <WorkerTable
          job={job}
          jobStatus={jobStatus}
          refetchJob={() => query.refetch()}
        />
      </Stack>
    );
  };
  const jobDetails = () => {
    return (
      <JobDetailsWrapper>
        <DetailsCard job={job} />
      </JobDetailsWrapper>
    );
  };

  return (
    <>
      <Page
        headerPadding={phoneOnly}
        noPadding={phoneOnly}
        size={phoneOnly ? 'full' : 'xl'}
      >
        <Stack vertical css={{ padding: phoneOnly ? '0 16px' : null }} gap={0}>
          <JobHeader
            displayStatus={displayLabels()}
            handleCloseClick={() => onCancelPress(job)}
            handlePublishClick={togglePublish}
            isJobPublished={jobPublished}
            job={job}
            jobStatus={jobStatus}
          />
          <Tabs
            selected={tab}
            showTabOnMobile={true}
            tabs={TABS}
            onSelect={setTab}
          />
        </Stack>
        <Stack vertical gap={24} style={{ marginTop: '20px' }}>
          {job.postedAt && !job.published && jobStatus !== JobStatus.COMPLETED && (
            <div style={{ width: '100% ' }}>
              <Alert
                description={`This job will be published to GravyWorkers on ${formatISO(
                  job.postedAt,
                  "EEE, MMM do 'at' h:mmbbb"
                )}`}
                icon={faClock}
                status="neutral"
                title="Job scheduled to be posted"
              />
            </div>
          )}

          <div style={{ width: '100%' }}>
            {tab === 0 ? workerDetails() : jobDetails()}
          </div>
        </Stack>
      </Page>
    </>
  );
};

export default Job;
