import { StitchesVariants } from '@stitches/react';
import { CSSProperties, FC } from 'react';

import TooltipInfo from './TooltipInfo';

import styled from '@/styles';

const Wrapper = styled('div', {
  width: '100%',
  variants: {
    displayType: {
      row: {
        display: 'flex',
        flexDirection: 'row',
      },
      rowSpace: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      block: {
        marginBottom: '1.25rem',
        flexDirection: 'column',
        '@phoneOnly': {
          marginBottom: '1rem',
        },
      },
    },
  },
  defaultVariants: {
    displayType: 'block',
  },
});

const Heading = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  variants: {
    displayType: {
      row: {
        marginBottom: '0px',
      },
      rowSpace: {
        marginBottom: '0px',
      },
      block: {
        marginBottom: '6px',
      },
    },
  },
  defaultVariants: {
    displayType: 'block',
  },
});

const Label = styled('label', {
  display: 'block',
  color: '$textLight',
  fontSize: '$sm',
  fontWeight: '$medium',
  variants: {
    fontSize: {
      sm: {
        fontSize: '$sm',
      },
      md: {
        fontSize: '$md',
      },
      lg: {
        fontSize: '$lg',
      },
    },
    displayType: {
      row: {
        marginRight: '10px',
        whiteSpace: 'nowrap',
        color: '#7A7A7A',
        '@phoneOnly': {
          fontSize: '14px',
          fontWeight: '400',
        },
      },
      rowSpace: {
        marginRight: '10px',
        whiteSpace: 'nowrap',
      },
      block: {
        marginRight: '0px',
        '@phoneOnly': {
          fontSize: '13px',
          color: '#000',
          fontWeight: '400',
        },
      },
      marginBottomNull: {
        marginBottom: '0',
      },
    },
  },
  defaultVariants: {
    fontSize: 'sm',
    displayType: 'block',
  },
});

Wrapper.displayName = 'stitches(FormElement.Wrapper)';
Label.displayName = 'stitches(FormElement.Label)';

type LabelVariants = StitchesVariants<typeof Label>;
type WrapperVariants = StitchesVariants<typeof Wrapper>;

type Props = LabelVariants &
  WrapperVariants & {
    htmlFor?: string;
    label?: string;
    hint?: string;
    style?: CSSProperties;
    icon?: any;
  };

const FormElement: FC<Props> = ({
  children,
  htmlFor,
  label,
  hint,
  displayType = 'block',
  icon,
  ...rest
}) => (
  <Wrapper displayType={displayType}>
    {icon && icon}
    {(label || hint) && (
      <Heading displayType={displayType}>
        {label && (
          <Label displayType={displayType} htmlFor={htmlFor} {...rest}>
            {label}
          </Label>
        )}
        {hint && <TooltipInfo text={hint} />}
      </Heading>
    )}
    {children}
  </Wrapper>
);

export default FormElement;
