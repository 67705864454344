import useModal from '@area2k/use-modal';
import { faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';

import AddWorkerToList from './AddWorkerToListModal';

import Avatar from '@/components/Avatar';
import Card from '@/components/Card';
import EmptyState from '@/components/EmptyState';
import IconicButton from '@/components/IconicButton';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import Tabs, { TabDefinition } from '@/components/Tabs';
import TextStack from '@/components/TextStack';
import { Body, Small } from '@/components/Typography';
import Link from '@/elements/Link';
import { TableCell, TableRow } from '@/elements/Table';
import { useRemoveCustomerWorkerRelationshipMutation } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { IconColor } from '@/styles/colors';
import { GetCustomerQuery, RelationshipKindEnum } from '@/types/graphql';

type Item = GetCustomerQuery['customer']['workerRelationships'][0];

type Props = {
  customer: GetCustomerQuery['customer'];
  accordion?: boolean;
};

const kinds: RelationshipKindEnum[] = [
  RelationshipKindEnum.FAVORITED,
  RelationshipKindEnum.BLOCKED,
  RelationshipKindEnum.LTA_WORKERS,
];

const HEADER_FIELDS = ['GravyWorker', 'Phone Number', ''];
const HEADER_FIELDS_LTA = ['NAME', 'LOCATION', 'ACTIONS'];

const WorkerList = ({ customer, accordion = false }: Props) => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const [tab, setTab] = useState(0);
  const [visibleWorkers, setVisibleWorkers] = useState<Item[]>([]);

  const RELATIONSHIP_TABS: TabDefinition[] = useMemo(() => {
    const relTabs = [
      {
        a11yLabel: 'View favorite GravyWorkers',
        label: 'Favorite',
        htmlId: 'tab-favorite-worker',
      },
      {
        a11yLabel: 'View blocked GravyWorkers',
        label: 'Blocked',
        htmlId: 'tab-blocked-worker',
      },
    ];
    if (customer?.ltaAllowed) {
      relTabs.push({
        a11yLabel: 'View lta GravyWorkers',
        label: 'LTA Workers',
        htmlId: 'tab-lta-worker',
      });
    }
    return relTabs;
  }, [customer]);

  const [showAddModal, hideAddModal] = useModal(
    () => (
      <AddWorkerToList
        currentTab={tab}
        customer={customer}
        hideModal={hideAddModal}
      />
    ),
    [tab]
  );

  const [deleteRelationship] = useRemoveCustomerWorkerRelationshipMutation();

  const handleRemove = async (item: Item) => {
    try {
      await deleteRelationship({
        variables: {
          customerId: customer.id,
          workerId: item.worker.id,
          kind: kinds[tab],
        },
        update: (cache) => {
          cache.modify({
            id: `Customer:${customer.id}`,
            fields: {
              workerRelationships(existingRefs = [], { readField }) {
                return existingRefs.filter(
                  (relationshipRef) =>
                    item.id !== readField('id', relationshipRef)
                );
              },
            },
          });
        },
      });
    } catch (err) {
      console.error(err);
    }
  };
  const isLtaTab = tab === 2;

  useEffect(() => {
    setVisibleWorkers(
      customer.workerRelationships.filter(
        (worker) => worker.kind === kinds[tab]
      )
    );
  }, [tab, customer.workerRelationships]);

  const TableBody = ({ items }: any) => (
    <tbody>
      {items.map((item: any) =>
        isLtaTab ? (
          <LTATableRow key={item.id} item={item} />
        ) : (
          <TableRow key={item.id} mobile={phoneOnly}>
            <TableCell noCellTitle={phoneOnly} stack={phoneOnly}>
              <Stack justify={'apart'}>
                <Stack>
                  <div>
                    <Avatar
                      firstName={item.worker.user.firstName}
                      size="sm"
                      src={item.worker.avatarUrl}
                    />
                  </div>
                  <TextStack spacing="tight">
                    <Link to={`../../workers/${item.worker.id}`}>
                      <Body color="inherit" weight="medium">
                        {item.worker.user.firstName}{' '}
                        {item.worker.user.middleName ?? ''}{' '}
                        {item.worker.user.lastName}
                      </Body>
                    </Link>
                    <Small>{item.worker.user.email}</Small>
                  </TextStack>
                </Stack>
                {phoneOnly && (
                  <IconicButton
                    a11yLabel="Remove this relationship"
                    appearance="clear"
                    icon={faTrash}
                    id={`btn-clear-${item.id}`}
                    size="sm"
                    status="danger"
                    onClick={() => handleRemove(item)}
                  />
                )}
              </Stack>
            </TableCell>
            <TableCell data-celltitle={'Phone Number'} stack={phoneOnly}>
              <Body>{item.worker.user.phoneNumber}</Body>
            </TableCell>
            {!phoneOnly && (
              <TableCell>
                <IconicButton
                  a11yLabel="Remove this relationship"
                  appearance="clear"
                  icon={faTrash}
                  id={`btn-clear-${item.id}`}
                  size="sm"
                  status="danger"
                  onClick={() => handleRemove(item)}
                />
              </TableCell>
            )}
          </TableRow>
        )
      )}
    </tbody>
  );

  const LTATableRow = ({ item }: any) => (
    <TableRow key={item.id} mobile={phoneOnly}>
      <TableCell noCellTitle={phoneOnly} stack={phoneOnly}>
        <Stack justify={'apart'}>
          <Stack>
            <div>
              <Avatar
                firstName={item.worker.user.firstName}
                size="sm"
                src={item.worker.avatarUrl}
              />
            </div>
            <TextStack spacing="tight">
              <Link to={`../../workers/${item.worker.id}`}>
                <Body color="inherit" weight="medium">
                  {item.worker.user.firstName}{' '}
                  {item.worker.user.middleName ?? ''}{' '}
                  {item.worker.user.lastName}
                </Body>
              </Link>
            </TextStack>
          </Stack>
          {phoneOnly && (
            <IconicButton
              a11yLabel="Remove this relationship"
              appearance="clear"
              css={{ color: IconColor }}
              icon={faTrashAlt}
              id={`btn-clear-${item.id}`}
              size="sm"
              onClick={() => handleRemove(item)}
            />
          )}
        </Stack>
      </TableCell>
      <TableCell
        data-celltitle={'Location'}
        noCellTitle={false}
        stack={phoneOnly}
      >
        <Body>
          {item.worker?.city}, {item.worker?.state}
        </Body>
        <></>
      </TableCell>
      {!phoneOnly && (
        <TableCell>
          <IconicButton
            a11yLabel="Remove this relationship"
            appearance="clear"
            css={{ color: IconColor }}
            icon={faTrashAlt}
            id={`btn-clear-${item.id}`}
            size="sm"
            onClick={() => handleRemove(item)}
          />
        </TableCell>
      )}
    </TableRow>
  );

  return (
    <Card
      accordion={accordion}
      actions={[
        {
          a11yLabel: 'Add GravyWorker to current list',
          label: phoneOnly ? 'Add' : 'Add GravyWorker',
          onAction: showAddModal,
          id: 'add-gravy-worker',
        },
      ]}
      title="GravyWorkers"
    >
      <Tabs
        fit
        selected={tab}
        showTabOnMobile={true}
        tabs={RELATIONSHIP_TABS}
        onSelect={setTab}
      />
      {visibleWorkers.length > 0 ? (
        <Table>
          {!phoneOnly && (
            <TableHeader
              fields={isLtaTab ? HEADER_FIELDS_LTA : HEADER_FIELDS}
              headerBgColor={isLtaTab ? '#E2E2E2' : ''}
            />
          )}
          <TableBody items={visibleWorkers} />
        </Table>
      ) : (
        <EmptyState title="No GravyWorkers" />
      )}
    </Card>
  );
};

export default WorkerList;
