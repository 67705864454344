import useModal from '@area2k/use-modal';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  CheckBoxOutlined,
  IndeterminateCheckBoxOutlined,
  MoreVert,
} from '@mui/icons-material';
import { Checkbox, IconButton } from '@mui/material';
import { usePDF } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { ReactNode, useMemo, useEffect, useState } from 'react';
import * as React from 'react';

import { disableCheckbox } from '../../Order/util';
import PrintWorkerTimeSheet from '../PrintWorkerTimeSheet';
import { JobStatus, enableCheckboxForClient } from '../util';

import ActionTab from './ActionsTab';
import HiredWorkerRow from './HiredWorkerRow';
import CustomTooltipJob from './TooltipJob';
import WorkerMobileView from './WorkerMobileView';
import { RenderWorkerInfo } from './workerUtils';

import PeopleSearchIcon from '@/assets/icons/PeopleSearchIcon';
import Card from '@/components/Card';
import StyledChip from '@/components/Chip';
import EmptyState from '@/components/EmptyState';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import Icon from '@/elements/Icon';
import { TableRow, TableCell, TableHeading } from '@/elements/Table';
import Text from '@/elements/Text';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import { jobColors } from '@/styles/colors';
import { GetJobQuery, JobTypeEnum, TimesheetStatusEnum } from '@/types/graphql';
import {
  checkIfJobIsActiveByStatus,
  getDroppedWorkersByJobWorkers,
  getHiredWorkersByJobWorkers,
  getRemovedWorkersByJobWorkers,
  sortDroppedWorkersByDropDate,
} from '@/util/job';

import './index.css';
import NewOfferModal from '../WorkerList/JobOfferList/NewOfferModal';
import AddWorkerModal from '../WorkerList/modals/AddWorkerModal';

import { useFeatureValue } from '@growthbook/growthbook-react';

import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import Button from '@/components/Button';
import WorkerStaffingModel from '@/components/WorkerStaffingModel';

type Props = {
  job: GetJobQuery['job'];
  jobStatus: JobStatus;
  refetchJob: () => void;
};
type WorkerInfo = GetJobQuery['job']['jobWorkers'][0];
const TABLE_HEADERS = [
  { name: 'WORKER INFO', style: { width: '30%' } },
  { name: 'SHIFT INFO', style: { width: '15%' } },
  'BREAK',
  'TOTAL TIME',
  'TIMESHEET',
  'EDIT INFO',
  'REVIEWER',
  'ACTIONS',
];

const DisableRow = ({
  invited,
  StatusChip,
  isClientAdmin,
  isDropped,
  isNoShow,
}: {
  invited: any;
  StatusChip: ReactNode;
  isClientAdmin: boolean;
  isDropped: boolean;
  isNoShow: boolean;
}) => {
  const reason = invited?.dismissReason || invited.dropReason || undefined;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    reason && setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    reason && setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const phoneOnly = useMediaQuery('(max-width: 559px)');

  const getName = (worker, isDropped) => {
    let fullName = '';
    const user = isDropped ? worker.worker.user : worker.dismissedBy;
    fullName += user.firstName;
    if (user.middleName !== null && user.middleName !== undefined) {
      fullName += ` ${user.middleName}`;
    }
    fullName += ` ${user.lastName}`;
    return fullName;
  };

  return (
    <>
      {phoneOnly ? (
        <TableRow>
          <WorkerMobileView
            checkboxIds={[]}
            disable={true}
            disableCheckbox={true}
            isClientAdmin={isClientAdmin}
            menuItems={undefined}
            workerInfo={invited.worker}
          />
        </TableRow>
      ) : (
        <>
          <TableRow
            key={invited.id}
            aria-haspopup="true"
            aria-owns={open ? 'mouse-over-popover' : undefined}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <TableCell>
              <Checkbox disabled={true} size="small" style={{ padding: 0 }} />
            </TableCell>
            <TableCell>
              {
                <RenderWorkerInfo
                  StatusChip={StatusChip}
                  disable={true}
                  isClientAdmin={isClientAdmin}
                  workerInfo={invited.worker}
                />
              }
            </TableCell>

            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
            <TableCell>-</TableCell>
            <TableCell>
              <IconButton disabled={true}>
                <MoreVert />
              </IconButton>
            </TableCell>
          </TableRow>
          {reason && (
            <CustomTooltipJob
              anchorEl={anchorEl}
              eventDate={isDropped ? invited.droppedAt : invited.dismissedAt}
              eventDoneBy={getName(invited, isDropped)}
              handlePopoverClose={handlePopoverClose}
              isDropped={isDropped}
              isNoShow={isNoShow}
              open={open}
              reason={
                isDropped ? invited.dropReason : invited.dismissReason ?? ''
              }
            />
          )}
        </>
      )}
    </>
  );
};

const WorkerTable = ({ job, jobStatus, refetchJob }: Props) => {
  const jobWorkers = job.jobWorkers;
  const jobInvites = job.jobOffers;
  const isJobActive = checkIfJobIsActiveByStatus(jobStatus);
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [isSelecting, setIsSelecting] = React.useState(false);

  const staffingOrderCreation = useFeatureValue(
    FEATURE_TOGGLE.StaffingOrderCreation,
    false
  );

  const staffingOrderAndJobScreen = useFeatureValue(
    FEATURE_TOGGLE.StaffingOrderAndJobScreen,
    false
  );
  const [showStaffingModel, setShowStaffModel] = useState(false);

  const [checkboxIds, setCheckboxIds] = useState<string[]>([]);
  const { currentAdminIsCustomerAdmin: isClientAdmin } = useAuth();

  const showHireOption = () => {
    if (job.jobType === JobTypeEnum.LTA && !job.account.customer.ltaAllowed) {
      return false;
    } else if (!isClientAdmin) {
      return !disableCheckbox(jobStatus, job.firstShiftStartAt);
    } else {
      return isJobActive;
    }
  };

  const [showOfferModal, hideOfferModal] = useModal(
    () => <NewOfferModal hideModal={hideOfferModal} job={job} />,
    [job]
  );
  const [showAddModal, hideAddModal] = useModal(
    () => <AddWorkerModal hideModal={hideAddModal} job={job} />,
    [job]
  );

  const droppedWorkers = useMemo(() => {
    const droppedWorkersArray = getDroppedWorkersByJobWorkers(jobWorkers);
    const sortedDroppedWorkers =
      sortDroppedWorkersByDropDate(droppedWorkersArray);

    return sortedDroppedWorkers;
  }, [jobWorkers]);

  const { removedWorkers, containsTimesheet } = useMemo(() => {
    const removedWorkers = getRemovedWorkersByJobWorkers(jobWorkers);
    const containsTimesheet = !!removedWorkers?.find((r) =>
      job.timesheets.find((t) => t.jobWorker.id === r.id)
    );

    return { removedWorkers, containsTimesheet };
  }, [jobWorkers]);

  const hiredWorkers = useMemo(() => {
    const hired = getHiredWorkersByJobWorkers(jobWorkers);
    // SORTING
    const notimesheet: WorkerInfo[] = [];
    const incomplete: WorkerInfo[] = [];
    const pending: WorkerInfo[] = [];
    const approved: WorkerInfo[] = [];
    const rejected: WorkerInfo[] = [];
    const noshow: WorkerInfo[] = [];

    hired.forEach((h) => {
      const timesheet = job.timesheets.find(
        (timesheet) => timesheet.jobWorker.id === h.id
      );
      if (!h.showed) {
        noshow.push(h);
      } else if (!timesheet) {
        notimesheet.push(h);
      } else if (timesheet.status === TimesheetStatusEnum.IN_PROGRESS) {
        const containcheckout =
          timesheet.approvedCheckoutAt || timesheet.reportedCheckoutAt;
        containcheckout ? pending.push(h) : incomplete.push(h);
      } else if (timesheet.status === TimesheetStatusEnum.PENDING) {
        pending.push(h);
      } else if (timesheet.status === TimesheetStatusEnum.APPROVED) {
        approved.push(h);
      } else if (timesheet.status === TimesheetStatusEnum.REJECTED) {
        rejected.push(h);
      }
    });
    return [
      ...notimesheet,
      ...incomplete,
      ...pending,
      ...approved,
      ...rejected,
      ...noshow,
    ];
  }, [jobWorkers]);

  const disableSelectAllForClient = useMemo(() => {
    if (isClientAdmin && hiredWorkers.length) {
      for (let i = 0; i < hiredWorkers.length; i++) {
        if (!hiredWorkers[i].showed) continue;
        const ts = job.timesheets.find(
          (t) => t.jobWorker.id === hiredWorkers[i].id
        );
        if (enableCheckboxForClient(ts, jobStatus)) return false;
      }
      return true;
    }
    return false;
  }, [hiredWorkers, isClientAdmin, jobStatus]);

  const [instance, updateInstance] = usePDF({
    document: PrintWorkerTimeSheet({ job, hiredWorkers }),
  });

  useEffect(() => {
    updateInstance(PrintWorkerTimeSheet({ job, hiredWorkers }));
  }, [job, hiredWorkers]);

  const { pendingInv, declinedInv } = useMemo(() => {
    const pendingInv: any[] = [];
    const declinedInv: any[] = [];
    jobInvites
      .filter((item) => item.acceptedAt == null)
      .forEach((inv) => {
        if (inv.rejectedAt) {
          declinedInv.push(inv);
        } else {
          pendingInv.push(inv);
        }
      });
    return { pendingInv, declinedInv };
  }, [jobInvites]);

  const handleSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const ids: string[] = [];
      hiredWorkers.forEach((jw) => {
        const isNoShow = !jw.showed;
        if (isNoShow) return;
        if (isClientAdmin) {
          const ts = job.timesheets.find((t) => t.jobWorker.id === jw.id);
          if (enableCheckboxForClient(ts, jobStatus)) {
            ids.push(jw.id);
          }
        } else if (jobStatus !== JobStatus.COMPLETED) {
          const ts = job.timesheets.find((t) => t.jobWorker.id === jw.id);
          if (ts) {
            ids.push(jw.id);
          }
        } else if (!isNoShow) {
          ids.push(jw.id);
        }
      });
      setCheckboxIds(ids);
    } else {
      setCheckboxIds([]);
    }
  };

  const allSelected = useMemo(() => {
    if (checkboxIds.length) {
      return checkboxIds.length === jobWorkers.length + jobInvites.length;
    }
    return false;
  }, [checkboxIds, jobWorkers, jobInvites]);

  useEffect(() => {
    if (!checkboxIds.length) setIsSelecting(false);
  }, [checkboxIds]);
  return (
    <>
      <Card
        actions={[
          {
            a11yLabel: 'Print GravyWorker Timesheet to this job',
            label: 'Print Timesheet',
            isLoading: instance.loading,
            href: instance.url || '',
            download: `${job.account.customer.name}_${job.skill.name}_${format(
              new Date(job.firstShiftStartAt),
              'MMddyyyy'
            )}`,
            disabled: !hiredWorkers || hiredWorkers.length === 0,
          },
          ...((!staffingOrderCreation || !staffingOrderAndJobScreen) &&
          isJobActive &&
          job.jobType !== JobTypeEnum.LTA
            ? [
                {
                  a11yLabel: 'Invite GravyWorker to this job',
                  id: 'job-invite-worker',
                  label: 'Invite Employee',
                  onAction: showOfferModal,
                },
              ]
            : []),
          ...((!staffingOrderCreation || !staffingOrderAndJobScreen) &&
          showHireOption()
            ? [
                {
                  a11yLabel: 'Hire GravyWorker to this job',
                  id: 'job-hire-worker',
                  label: 'Hire Employee',
                  onAction: showAddModal,
                },
              ]
            : []),
        ]}
        actionsDropdown={
          staffingOrderCreation && staffingOrderAndJobScreen ? (
            <Button
              a11yLabel={'Staff Job'}
              disabled={!showHireOption()}
              id="staff-job-btn"
              label="Staff Job"
              style={{ marginLeft: '20px' }}
              type="button"
              onClick={() => setShowStaffModel(true)}
            />
          ) : undefined
        }
        noRadius={phoneOnly}
        title={
          <>
            {hiredWorkers.length < job.quantity && (
              <PeopleSearchIcon
                style={{
                  marginLeft: 10,
                  fontSize: '15px',
                  top: '3px',
                  marginRight: 10,
                }}
                titleAccess="Under Staffed"
              />
            )}

            <Text as="span" color="default" size="md" weight="normal">
              {hiredWorkers.length} hired of <b>{job.quantity}</b> needed
            </Text>
          </>
        }
      >
        <div style={{ marginTop: '12px' }}>
          <ActionTab
            checkboxIds={checkboxIds}
            isClientAdmin={isClientAdmin}
            job={job}
            setCheckboxIds={setCheckboxIds}
          />

          <Table>
            {!phoneOnly && (
              <TableHeader
                checkBoxHeader={
                  <TableHeading style={{ width: '1%' }}>
                    <Checkbox
                      checked={checkboxIds.length > 0}
                      checkedIcon={
                        allSelected ? (
                          <CheckBoxOutlined htmlColor="#332F2D" />
                        ) : (
                          <IndeterminateCheckBoxOutlined htmlColor="#332F2D" />
                        )
                      }
                      disabled={
                        (jobStatus === JobStatus.UPCOMING &&
                          job.timesheets.length === 0) ||
                        jobStatus === JobStatus.CANCELLED ||
                        disableSelectAllForClient
                      }
                      id="select-all"
                      size="small"
                      style={{ padding: 0 }}
                      onChange={handleSelectAllClick}
                    />
                  </TableHeading>
                }
                fields={TABLE_HEADERS}
                headerBgColor={jobColors.greyLigher}
              />
            )}

            <tbody>
              {hiredWorkers.length > 0 &&
                hiredWorkers.map((w) => (
                  <HiredWorkerRow
                    key={w.id}
                    checkboxIds={checkboxIds}
                    isClientAdmin={isClientAdmin}
                    isSelecting={isSelecting}
                    job={job}
                    jobStatus={jobStatus}
                    setCheckboxIds={setCheckboxIds}
                    setIsSelecting={setIsSelecting}
                    worker={w}
                  />
                ))}
              {pendingInv.length > 0 &&
                pendingInv.map((p) => (
                  <DisableRow
                    key={p.id}
                    StatusChip={
                      <StyledChip
                        label="Pending"
                        size="small"
                        sx={{ fontSize: '0.8rem' }}
                      />
                    }
                    invited={p}
                    isClientAdmin={isClientAdmin}
                    isDropped={false}
                    isNoShow={false}
                  />
                ))}
              {droppedWorkers.length > 0 &&
                droppedWorkers.map((d) => (
                  <DisableRow
                    key={d.id}
                    StatusChip={
                      <StyledChip
                        icon={
                          <Icon
                            fixedWidth
                            color="#FF5C00"
                            icon={faInfoCircle}
                            style={{ fontSize: '14px' }}
                          />
                        }
                        label="Dropped"
                        size="small"
                        sx={{
                          fontSize: '0.8rem',
                          bgcolor: '#FCF6D6',
                          color: '#ECA300',
                        }}
                      />
                    }
                    invited={d}
                    isClientAdmin={isClientAdmin}
                    isDropped={true}
                    isNoShow={false}
                  />
                ))}
              {removedWorkers.length > 0 &&
                removedWorkers.map((w) =>
                  containsTimesheet ? (
                    <HiredWorkerRow
                      key={w.id}
                      checkboxIds={checkboxIds}
                      isClientAdmin={isClientAdmin}
                      job={job}
                      jobStatus={jobStatus}
                      setCheckboxIds={setCheckboxIds}
                      worker={w}
                    />
                  ) : (
                    <DisableRow
                      key={w.id}
                      StatusChip={
                        <StyledChip
                          icon={
                            <Icon
                              fixedWidth
                              color="#FF5C00"
                              icon={faInfoCircle}
                              style={{ fontSize: '14px' }}
                            />
                          }
                          label="Removed"
                          size="small"
                          sx={{
                            fontSize: '0.8rem',
                            bgcolor: '#FFEFE1',
                            color: '#FF5C00',
                          }}
                        />
                      }
                      invited={w}
                      isClientAdmin={isClientAdmin}
                      isDropped={false}
                      isNoShow={false}
                    />
                  )
                )}

              {declinedInv.length > 0 &&
                declinedInv.map((dec) => (
                  <DisableRow
                    key={dec.id}
                    StatusChip={
                      <StyledChip
                        label="Declined"
                        size="small"
                        sx={{ fontSize: '0.8rem', color: '#7456ED' }}
                      />
                    }
                    invited={dec}
                    isClientAdmin={isClientAdmin}
                    isDropped={false}
                    isNoShow={false}
                  />
                ))}
            </tbody>
          </Table>
          {jobWorkers.length === 0 && jobInvites.length === 0 && (
            <EmptyState
              text={
                isJobActive
                  ? 'Hire employee for this job by clicking the button above'
                  : 'There were no employees hired for this job'
              }
              title="No hired employees"
            />
          )}
        </div>
      </Card>
      {showStaffingModel && (
        <WorkerStaffingModel
          customerId={job.account.customer.id}
          hideModal={() => {
            if (refetchJob) {
              refetchJob();
            }
            setShowStaffModel(false);
          }}
          jobTaxType={job.account.customer.jobTaxType!}
          selectedJobs={[job]}
          showShiftList={false}
        />
      )}
    </>
  );
};

export default WorkerTable;
