import { useFeatureValue } from '@growthbook/growthbook-react';
import {
  CheckBoxOutlined,
  IndeterminateCheckBoxOutlined,
  SubdirectoryArrowRight,
} from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';

import { HEADER_FIELDS } from '../constants';
import { useInvoiceGeneratorState } from '../context';

import ActionTab from './ActionsTab';
import InvoicesFilters from './InvoicesFilters';

import { HalfClock } from '@/assets/icons';
import Button from '@/components/Button';
import Card from '@/components/Card';
import LoadingState from '@/components/LoadingState';
import Page from '@/components/Page';
import QueryEmptyState from '@/components/QueryEmptyState';
import Stack from '@/components/Stack';
import Table from '@/components/Table';
import TableHeader from '@/components/TableHeader';
import { Small } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { HOUR_IN_MINUTES } from '@/constants/time';
import Link from '@/elements/Link';
import { TableCell, TableHeading, TableRow } from '@/elements/Table';
import {
  useGenerateInvoiceTimesheetsQuery,
  useInvoiceGenerateMutation,
} from '@/graphql';
import { useAppDispatch, useAppSelector } from '@/hooks/store';
import useMediaQuery from '@/hooks/useMediaQuery';
import { getRoundOff } from '@/routes/Agency/Job/util';
import {
  ReloadInvoiceStatusEnum,
  addMessage,
  setReloadInvoices,
} from '@/store/slices/pubnubSlice';
import { IconColor, jobColors } from '@/styles/colors';
import {
  InvoiceItem,
  Timesheet,
  TimesheetFilterSetInput,
  TimesheetInvoiceStatusEnum,
} from '@/types/graphql';
import { minutesToHoursString } from '@/util/convertion';
import { formatISO } from '@/util/date';
import { getInvoiceDescription } from '@/util/invoice';
import { centsToCurrency } from '@/util/number';

const fontStyleColor = { color: '#262626' };
const initialFilters = {
  approvedCheckinAt: undefined,
  invoiceStatus: TimesheetInvoiceStatusEnum.ALL,
};

const ITEMS_PER_PAGE = 20;

const Invoices = () => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { billing } = useInvoiceGeneratorState();

  const [filters, setFilters] =
    useState<TimesheetFilterSetInput>(initialFilters);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: ITEMS_PER_PAGE,
  });
  const [data, setData] = useState<Timesheet[]>([]);
  const reloadInvoices = useAppSelector((state) => state.pubnub.reloadInvoices);
  const dispatch = useAppDispatch();
  const showTwoDecimalHours = useFeatureValue(
    FEATURE_TOGGLE.TwoDecimalHoursFeature,
    false
  );

  const handleSelectAllClick = () => {
    if (selectedIds.length === 0) {
      const ids = data.filter((ts) => !ts.holdByInvoiceId).map((x) => x.id);
      setSelectedIds(ids);
    } else if (selectedIds.length > 0) {
      setSelectedIds([]);
    }
  };

  const handleCheckClick = (checked, itemId) => {
    if (checked) {
      setSelectedIds((ids) => [...ids, itemId]);
    } else {
      setSelectedIds((ids) => ids.filter((i) => i !== itemId));
    }
  };

  useEffect(() => {
    setAllSelected(selectedIds.length === data.length);
  }, [selectedIds, data]);

  const [generateInvoice] = useInvoiceGenerateMutation();

  const query = useGenerateInvoiceTimesheetsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      customerId: billing!.customer.id,
      page: pagination.currentPage,
      perPage: pagination.itemsPerPage,
      filters: {
        account: { value: billing!.account.id },
        invoiceStatus: filters.invoiceStatus,
        approvedCheckinAt: filters.approvedCheckinAt,
      },
    },
  });

  const resetQueryParams = (pageSize: number = ITEMS_PER_PAGE) => {
    setSelectedIds([]);
    setAllSelected(false);
    setPagination({
      currentPage: 1,
      itemsPerPage: pageSize,
    });
  };

  useEffect(() => {
    if (reloadInvoices !== ReloadInvoiceStatusEnum.OFF) {
      dispatch(setReloadInvoices(ReloadInvoiceStatusEnum.OFF));
      if (reloadInvoices === ReloadInvoiceStatusEnum.AUTO) {
        resetQueryParams(
          data.length > ITEMS_PER_PAGE ? data.length : ITEMS_PER_PAGE
        );
      } else {
        resetQueryParams();
      }
      query.refetch();
    }
  }, [reloadInvoices]);

  useEffect(() => {
    setData([]);
    resetQueryParams();
  }, [filters]);

  useEffect(() => {
    setShowLoadMore(!query.loading);
    if (!query.loading && !query.error) {
      if (
        query.data?.generateInvoiceTimesheets?.items &&
        query.data?.generateInvoiceTimesheets?.items.length > 0
      ) {
        if (pagination.currentPage === 1) {
          setData([...query.data?.generateInvoiceTimesheets?.items]);
        } else {
          setData([...data, ...query.data?.generateInvoiceTimesheets?.items]);
        }

        if (
          query.data?.generateInvoiceTimesheets?.items.length < ITEMS_PER_PAGE
        ) {
          setShowLoadMore(false);
        }
      } else {
        if (pagination.currentPage === 1) {
          setData([]);
        }
        setShowLoadMore(false);
      }
    } else if (query.error) {
      setShowLoadMore(false);
    }
  }, [query]);

  const getTimesheetStatusView = (status) => {
    if (status === 0) {
      return <Small css={fontStyleColor}>Not Invoiced</Small>;
    } else if (status === 1) {
      return (
        <Small
          css={{
            ...fontStyleColor,
            margin: 'auto',
            padding: '10px 20px',
            borderRadius: 20,
            backgroundColor: '#EBEBEB',
          }}
        >
          Draft
        </Small>
      );
    } else if (status === 2) {
      return (
        <Stack
          style={{
            borderRadius: 20,
            backgroundColor: '#D3D3D3',
            padding: '5px 10px',
          }}
        >
          <HalfClock fontSize="small" />
          <Small css={{ ...fontStyleColor, margin: 'auto' }}>In Progress</Small>
        </Stack>
      );
    }
  };

  const handleGenerateInvoice = async () => {
    try {
      const resp = await generateInvoice({
        variables: {
          accountId: billing!.account.id,
          customerId: billing!.customer.id,
          timesheetIds: selectedIds,
        },
      });
      if (resp?.data?.invoiceGenerate?.invoice) {
        resetQueryParams();
        query.refetch();
      }
    } catch (e) {
      dispatch(
        addMessage({
          type: 'ERROR',
          title: 'Invoice Failure',
          content: `Your invoice could not be created.`,
          reloadLink: true,
        })
      );
    }
  };

  const getTimesheetBillRates = (timesheet) => {
    if (
      timesheet.invoiceItems &&
      timesheet.invoiceItems.length > 0 &&
      timesheet.invoiceItems[0].billRate
    ) {
      return `$${centsToCurrency(timesheet.invoiceItems[0].billRate)}`;
    } else if (timesheet.costRate) {
      return `$${centsToCurrency(timesheet.costRate)}`;
    }
    return '0';
  };

  const renderOverTimeRow = (item: InvoiceItem, index: number) => {
    const { order, tip, skill, netTime, jobId, billRate, isHoliday } = item;
    const orderId = order?.split('#')?.[1] || '';
    return (
      <TableRow
        key={`overtime-row-${index}`}
        css={{ backgroundColor: 'white !important' }}
        id={`overtime-row-${index}`}
      >
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell>
          <SubdirectoryArrowRight
            htmlColor={IconColor}
            id="overtime-icon"
            style={{ float: 'right' }}
          />
        </TableCell>
        <TableCell>
          <Small css={fontStyleColor}>
            {isHoliday ? 'HOLIDAY ' : ''} OVERTIME: {`${skill?.name || ''}`}
          </Small>
        </TableCell>
        <TableCell>
          <Small css={fontStyleColor}>
            {jobId && netTime ? (
              <Link to={`/orders/${orderId}/jobs/${jobId}`}>{netTime}</Link>
            ) : (
              ''
            )}
          </Small>
        </TableCell>
        <TableCell>
          <Small css={fontStyleColor}>
            {billRate ? `$${centsToCurrency(billRate)}` : ''}
          </Small>
        </TableCell>
        <TableCell>
          <Small css={fontStyleColor}>
            {tip ? `$${centsToCurrency(Number(tip))}` : ''}
          </Small>
        </TableCell>
        <TableCell />
      </TableRow>
    );
  };

  return (
    <Page
      headerPadding={phoneOnly}
      noPadding={phoneOnly}
      size={phoneOnly ? 'full' : 'xl'}
      title="Invoice Generator"
    >
      <Card>
        <Card.Section>
          <Stack gap={8}>
            <InvoicesFilters
              billing={billing}
              filters={filters}
              onChangeFilter={setFilters}
            />
          </Stack>
        </Card.Section>
        <div style={{ marginTop: '12px' }}>
          <ActionTab
            checkboxIds={selectedIds}
            handleGenerateInvoice={handleGenerateInvoice}
            setCheckboxIds={setSelectedIds}
          />
          <Table>
            <TableHeader
              checkBoxHeader={
                <TableHeading style={{ width: '1%' }}>
                  <Checkbox
                    checked={selectedIds.length > 0}
                    checkedIcon={
                      allSelected ? (
                        <CheckBoxOutlined htmlColor="#332F2D" />
                      ) : (
                        <IndeterminateCheckBoxOutlined htmlColor="#332F2D" />
                      )
                    }
                    disabled={false}
                    id="select-all"
                    size="small"
                    style={{ padding: 0 }}
                    onChange={handleSelectAllClick}
                  />
                </TableHeading>
              }
              fields={HEADER_FIELDS}
              headerBgColor={jobColors.nightCharcoal}
            />
            <tbody>
              {data?.map((item, idx) => {
                const { worker } = item.jobWorker;
                const { address, order } = item.job;
                const addressLine = `${
                  address.addressLine1 ? address.addressLine1 : ''
                } ${address.addressLine2 ? address.addressLine2 : ''}`;
                const invItems = item.invoiceItems?.filter((invitem) => {
                  if (invitem.timesheetInvoice?.status === 'DRAFT') return true;
                  else return false;
                });
                let mainInvoice: InvoiceItem | undefined;
                let overtimeInvoice: InvoiceItem | undefined;
                if (invItems) {
                  if (invItems.length === 1) {
                    mainInvoice = invItems[0];
                  } else {
                    mainInvoice = invItems.find((x) => !x.isOvertime);
                    overtimeInvoice = invItems.find((x) => x.isOvertime);
                  }
                }
                const desc = mainInvoice
                  ? getInvoiceDescription(mainInvoice, true)
                  : item.job.skill.name;
                const isDisable = !!item.holdByInvoiceId;
                const statusId = isDisable ? 2 : mainInvoice ? 1 : 0;

                return (
                  <>
                    <TableRow
                      key={idx}
                      style={{
                        backgroundColor: isDisable ? '#F4F5F7' : 'white',
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          checked={selectedIds.includes(item.id)}
                          checkedIcon={<CheckBoxOutlined htmlColor="#332F2D" />}
                          disabled={isDisable}
                          id={'checkbox-' + item.id}
                          size="small"
                          style={{ padding: 0 }}
                          onChange={(e) =>
                            handleCheckClick(e.target.checked, item.id)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Small css={fontStyleColor}>
                          {formatISO(item.approvedCheckinAt!, 'MM-dd-yyyy')}
                        </Small>
                      </TableCell>
                      <TableCell>
                        <Link
                          target="_blank"
                          to={`/orders/${order.id}`}
                        >{`#${order.id}`}</Link>
                      </TableCell>
                      <TableCell>
                        <Small css={fontStyleColor}>{addressLine}</Small>
                        <Small css={fontStyleColor}>
                          {address.city},{address.state} {address.zip}
                        </Small>
                      </TableCell>
                      <TableCell>
                        <Link target="_blank" to={`/workers/${worker.id}`}>{`${
                          worker.user.firstName
                        } ${worker.user.middleName ?? ''} ${
                          worker.user.lastName
                        }`}</Link>
                      </TableCell>
                      <TableCell>
                        <Small css={fontStyleColor}>{desc}</Small>
                      </TableCell>
                      <TableCell>
                        <Small css={fontStyleColor}>
                          <Link
                            target="_blank"
                            to={`/orders/${order.id}/jobs/${item.job?.id}`}
                          >
                            {mainInvoice?.netTime ?? showTwoDecimalHours
                              ? getRoundOff(
                                  item.netApprovedTime / HOUR_IN_MINUTES
                                )
                              : minutesToHoursString(item.netApprovedTime)}
                          </Link>
                        </Small>
                      </TableCell>
                      <TableCell>
                        <Small css={fontStyleColor}>
                          {getTimesheetBillRates(item)}
                        </Small>
                      </TableCell>
                      <TableCell>
                        <Small css={fontStyleColor}>
                          {item.tipAmount ? `$${item.tipAmount}` : 0}
                        </Small>
                      </TableCell>
                      <TableCell>
                        <Small css={fontStyleColor}>
                          {mainInvoice
                            ? `#${mainInvoice.timesheetInvoice?.id}`
                            : ''}
                        </Small>
                      </TableCell>
                      <TableCell>{getTimesheetStatusView(statusId)}</TableCell>
                    </TableRow>
                    {overtimeInvoice &&
                      renderOverTimeRow(overtimeInvoice!, idx)}
                  </>
                );
              })}
              {query.loading && (
                <TableRow>
                  <TableCell align="center" colSpan={11}>
                    <LoadingState overlayColor="white" />
                  </TableCell>
                </TableRow>
              )}
            </tbody>
          </Table>
          {!query.loading && data.length === 0 && (
            <QueryEmptyState
              query={query}
              text="If there were any, they would be here."
              title="No timesheets available"
            />
          )}
          <div
            style={{ padding: '20px', display: showLoadMore ? 'grid' : 'none' }}
          >
            <Button
              a11yLabel={'Load More'}
              appearance="outline"
              id="btn-load-more"
              label="Load More ..."
              style={{ width: '200px', margin: 'auto' }}
              type="button"
              onClick={() => {
                setPagination({
                  currentPage: pagination.currentPage + 1,
                  itemsPerPage: pagination.itemsPerPage,
                });
              }}
            />
          </div>
        </div>
      </Card>
    </Page>
  );
};

export default Invoices;
