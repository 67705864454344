import { useEffect, useMemo, useState } from 'react';

import { useOrderActions, useOrderState } from '../../../context';
import { Step, useJobDraftState } from '../../context';
import BottomBar from '../BottomBar';
import Layout from '../Layout';
import { updSchedulesWithHolidayRates } from '../ScheduleStep/util';

import {
  PayRateInputWrapper,
  PayRateWrapper,
  RatingChartWrapper,
  RatingWrapper,
  RowBorderWrapper,
  TextAverageRate,
  TextBadRate,
  TextGoodRate,
  TitleFlex,
  TitleWrapper,
  TotalHours,
} from './styles';

import { RatingAverage, RatingBad, RatingGood } from '@/assets/icons';
import Button from '@/components/Button';
import MaskedInput from '@/components/MaskedInput';
import SingleColumnLayout from '@/components/SingleColumnLayout';
import Stack from '@/components/Stack';
import { Body, Heading, Subheading } from '@/components/Typography';
import { GAEvent } from '@/constants/gaevents';
import Text from '@/elements/Text';
import { useGetRateRangeLazyQuery } from '@/graphql';
import useMediaQuery from '@/hooks/useMediaQuery';
import { JobTypeEnum, Scalars } from '@/types/graphql';
import useAnalytics from '@/util/analytics';
import { calculateTotalHours, formatDuration } from '@/util/datetime';
import { CalculateCost } from '@/util/payments';

type Props = {
  setStep: (step: Step) => Scalars['Void'];
};

const PayRateStep = ({ setStep }: Props) => {
  const jobState = useJobDraftState();
  const { billing, holidays, orderType } = useOrderState();
  const { schedules, skill, payRate, address } = jobState;
  const { addJob } = useOrderActions();
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const [pay, setPay] = useState(payRate ? (payRate / 100).toString() : '0');
  const [minPay, setMinPay] = useState(0);
  const [maxPay, setMaxPay] = useState(payRate);

  const [startTime, setStartTime] = useState<number>(0);

  const { logEvent } = useAnalytics();

  useEffect(() => {
    setStartTime(new Date().getTime());
  }, []);

  const [fetch, { data }] = useGetRateRangeLazyQuery();
  useEffect(() => {
    fetch({
      variables: { addressId: address?.id, skillId: skill?.id },
    });
  }, [skill, address]);

  useEffect(() => {
    if (data && data.rateRange && data.rateRange.length) {
      setMinPay(data.rateRange[0].minimum);
      setMaxPay(data.rateRange[0].maximum);
    }
  }, [data]);

  const payValue = Number((Number(pay.replace('$', '')) * 100).toFixed(0));
  const continueButtonIsDisabled = payValue < minPay;
  const totalHours = formatDuration(calculateTotalHours({ schedules }));

  const RenderRatingChart = () => {
    if (payValue < minPay) {
      return (
        <RatingChartWrapper>
          <RatingBad sx={phoneOnly ? { width: '20px', height: '20px' } : {}} />
          <TextBadRate>Raise the rate to meet minimum wage.</TextBadRate>
        </RatingChartWrapper>
      );
    } else if (payValue < maxPay!) {
      return (
        <RatingChartWrapper>
          <RatingAverage
            sx={phoneOnly ? { width: '20px', height: '20px' } : {}}
          />
          <TextAverageRate>
            Consider an increase to attract candidates.
          </TextAverageRate>
        </RatingChartWrapper>
      );
    } else {
      return (
        <RatingChartWrapper>
          <RatingGood sx={phoneOnly ? { width: '20px', height: '20px' } : {}} />
          <TextGoodRate>Great - this is a competitive rate!</TextGoodRate>
        </RatingChartWrapper>
      );
    }
  };
  const color = useMemo(() => {
    if (payValue < minPay!) {
      return '#DC1515';
    } else if (payValue < maxPay!) {
      return '#EC7F00';
    } else {
      return '#45A735';
    }
  }, [payValue, minPay, maxPay]);

  const handleSubmit = () => {
    const payRate = payValue;
    const costRate = CalculateCost(
      pay.replace('$', '') || '0',
      billing?.account.markupPercent ?? 0
    );
    let newSchedules = schedules.map((schedule) => ({
      ...schedule,
      payRate,
      costRate,
    }));
    newSchedules = updSchedulesWithHolidayRates({
      schedules: newSchedules,
      calcPayRate: payRate,
      calcBillRate: costRate,
      holidays: holidays || [],
      minPay,
    });
    addJob({
      ...jobState,
      schedules: newSchedules,
      payRate,
      markupPercent: billing?.account.markupPercent ?? 0,
      minPay,
      maxPay,
    });
    logEvent(
      orderType === JobTypeEnum.LTA
        ? GAEvent.StepFiveMarkupLta
        : GAEvent.StepFiveMarkupGig,
      billing?.account?.id,
      {
        time_spent: (new Date().getTime() - startTime) / 1000,
      }
    );
    setStep(Step.SUBMISSION);
  };

  return (
    <Layout>
      <SingleColumnLayout
        noPadding={phoneOnly}
        size={phoneOnly ? 'full' : 'md'}
      >
        <Stack vertical gap={24} style={{ alignItems: 'center' }}>
          <TitleWrapper phoneOnly={phoneOnly}>
            <TitleFlex style={{ flex: 1 }}>
              <Heading>{`Set Pay Rate for ${skill?.name}`}</Heading>
            </TitleFlex>
            <TotalHours>
              <Text css={{ marginRight: '10px' }} size="lg">
                Total Hours:{' '}
              </Text>
              <Subheading>{totalHours}</Subheading>
            </TotalHours>
          </TitleWrapper>
          <PayRateWrapper phoneOnly={phoneOnly}>
            <Stack vertical align={'end'}>
              <RowBorderWrapper>
                <Body size={'lg'} weight={'medium'}>
                  Pay Rate
                </Body>
                <RatingWrapper>
                  <PayRateInputWrapper>
                    <MaskedInput
                      blocks={{
                        num: {
                          mask: Number,
                          thousandsSeparator: ',',
                          radix: '.',
                          max: 999,
                          scale: 2,
                          padFractionalZeros: true,
                          signed: false,
                          normalizeZeros: true,
                        },
                      }}
                      mask={'$num'}
                      style={{
                        width: '100px',
                        textAlign: 'end',
                        fontSize: '20px',
                        fontWeight: '700',
                        color: `${color}`,
                      }}
                      value={pay}
                      onAccept={(value) => {
                        value && setPay(value);
                      }}
                    />
                  </PayRateInputWrapper>
                </RatingWrapper>
              </RowBorderWrapper>
              <RenderRatingChart />
            </Stack>
          </PayRateWrapper>
          <BottomBar>
            <Button
              a11yLabel="Go back to previous step"
              appearance="outline"
              id="back-btn"
              label="Back"
              type="button"
              onClick={() => setStep(Step.DETAILS)}
            />
            <Button
              a11yLabel="Submit form"
              disabled={continueButtonIsDisabled}
              id="continue-btn"
              label="Continue"
              type="button"
              onClick={handleSubmit}
            />
          </BottomBar>
        </Stack>
      </SingleColumnLayout>
    </Layout>
  );
};

export default PayRateStep;
