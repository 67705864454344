import { useEffect, useState } from 'react';

import { useOrderState } from '../../../context';
import {
  PublishInEnum,
  Step,
  Uniform,
  selectionOptions,
  useJobDraftActions,
  useJobDraftState,
} from '../../context';
import BottomBar from '../BottomBar';
import Layout from '../Layout';

import BreakCrumbHeaders from './Common/BreadCrumbHeaders';
import MainInviteWorkersPage from './MainInviteWorkersPage';
import { JobTypeSectionContainer, JobTypeSectionIconWrapper } from './styles';

import { AnnouncementIcon, InvitationIcon } from '@/assets/icons';
import Button from '@/components/Button';
import Option from '@/components/Option';
import SingleColumnLayout from '@/components/SingleColumnLayout';
import Stack from '@/components/Stack';
import { Heading, Small, Subheading } from '@/components/Typography';
import { GAEvent } from '@/constants/gaevents';
import Text from '@/elements/Text';
import useMediaQuery from '@/hooks/useMediaQuery';
import { Maybe } from '@/types';
import { JobTypeEnum, Scalars } from '@/types/graphql';
import useAnalytics from '@/util/analytics';

type StaffingStepProps = {
  setStep: (step: Step) => Scalars['Void'];
};

export type FormValues = {
  instructions: Scalars['String'];
  uniform: Maybe<Uniform>;
  uniformInstructions: Scalars['String'];
};

const StaffingStep = ({ setStep }: StaffingStepProps) => {
  const { postSetting, publishJob } = useJobDraftState();
  const { orderType, billing } = useOrderState();
  const { updatePublishing, updateCompletedStep } = useJobDraftActions();
  const [hireWorkersScreen, setHireWorkersScreen] = useState<boolean>(
    orderType === JobTypeEnum.LTA
  );
  const isPublic = postSetting === selectionOptions.EVERYONE;
  const isPrivate = postSetting === selectionOptions.MY_SELECTIONS;

  const onContinueInvitationButtonClick = () => {
    setHireWorkersScreen(true);
  };

  const [startTime, setStartTime] = useState<number>(0);

  useEffect(() => {
    setStartTime(new Date().getTime());
  }, []);

  const { logEvent } = useAnalytics();

  const handleContinue = () => {
    updateCompletedStep({
      completedStep: Step.STAFFING,
    });
    logEvent(
      orderType === JobTypeEnum.LTA
        ? GAEvent.StepThreeLta
        : GAEvent.StepThreeGig,
      billing?.account?.id,
      {
        time_spent: (new Date().getTime() - startTime) / 1000,
      }
    );

    setStep(Step.DETAILS);
  };

  const phoneOnly = useMediaQuery('(max-width: 559px)');

  return (
    <Layout>
      {!hireWorkersScreen && (
        <SingleColumnLayout
          noPadding={phoneOnly}
          size={phoneOnly ? 'full' : 'md'}
        >
          {isPrivate && <BreakCrumbHeaders step={1} />}
          <Stack vertical gap={24}>
            <Heading>How would you like to staff your job?</Heading>

            <Stack vertical gap={20}>
              <JobTypeSectionContainer
                css={{
                  border: isPublic ? '1px solid #45A735' : '1px solid #D3D3D3',
                  cursor: 'pointer',
                }}
                id="open_post"
                onClick={() => {
                  updatePublishing({
                    postSetting: selectionOptions.EVERYONE,
                    publishIn: PublishInEnum.IMMEDIATELY,
                    publishJob: true,
                  });
                }}
              >
                <Stack
                  align="start"
                  css={{ flexDirection: 'row', minHeight: '80px' }}
                  gap={40}
                >
                  <Stack align="start" css={{ flexDirection: 'row' }} gap={24}>
                    <JobTypeSectionIconWrapper>
                      <AnnouncementIcon
                        id="announcement-icon"
                        sx={{
                          width: '68px',
                          height: '68px',
                        }}
                      />
                    </JobTypeSectionIconWrapper>
                    <Stack vertical gap={6}>
                      <Stack justify={'apart'}>
                        <Subheading>Open Job Post</Subheading>
                        <Option
                          appearance="bullseye"
                          checked={isPublic}
                          id={'open_post'}
                          label={''}
                          style={{ top: '-10px', left: '12px' }}
                          type="radio"
                        />
                      </Stack>
                      <Small
                        css={{
                          fontSize: 16,
                          color: '#7A7A7A',
                          textAlign: 'left',
                        }}
                      >
                        Make your job publicly available to attract a wide pool
                        of qualified candidates.
                      </Small>
                    </Stack>
                  </Stack>
                </Stack>
              </JobTypeSectionContainer>

              <JobTypeSectionContainer
                css={{
                  minHeight: isPrivate ? 180 : 130,
                  border: isPrivate ? '1px solid #45A735' : '1px solid #D3D3D3',
                  cursor: 'pointer',
                }}
                id="invite_post"
                onClick={() => {
                  !isPrivate &&
                    updatePublishing({
                      postSetting: selectionOptions.MY_SELECTIONS,
                      publishIn: PublishInEnum.IMMEDIATELY,
                      publishJob: true,
                    });
                }}
              >
                <Stack
                  align="start"
                  css={{ flexDirection: 'row', minHeight: '130px' }}
                  gap={40}
                >
                  <Stack align="start" css={{ flexDirection: 'row' }} gap={24}>
                    <JobTypeSectionIconWrapper>
                      <InvitationIcon
                        id="invitation-icon"
                        sx={{
                          width: '68px',
                          height: '68px',
                        }}
                      />
                    </JobTypeSectionIconWrapper>
                    <Stack vertical gap={6}>
                      <Stack justify={'apart'}>
                        <Subheading>Send Invitations</Subheading>
                        <Option
                          appearance="bullseye"
                          checked={isPrivate}
                          id={'invite_post'}
                          label={''}
                          style={{ top: '-10px', left: '12px' }}
                          type="radio"
                        />
                      </Stack>
                      <Small
                        css={{
                          fontSize: 16,
                          color: '#7A7A7A',
                          textAlign: 'left',
                        }}
                      >
                        Send job invites to specific workers and ensure the best
                        fit for your needs.
                      </Small>

                      {isPrivate && (
                        <Stack
                          css={{ marginTop: '8px' }}
                          gap={10}
                          vertical={phoneOnly}
                        >
                          <Option
                            appearance="switch"
                            checked={publishJob}
                            id={'switch-invitation'}
                            label={''}
                            type="checkbox"
                            onChange={(e) => {
                              updatePublishing({
                                postSetting: selectionOptions.MY_SELECTIONS,
                                publishIn: PublishInEnum.IMMEDIATELY,
                                publishJob: e.target.checked,
                              });
                            }}
                          />
                          <Text css={{ textAlign: 'left' }}>
                            Make the job available to qualified workers if your
                            selected workers are unavailable.
                          </Text>
                        </Stack>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </JobTypeSectionContainer>

              {isPrivate && (
                <Button
                  a11yLabel="invite-workers"
                  label="Continue to Invite Workers"
                  type="button"
                  onClick={onContinueInvitationButtonClick}
                />
              )}
            </Stack>
          </Stack>
        </SingleColumnLayout>
      )}

      {hireWorkersScreen && (
        <MainInviteWorkersPage
          onClickJobAccess={() => setHireWorkersScreen(false)}
        />
      )}

      <BottomBar>
        <Button
          a11yLabel="Go back to previous step"
          appearance="outline"
          id="back-btn"
          label="Back"
          type="button"
          onClick={() => setStep(Step.SCHEDULE)}
        />
        <Button
          a11yLabel="Submit form"
          disabled={(isPrivate && !hireWorkersScreen) || !postSetting}
          id="continue-btn"
          label="Continue"
          onClick={handleContinue}
        />
      </BottomBar>
    </Layout>
  );
};

export default StaffingStep;
