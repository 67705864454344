import useModal from '@area2k/use-modal';
import {
  ChatContainer,
  MessageList,
  Message,
  ConversationHeader,
  MessageSeparator,
  Loader,
} from '@chatscope/chat-ui-kit-react';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { Divider, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { formatDate, getAnnouncementTitle } from '../utils';

import UserInfoModal from './AnnouncementInfoModal';

import Avatar from '@/components/Avatar';
import Modal from '@/components/Modal';
import TextStack from '@/components/TextStack';
import { Body, Heading, Small } from '@/components/Typography';
import { FEATURE_TOGGLE } from '@/constants/featuretoggle';
import { V3Endpoints } from '@/constants/urls';
import Text from '@/elements/Text';
import axiosClient from '@/util/axios/axiosClient';
import { AnnouncementGroupInterface, UserInterface } from '@/util/axios/types';
import { getDateandLabel, getTimeString } from '@/util/date';

type AnnouncementDetailContainerProps = {
  announcementGroup: AnnouncementGroupInterface;
  messages: any[];
  backClick: () => void;
  phoneOnly: boolean;
};
type MessageInfoProps = {
  user: UserInterface;
  msg: any;
  time: string;
};
const AnnouncementDetails = ({
  announcementGroup,
  messages = [],
  backClick,
  phoneOnly,
}: AnnouncementDetailContainerProps) => {
  const announcementExpiration = useFeatureValue(
    FEATURE_TOGGLE.AnnouncementExpiration,
    false
  );
  const [showModal, hideModal] = useModal(() => {
    return (
      <ProfileModal
        announcementExpiration={announcementExpiration}
        announcementGroup={announcementGroup}
        hideModal={hideModal}
      />
    );
  }, [announcementGroup, announcementExpiration]);

  const [showUserModal, hideUserModal] = useModal(() => {
    return (
      <UserInfoModal hideModal={hideUserModal}>
        <UserInfo
          announcementExpiration={announcementExpiration}
          announcementGroup={announcementGroup}
          phoneOnly={phoneOnly}
        />
      </UserInfoModal>
    );
  }, [announcementGroup, announcementExpiration]);

  let dateLabel =
    messages.length > 0
      ? getDateandLabel(new Date(Number(messages[0].timetoken) / 10000))
      : '';

  return (
    <div
      style={{
        maxHeight: phoneOnly ? '87vh' : '91vh',
        overflow: 'auto',
        width: '100%',
      }}
    >
      <ChatContainer>
        <ConversationHeader
          style={{ backgroundColor: '#fff', cursor: 'pointer' }}
        >
          {phoneOnly && <ConversationHeader.Back onClick={backClick} />}
          <ConversationHeader.Content
            onClick={() => {
              if (phoneOnly) {
                showUserModal();
              } else {
                showModal();
              }
            }}
          >
            <Stack direction={'row'} spacing={1.5}>
              <Avatar
                firstName={
                  'Workers: ' + formatDate(announcementGroup?.createdAt)
                }
                size={phoneOnly ? 'sm' : 'm'}
                src={require('@/assets/img/announcment.png')}
              />
              <Heading>
                {announcementExpiration
                  ? getAnnouncementTitle(announcementGroup)
                  : 'Workers: ' + formatDate(announcementGroup?.createdAt)}
              </Heading>
            </Stack>
          </ConversationHeader.Content>
        </ConversationHeader>

        <MessageList className="custom-bg-chat">
          <Stack>
            <TextStack>
              <br />
              <Body>Filters Applied:</Body>
              <Small>
                <strong>Skills: </strong>
                {announcementGroup?.filters?.skills
                  ?.map((s) => s.name)
                  .join(', ')}
              </Small>
              <Small>
                <strong> Location: </strong>
                {announcementGroup?.filters?.withInMiles} miles within{' '}
                {announcementGroup?.filters?.zipCode}
              </Small>
              <Small>
                <strong>Tax Type: </strong>
                {announcementGroup?.filters?.taxTypes.join(', ')}
              </Small>
            </TextStack>
          </Stack>
          {messages &&
            messages.map((msg) => {
              const formattedDate = new Date(Number(msg.timetoken) / 10000);
              const label = getDateandLabel(formattedDate);
              const time = getTimeString(formattedDate);
              const user = msg.message.sender;

              dateLabel = label;
              return (
                <React.Fragment key={msg.timetoken}>
                  <MessageSeparator className="custom-chat-line">
                    {dateLabel}
                  </MessageSeparator>
                  <MessageInfo msg={msg} time={time} user={user} />
                </React.Fragment>
              );
            })}
        </MessageList>
      </ChatContainer>
    </div>
  );
};

type UserInfoProps = {
  announcementGroup: AnnouncementGroupInterface;
  phoneOnly: boolean;
  announcementExpiration: boolean;
};

const UserInfo = ({
  announcementGroup,
  phoneOnly,
  announcementExpiration,
}: UserInfoProps) => {
  const [users, setUsers] = useState<Array<UserInterface>>([]);
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (announcementGroup.users && announcementGroup.users.length) {
      setUsers(announcementGroup.users);
    } else {
      setShowLoader(true);
      axiosClient
        .get(V3Endpoints.GET_CHATGROUP_LIST_USERS + announcementGroup?.id)
        .then((response) => {
          const { data } = response;
          setShowLoader(false);
          if (data && data.length) {
            const users: Array<UserInterface> = data.map((u) => {
              const user: UserInterface = {
                id: u.user.id,
                firstName: u.user.firstName,
                lastName: u.user.lastName,
                avatar: u.user.avatar_url,
                type: u.user.type,
                email: u.user.email,
                active: u.user.active,
              };
              return user;
            });
            announcementGroup.users = users;
            setUsers(users);
          }
        })
        .catch((e) => {
          setShowLoader(false);
          console.error(e);
        });
    }
  }, [announcementGroup]);

  return (
    <React.Fragment>
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: phoneOnly ? '0px' : '30px',
        }}
      >
        <Avatar size="lg" src={require('@/assets/img/announcment.png')} />
        <Heading
          css={{ marginLeft: '20px', marginRight: '20px' }}
          textAlignProp={'center'}
        >
          {announcementExpiration
            ? getAnnouncementTitle(announcementGroup)
            : 'Workers: ' + formatDate(announcementGroup?.createdAt)}
        </Heading>
        <Small>{announcementGroup.email ?? 'Email not provided'}</Small>
      </div>
      <div
        style={{
          alignItems: 'left',
          paddingLeft: '30px',
          height: '60vh',
          overflowY: 'scroll',
        }}
      >
        {showLoader ? (
          <Loader
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginLeft: '-30px',
            }}
          >
            Loading
          </Loader>
        ) : (
          <>
            <Text as="h4" size={'md'} weight={'bold'}>
              {users?.length} Receipents
            </Text>
            {users?.map((user) => {
              return (
                <div key={user.id}>
                  <br />
                  <Stack
                    style={{
                      paddingLeft: '10px',
                    }}
                  >
                    <Body>
                      {user.firstName} {user.lastName}
                    </Body>
                    <Small>{user.email}</Small>
                  </Stack>
                  <br />
                  <Divider />
                </div>
              );
            })}
          </>
        )}
      </div>
    </React.Fragment>
  );
};
const ProfileModal = ({
  announcementGroup,
  hideModal,
  users,
  announcementExpiration,
}: any) => {
  return (
    <Modal disableClickout size={'xxs'} onRequestClose={hideModal}>
      <UserInfo
        announcementExpiration={announcementExpiration}
        announcementGroup={announcementGroup}
        phoneOnly={false}
        users={users}
      />
    </Modal>
  );
};

const MessageInfo = ({ user, msg, time }: MessageInfoProps) => {
  return (
    <Message
      model={{
        message: msg.message.content.message,
        sentTime: msg.timetoken,
        sender: msg.message.sender.firstName,
        direction: 1,
        type: 'custom',
      }}
    >
      <Message.CustomContent>
        <TextStack>
          <strong>Gravy Staff</strong>
          <Text as="h4" color={'inherit'} size={'md'} weight={'bold'}>
            {`${user?.firstName || ''} ${user?.lastName || ''}`}
          </Text>
        </TextStack>

        <br />
        <Message.TextContent text={msg.message.content.message} />
        <div style={{ float: 'right', paddingTop: '3px' }}>{time}</div>
      </Message.CustomContent>
    </Message>
  );
};

export default AnnouncementDetails;
