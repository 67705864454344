/* eslint no-underscore-dangle: 0 */
import { FieldContext, SubmitHelpers } from '@area2k/use-form';
import useModal from '@area2k/use-modal';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Billing as BillingType,
  useOrderActions,
  useOrderState,
} from '../context';

import AccountSelectField from './AccountSelectField';
import CustomerAutocompleteField from './CustomerAutocompleteField';
import AccountWarning from './modals/AccountWarning';
import CustomerProfileStatusModal from './modals/CustomerProfileStatusModal';
import CustomerProfileStepsModal from './modals/CustomerProfileStepsModal';

import Button from '@/components/Button';
import Card from '@/components/Card';
import Stack from '@/components/Stack';
import { Small } from '@/components/Typography';
import Link from '@/elements/Link';
import Form from '@/form';
import {
  useGetAccountPaymentMethodsLazyQuery,
  useGetCustomerLazyQuery,
} from '@/graphql';
import useAuth from '@/hooks/useAuth';
import useMediaQuery from '@/hooks/useMediaQuery';
import ROUTES from '@/routes/routes';
import { Maybe } from '@/types';
import { JobTypeEnum } from '@/types/graphql';
import { CustomerStatusEnum } from '@/util/accountstatus';

export enum CustomerProfileSteps {
  CUSTOMER_ADMIN_CREATION = 1,
  BILLING_ACCOUNT_CREATION = 2,
  ADDRESS_CREATION = 3,
}

export type WarningType = 'RATE' | 'ADDRESS';

type FormValues = {
  customer: Maybe<BillingType['customer']>;
  account: Maybe<BillingType['account']>;
};

const Billing = () => {
  const phoneOnly = useMediaQuery('(max-width: 559px)');
  const { currentAdmin, currentAdminIsCustomerAdmin, isCurrentCustomerAdmin } =
    useAuth();
  const navigate = useNavigate();
  const [fetchGetCustomer, { data: customerData }] = useGetCustomerLazyQuery();

  const [getAccountPaymentMethods, { data: accountPaymentMethodsData }] =
    useGetAccountPaymentMethodsLazyQuery();

  const { setBilling, setOrderType } = useOrderActions();
  const { orderType } = useOrderState();

  const [profileStep, setProfileStep] =
    useState<Maybe<CustomerProfileSteps>>(null);

  const [profileStatus, setProfileStatus] =
    useState<Maybe<CustomerStatusEnum>>(null);

  const [formValues, setFormValues] = useState<FormValues>({
    customer: null,
    account: null,
  });

  const [showExitJobEditorModal, hideExitJobEditorModal] = useModal(
    () => (
      <CustomerProfileStepsModal
        currentStep={profileStep}
        customer={formValues.customer}
        hideModal={hideExitJobEditorModal}
        totalSteps={Object.keys(CustomerProfileSteps).length / 2}
        onConfirm={() => setProfileStep(null)}
      />
    ),
    [profileStep]
  );

  const [showClientStatusModal, hideClientStatusModal] = useModal(
    () => (
      <CustomerProfileStatusModal
        hideModal={() => {
          hideClientStatusModal();
          setProfileStatus(null);
        }}
        status={profileStatus}
      />
    ),
    [profileStatus]
  );

  const [showAccountWarning, hideAccountWarning] = useModal(
    ({ warningType }: { warningType: WarningType }) => (
      <AccountWarning
        account={formValues.account}
        customer={formValues.customer}
        hideModal={() => {
          setProfileStep(null);
          setFormValues((prevValues) => ({ ...prevValues, account: null }));
          hideAccountWarning();
        }}
        warningType={warningType}
        onConfirm={() => setProfileStep(null)}
      />
    ),
    [formValues]
  );

  const handleFormValuesChange = <T extends unknown>(
    fieldContext: FieldContext<T>,
    fieldId: keyof FormValues
  ) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldId]: fieldContext.value,
    }));
  };

  const handleSubmit = async (
    values: FormValues,
    { setFormError }: SubmitHelpers
  ) => {
    if (formValues.account && formValues.customer) {
      setBilling({
        account: formValues.account,
        customer: formValues.customer,
      });
      setProfileStep(null);
    } else {
      setFormError('incomplete', {
        title: 'Please complete all fields',
        message: 'All fields are required to continue.',
      });
    }
  };

  useEffect(() => {
    const parsed = qs.parse(location.search);
    if (parsed && parsed.type) {
      if (
        isCurrentCustomerAdmin(currentAdmin) &&
        !currentAdmin.customer?.ltaAllowed &&
        parsed.type === JobTypeEnum.LTA
      ) {
        navigate('/');
        return;
      }
      setOrderType({
        orderType:
          parsed.type === JobTypeEnum.LTA ? JobTypeEnum.LTA : JobTypeEnum.GIG,
      });
    } else {
      // redirect to dashboard
      navigate('/');
    }
  }, [location.search]);

  useEffect(() => {
    if (profileStep) {
      showExitJobEditorModal();
    }
  }, [profileStep]);

  useEffect(() => {
    if (profileStatus) {
      showClientStatusModal();
    }
  }, [profileStatus]);

  useEffect(() => {
    if (formValues.customer) {
      const { customer, account } = formValues;

      if (customer.admins.items.length === 0) {
        setProfileStep(CustomerProfileSteps.CUSTOMER_ADMIN_CREATION);
        return;
      }

      if (customer.accounts.length === 0) {
        setProfileStep(CustomerProfileSteps.BILLING_ACCOUNT_CREATION);
        return;
      }

      if (customer.addresses.length === 0) {
        setProfileStep(CustomerProfileSteps.ADDRESS_CREATION);
        return;
      }

      if (customer.status !== CustomerStatusEnum.APPROVED) {
        setProfileStatus(CustomerStatusEnum[customer.status]);
        return;
      }

      if (account) {
        const accountHasOneActiveAddress = customer.addresses.some(
          (address) => address.active
        );

        if (!accountHasOneActiveAddress) {
          showAccountWarning({
            warningType: 'ADDRESS',
          });

          return;
        }

        getAccountPaymentMethods({
          variables: { accountId: account.id },
        });
      }

      setProfileStep(null);
    }
  }, [formValues]);

  useEffect(() => {
    if (customerData) {
      const { customer } = customerData;

      // @ts-ignore
      setFormValues((prevValues) => ({
        ...prevValues,
        customer: {
          __typename: customer.__typename,
          id: customer.id,
          accounts: customer.accounts,
          addresses: customer.addresses,
          admins: customer.admins,
          jobTaxType: customer.jobTaxType,
          name: customer.name,
          status: customer.status,
        },
      }));
    }
  }, [currentAdmin, customerData]);

  useEffect(() => {
    if (isCurrentCustomerAdmin(currentAdmin) && currentAdminIsCustomerAdmin) {
      fetchGetCustomer({
        variables: {
          customerId: currentAdmin.customer.id,
          selectNearbyRate: true,
        },
      });
    }
  }, [currentAdmin]);

  return (
    <Card sectioned noRadius={phoneOnly} title="Client Selection">
      <Form initialValues={formValues} onSubmit={handleSubmit}>
        {!currentAdminIsCustomerAdmin && (
          <CustomerAutocompleteField
            callback={(fieldContext) => {
              handleFormValuesChange(fieldContext, 'customer');
            }}
            fieldId="customer"
            filterLTA={orderType === JobTypeEnum.LTA}
          />
        )}

        <AccountSelectField
          accountCallback={(fieldContext) => {
            handleFormValuesChange(fieldContext, 'account');
          }}
          customer={formValues.customer}
          fieldId="account"
          label="Billing Account*"
          profileStep={profileStep}
        />

        {formValues.customer &&
          formValues.account &&
          accountPaymentMethodsData &&
          !accountPaymentMethodsData.accountPaymentMethods
            .currentPaymentMethod && (
            <Stack vertical verticalGap={0}>
              <Small color="danger" weight="bold">
                There is no payment method associated with this billing account.
              </Small>
              <Stack>
                <Link
                  to={
                    currentAdminIsCustomerAdmin
                      ? `../../${ROUTES.myCompany}/accounts/${formValues.account.id}`
                      : `../../${ROUTES.clients}/${formValues.customer.id}/accounts/${formValues.account.id}`
                  }
                >
                  <Small color="danger" weight="bold">
                    Click here
                  </Small>
                </Link>
                <Small color="danger" weight="bold">
                  to add payment method.
                </Small>
              </Stack>
            </Stack>
          )}

        <Stack justify="end">
          <Button
            a11yLabel="Submit"
            disabled={
              !formValues.customer ||
              !formValues.account ||
              !accountPaymentMethodsData ||
              !accountPaymentMethodsData.accountPaymentMethods
                .currentPaymentMethod ||
              formValues.customer.status !== CustomerStatusEnum.APPROVED
            }
            id="start-order-btn"
            label="Start order"
            type="submit"
          />
        </Stack>
      </Form>
    </Card>
  );
};

export default Billing;
