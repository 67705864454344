export type MessageReceiversList = {
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  avatar_url: string;
  type: string;
  id: number;
}[];

export enum ChatGroupGWUserType {
  TENANT_ADMIN = 'tenant_admin',
  CLIENT_ADMIN = 'client_admin',
  WORKER = 'worker',
}

export interface Skills {
  id: number;
  name: String;
}
export interface Filters {
  skills?: Array<Skills>;
  withInMiles: String;
  zipCode: String;
  taxType: Array<String>;
}

export interface UserInterface {
  firstName: string;
  id: number;
  lastName: string;
  avatar: string;
  email: string;
  type: ChatGroupGWUserType;
  active?: boolean;
}
export interface ChatGroupInterface {
  id: string;
  name: string;
  lastMessage: string;
  lastMessageTime: string;
  avatar?: string;
  email: string;
  createdAt: string;
  active?: boolean;
  users: Array<UserInterface>;
  allMessagesLoaded: boolean;
}
export interface AnnouncementGroupInterface {
  id: string;
  name: string;
  lastMessage: string;
  lastMessageTime: string;
  avatar?: string;
  email: string;
  createdAt: string;
  active?: boolean;
  users: Array<UserInterface>;
  filters: Filters;
}

export enum MessageType {
  CHAT_MESSAGE = 'chat_message',
  SYSTEM_MESSAGE = 'system_message',
}

export enum ContentType {
  TEXT = 'text',
  ANNOUNCEMENT = 'announcement',
}

export type CustomerCountResponse = {
  count: number;
  status: string;
};

export enum MessageActionType {
  ADDED_TO_NEW_CHAT_GROUP = 'added_to_new_chat_group',
  ADDED_TO_EXISTING_CHAT_GROUP = 'added_to_existing_chat_group',
  REMOVED_FROM_EXISTING_CHAT_GROUP = 'removed_from_existing_chat_group',
  WORKER_ENABLED = 'worker_enabled',
  WORKER_DISABLED = 'worker_disabled',
  CUSTOMER_ENABLED = 'customer_enabled',
  CUSTOMER_DISABLED = 'customer_disabled',
  ADDED_TO_NEW_ANNOUNCEMENT = 'added_to_new_announcement',
  INVOICE_GENERATION = 'invoice_generation',
}

export enum NetworkStatusCategory {
  PUBNUB_CONNECTED = 'PNConnectedCategory',
  PUBNUB_NETWORK_UP = 'PNNetworkUpCategory',
  PUBNUB_RECONNECTED = 'PNReconnectedCategory',
  PUBNUB_NETWORK_DOWN = 'PNNetworkDownCategory',
  PUBNUB_NETWORK_ISSUES = 'PNNetworkIssuesCategory',
  PUBNUB_NETWORK_TIMEOUT = 'PNTimeoutCategory',
}

export type WorkersCount = {
  all: number;
  favourite: number;
  highlyRated: number;
  pastWorker: number;
  trained: number;
};

export enum WorkerTabs {
  All = 'all',
  Favorites = 'favourites',
  GravyTrained = 'gravy_trained',
  HighlyRated = 'highly_rated',
  PastWorkers = 'past_workers',
}
