import { useReactiveVar } from '@apollo/client';
import { SubmitHelpers } from '@area2k/use-form';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { StyledPage } from '../SignUp/styles';

import Button from '@/components/Button';
import Card from '@/components/Card';
import GravyWorkLogo from '@/components/GravyWorkLogo';
import Stack from '@/components/Stack';
import { Body } from '@/components/Typography';
import Version from '@/components/Version';
import { GAEvent } from '@/constants/gaevents';
import Link from '@/elements/Link';
import Form from '@/form';
import FormFooter from '@/form/FormFooter';
import TextField from '@/form/TextField';
import { useAdminLoginMutation } from '@/graphql';
import useLocationSearch from '@/hooks/useLocationSearch';
import ROUTES from '@/routes/routes';
import useAnalytics from '@/util/analytics';
import { currentAdminVar, currentTenantVar } from '@/util/apollo/cache';
import { handleMutationFormError } from '@/util/error';
import ls, {
  ACCESS_TOKEN_KEY,
  ACCOUNT_DISABLED,
  DEVICE_TOKEN_KEY,
  PENDING_CUSTOMERS,
  RESET_PASSWORD,
} from '@/util/localstorage';
import { isIOS, isWeb } from '@/util/platform';

type FormValues = { email: string; password: string };

const initialValues: FormValues = {
  email: '',
  password: '',
};

const Login = () => {
  const navigate = useNavigate();
  const { to } = useLocationSearch();

  const { logEvent, setUser } = useAnalytics();

  if (!isWeb()) {
    navigate(ROUTES.appLogin, { replace: true });
  }
  const deviceToken = ls.get(DEVICE_TOKEN_KEY);
  const destination = useMemo(() => (typeof to === 'string' ? to : '/'), [to]);

  const currentTenant = useReactiveVar(currentTenantVar);

  const [loginAdmin, { loading: isLoginAdminLoading }] =
    useAdminLoginMutation();

  const handleSubmit = useCallback(
    async (values: FormValues, { setFormError }: SubmitHelpers) => {
      try {
        const result = await loginAdmin({
          variables: { ...values, tenantId: currentTenant!.id },
        });

        const {
          accessToken,
          refreshToken,
          tenantAdmin,
          customerAdmin,
          resetPassword,
          disabled,
        } = result.data!.adminLogin;

        ls.multiSet({
          [ACCESS_TOKEN_KEY]: accessToken,
          [DEVICE_TOKEN_KEY]: refreshToken,
          [RESET_PASSWORD]: resetPassword,
          [ACCOUNT_DISABLED]: disabled,
          [PENDING_CUSTOMERS]: tenantAdmin?.pendingCustomers,
        });

        currentAdminVar(tenantAdmin ?? customerAdmin);

        if (resetPassword) {
          logEvent(GAEvent.FirstLogin, customerAdmin?.user?.id);
        } else {
          setUser(
            customerAdmin
              ? `customer_admin_${customerAdmin.user.id}`
              : `tenant_admin_${tenantAdmin?.user.id}`
          );
          if (customerAdmin) {
            const parameters = {
              user_id: customerAdmin.user.id,
              customer_id: customerAdmin.customer.id,
              client_name: customerAdmin.customer.name,
              admin_name: `${customerAdmin.user.firstName} ${customerAdmin.user.lastName}`,
              paltform: isWeb() ? 'web' : isIOS() ? 'ios' : 'android',
              role: 'customer_admin',
            };
            logEvent(GAEvent.Login, customerAdmin.user.id, parameters);
          } else if (tenantAdmin) {
            const parameters = {
              user_id: tenantAdmin.user.id,
              tenant_id: tenantAdmin.id,
              admin_name: `${tenantAdmin.user.firstName} ${tenantAdmin.user.lastName}`,
              paltform: isWeb() ? 'web' : isIOS() ? 'ios' : 'android',
              role: 'tenant_admin',
            };
            logEvent(GAEvent.TenantLogin, tenantAdmin.user.id, parameters);
          }
        }

        const newDestination = !resetPassword ? destination : '/reset-password';

        navigate(newDestination);
      } catch (err) {
        handleMutationFormError(err, {
          setFormError,
          errorMap: {
            INVALID_LOGIN: () => ({
              icon: faLock,
              title: 'Invalid login',
              message: 'There is no account matching the email you provided.',
              status: 'warning',
            }),
            INVALID_CLIENT_ADMIN: () => ({
              icon: faLock,
              title: 'Invalid login',
              message:
                'You have not yet verified your email, please confirm your account via the confirmation email',
              status: 'warning',
            }),
            all: (gqlError) => ({
              title: 'Invalid login',
              // TODO: Proper error handling to be done through JIRA ticket
              message:
                'You have a worker account, please sign in with the app (' +
                gqlError.message +
                ')',
              status: 'danger',
            }),
          },
        });
      }
    },
    [currentTenant]
  );

  if (deviceToken) {
    return <Navigate replace to={destination} />;
  }

  return (
    <StyledPage size="xs">
      <Stack vertical gap={24}>
        <Card>
          <Stack css={{ paddingTop: '20px' }} justify="center">
            <GravyWorkLogo />
          </Stack>
          <Card.Header title="Sign In" />
          <Card.Section>
            <Form initialValues={initialValues} onSubmit={handleSubmit}>
              <TextField
                autoFocus
                required
                autoComplete="email"
                fieldId="email"
                label="Email Address"
                placeholder="Email address"
                type="email"
              />
              <TextField
                required
                autoComplete="current-password"
                css={{ letterSpacing: '2px' }}
                fieldId="password"
                label="Password"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                type="password"
              />
              <FormFooter>
                <Link id="forget-password" to={`/${ROUTES.forgotPassword}`}>
                  Forgot password?
                </Link>
                <Button
                  a11yLabel="Sign in"
                  id="sign-in-btn"
                  isLoading={isLoginAdminLoading}
                  loadingLabel="Log In..."
                />
              </FormFooter>
            </Form>
          </Card.Section>
          <Card.Section subdued>
            <Stack justify="center">
              <Body>Don&apos;t have an account?</Body>
              <Link id="sign-up" to={`/${ROUTES.signUp}`}>
                Sign up
              </Link>
            </Stack>
          </Card.Section>
        </Card>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <Version size="sm" />
        </div>
      </Stack>
    </StyledPage>
  );
};

export default Login;
