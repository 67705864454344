import Card from '@/components/Card';
import Stack from '@/components/Stack';
import { Body, Small } from '@/components/Typography';
import Link from '@/elements/Link';
import ROUTES from '@/routes/routes';
import { GetJobQuery } from '@/types/graphql';

type Props = {
  job: GetJobQuery['job'];
  currentAdminIsCustomerAdmin: boolean;
};

const OrderSection = ({
  job: { account, order },
  currentAdminIsCustomerAdmin,
}: Props) => {
  return (
    <Card.Section
      styleHeading={true}
      title="Job Details"
      style={{ paddingBottom: '10px' }}
    >
      <Stack vertical>
        <Stack justify="apart">
          <Small>Order</Small>
          <Body>
            <Link to="../..">#{order.id}</Link>
          </Body>
        </Stack>
        <Stack justify="apart">
          <Small>Client</Small>
          <Body textAlignProp={'right'}>
            <Link
              to={
                currentAdminIsCustomerAdmin
                  ? `../../../../${ROUTES.myCompany}`
                  : `../../../../clients/${account.customer.id}`
              }
            >
              {account.customer.name}
            </Link>
          </Body>
        </Stack>
        <Stack justify="apart">
          <Small>Billing Account</Small>
          <Body textAlignProp={'right'}>
            <Link
              to={
                currentAdminIsCustomerAdmin
                  ? `/${ROUTES.myCompany}/accounts/${account.id}`
                  : `/clients/${account.customer.id}/accounts/${account.id}`
              }
            >
              {account.name}
            </Link>
          </Body>
        </Stack>
      </Stack>
    </Card.Section>
  );
};

export default OrderSection;
