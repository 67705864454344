import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useState } from 'react';

import Alert from '@/components/Alert';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Modal from '@/components/Modal';
import Stack from '@/components/Stack';
import { TabDefinition } from '@/components/Tabs';
import WorkerAutocompleteField, {
  WorkerItem,
} from '@/components/WorkerAutocompleteField';
import Form from '@/form';
import {
  useCreateCustomerWorkerRelationshipMutation,
  WorkerRelationshipItemFragmentDoc,
} from '@/graphql';
import {
  GetCustomerQuery,
  RelationshipKindEnum,
  TaxTypeEnum,
} from '@/types/graphql';

const JOBS_TABS: TabDefinition[] = [
  { a11yLabel: 'View favorite GravyWorkers', label: 'favorite' },
  { a11yLabel: 'View blocked GravyWorkers', label: 'blocked' },
  { a11yLabel: 'View lta GravyWorkers', label: 'LTA' },
];

export type Props = {
  hideModal: () => void;
  currentTab: number;
  customer: GetCustomerQuery['customer'];
};

type FormValues = {
  worker: WorkerItem | null;
};

const kinds: RelationshipKindEnum[] = [
  RelationshipKindEnum.FAVORITED,
  RelationshipKindEnum.BLOCKED,
  RelationshipKindEnum.LTA_WORKERS,
];

const AddWorkerToList = ({ customer, currentTab, hideModal }: Props) => {
  const initialValues: FormValues = {
    worker: null,
  };
  const { jobTaxType } = customer;
  const [error, setError] = useState<boolean>(false);

  const [createRelationship] = useCreateCustomerWorkerRelationshipMutation({
    update: (cache, { data }) => {
      cache.modify({
        id: `Customer:${customer.id}`,
        fields: {
          workerRelationships(existingRefs = [], { readField }) {
            if (!data) return existingRefs;
            const { workerRelationship } = data.customerRelateWorker;

            if (
              existingRefs.some(
                (ref) => readField('id', ref) === workerRelationship.id
              )
            ) {
              return existingRefs;
            }

            const newRelationRef = cache.writeFragment({
              fragment: WorkerRelationshipItemFragmentDoc,
              data: workerRelationship,
            });

            return [...existingRefs, newRelationRef];
          },
        },
      });
    },
  });

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        await createRelationship({
          variables: {
            customerId: customer.id,
            workerId: values.worker!.id,
            kind: kinds[currentTab],
          },
        });
        hideModal();
      } catch (_err) {
        setError(true);
      }
    },
    [customer]
  );

  return (
    <Modal
      disableClickout
      size="sm"
      title={`Add a ${JOBS_TABS[currentTab].label} GravyWorker`}
      onRequestClose={hideModal}
    >
      <Card.Section>
        {error && (
          <Alert
            icon={faExclamationTriangle}
            status="warning"
            title="GravyWorker not found"
          />
        )}
        <Form initialValues={initialValues} onSubmit={handleSubmit}>
          <WorkerAutocompleteField
            context="clientsAddWorker"
            fieldId="worker"
            label="Select GravyWorker"
            {...(jobTaxType === 'w2' && {
              taxType: TaxTypeEnum.TAX_W2,
            })}
          />
          <Stack justify="end">
            <Button
              a11yLabel="Submit form"
              id="save-gravy-worker-btn"
              isLoading={undefined}
              label="Save"
              type="submit"
            />
          </Stack>
        </Form>
      </Card.Section>
    </Modal>
  );
};

export default AddWorkerToList;
