import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { Route, Navigate, useLocation } from 'react-router-dom';

import { currentAdminVar } from '@/util/apollo/cache';
import { isWeb } from '@/util/platform';

export enum Role {
  CUSTOMER_ADMIN,
  TENANT_ADMIN,
}

interface Props {
  element: React.ReactElement;
  requiredRoles: Role[];
  path?: string;
  redirectTo?: string;
}

const PrivateElement = ({
  element,
  requiredRoles,
  redirectTo = isWeb() ? '/login' : '/app-login',
}: Props) => {
  const location = useLocation();

  const currentAdmin = useReactiveVar(currentAdminVar);

  const userHasRequiredRoles =
    (currentAdmin && requiredRoles.includes(currentAdmin.role!)) || false;

  return userHasRequiredRoles ? (
    element
  ) : (
    <Navigate state={{ from: location }} to={redirectTo} />
  );
};

const PrivateRoute = ({
  element,
  requiredRoles,
  redirectTo,
  ...rest
}: Props) => {
  return (
    <Route
      {...rest}
      element={
        <PrivateElement
          element={element}
          redirectTo={redirectTo}
          requiredRoles={requiredRoles}
        />
      }
    />
  );
};

export default PrivateRoute;
