import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useCombobox, UseComboboxProps } from 'downshift';
import { ReactNode, useCallback, useState } from 'react';

import Combobox from './Combobox';
import Item from './Item';
import Menu from './Menu';

import Icon from '@/elements/Icon';
import Input from '@/elements/Input';

export type RenderItemProps<T> = {
  index: number;
  item: T;
  isHighlighted: boolean;
  isSelected: boolean;
};

export type Props<T> = {
  autoFocus?: boolean;
  disabled?: boolean;
  id: string;
  items: UseComboboxProps<T>['items'];
  selectedItem: T | null;
  itemToKey: (item: T) => string | number;
  itemToString: NonNullable<UseComboboxProps<T>['itemToString']>;
  onInputValueChange: UseComboboxProps<T>['onInputValueChange'];
  onSelectedItemChange: UseComboboxProps<T>['onSelectedItemChange'];
  placeholder?: string;
  renderItem?: (props: RenderItemProps<T>) => ReactNode;
  required?: boolean;
  stateReducer?: UseComboboxProps<T>['stateReducer'];
  fixedSelectHeight?:
    | 'sm'
    | 'md'
    | 'lg'
    | 'auto'
    | {
        [x: string]: 'sm' | 'md' | 'lg' | 'auto' | undefined;
        initial?: 'sm' | 'md' | 'lg' | 'auto' | undefined;
      }
    | undefined;
  openMenuOnInputFocus?: boolean;
};

const emptyStateReducer = (_state, { changes }) => changes;

const Autocomplete = <T extends object>({
  autoFocus,
  id,
  items,
  selectedItem: providedSelectedItem,
  itemToKey,
  itemToString,
  onInputValueChange,
  onSelectedItemChange,
  renderItem: providedRenderItem,
  stateReducer = emptyStateReducer,
  fixedSelectHeight = 'lg',
  openMenuOnInputFocus = true,
  ...inputProps
}: Props<T>) => {
  const {
    highlightedIndex,
    isOpen,
    selectedItem,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    openMenu,
    selectItem,
  } = useCombobox({
    id,
    items,
    selectedItem: providedSelectedItem,
    itemToString,
    stateReducer,
    onInputValueChange,
    onSelectedItemChange,
  });

  const [isInputFocused, setIsInputFocused] = useState(false);

  const defaultRenderItem = useCallback(
    ({ index, item, ...rest }: RenderItemProps<T>) => (
      <Item key={index} {...rest}>
        {itemToString(item)}
      </Item>
    ),
    [itemToString]
  );

  const renderItem = providedRenderItem || defaultRenderItem;

  return (
    <div>
      <Combobox
        {...getComboboxProps()}
        className={isInputFocused ? 'focused' : ''}
      >
        <Input
          {...getInputProps({
            autoFocus,
            onBlur: () => {
              setIsInputFocused(false);
              if (selectedItem) {
                selectItem(selectedItem);
              }
            },
            onFocus: () => {
              setIsInputFocused(true);
              if (!isOpen && openMenuOnInputFocus) {
                openMenu();
              }
            },
          })}
          {...inputProps}
        />
        <Icon
          icon={faChevronDown}
          size={'sm'}
          style={{ position: 'absolute', right: 14, top: 14, color: '#ccc' }}
        />
      </Combobox>

      <Menu
        {...getMenuProps()}
        as="ul"
        height={fixedSelectHeight}
        size="parent"
        status={isOpen && items.length > 0 ? 'open' : 'closed'}
      >
        {items.map((item, index) => (
          <li key={itemToKey(item)} {...getItemProps({ item, index })}>
            {renderItem({
              item,
              index,
              isHighlighted: highlightedIndex === index,
              isSelected:
                !!selectedItem && itemToKey(item) === itemToKey(selectedItem),
            })}
          </li>
        ))}
      </Menu>
    </div>
  );
};

export default Autocomplete;
