import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { StitchesVariants } from '@stitches/react';
import React, { ComponentProps } from 'react';

import Icon from '@/elements/Icon';
import IconicButtonElement from '@/elements/IconicButton';

type IconicButtonVariants = StitchesVariants<typeof IconicButtonElement>;
type IconVariants = StitchesVariants<typeof Icon>;

export type Props = ComponentProps<typeof IconicButtonElement> &
  IconicButtonVariants & {
    a11yLabel: string;
    icon: IconDefinition;
    isLoading?: boolean;
    loadingA11yLabel?: string;
    loadingIcon?: IconDefinition;
    spin?: boolean;
    iconTheme?: IconVariants['theme'];
    customIcon?: React.ReactElement | any;
    text?: string;
  };

const IconicButton = ({
  a11yLabel,
  disabled,
  icon,
  isLoading,
  loadingA11yLabel,
  loadingIcon = faCircleNotch,
  spin = false,
  iconTheme = 'default',
  customIcon,
  text,
  ...rest
}: Props) => {
  return (
    <IconicButtonElement
      {...rest}
      aria-label={isLoading ? loadingA11yLabel || a11yLabel : a11yLabel}
      disabled={isLoading || disabled}
    >
      {customIcon || (
        <Icon
          fixedWidth
          icon={isLoading ? loadingIcon : icon}
          spin={spin}
          theme={iconTheme}
        />
      )}
      {text && typeof text === 'string' && <span>{text}</span>}
    </IconicButtonElement>
  );
};

export default IconicButton;
